import React, { useEffect, useState } from 'react'
import StyleClass from './TransportDetails/transport.module.css'
import { useForm } from 'react-hook-form';
import { ShopServices } from '../Shop/ShopServicess';
import { Success } from '../../components/utils/ToastMessages';
import { Services } from '../../Services';
const FieldsModal = ({ onClose, OrderDetails, isOpen, setIsOpen, orderId, totalQuintal, fieldId, setFieldId }) => {
    const [hamalidropDown, setHamalidropDown] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [cashDays, setCashDays] = useState([]);
    const [hamalicharges, setHamaliCharges] = useState(0);
    const { register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        const getConfigs = async () => {
            try {
                const res = await ShopServices.ConfigDropdowns("GET", null, token);
                if (res.Status === 1) {
                    setDropdownOptions(res.config);
                }
            } catch (err) {
                console.log(err);
            }
        }
        getConfigs();
    }, []);

    useEffect(() => {
        if (dropdownOptions.length > 0) {
            const Hchargesdropdown = dropdownOptions?.find(
                (config) => config.configId === 11
            );
            const Cashdiscountdays = dropdownOptions?.find(
                (config) => config.configId === 14
            );

            if (Hchargesdropdown) {
                let numbers = Array.from({ length: parseInt(Hchargesdropdown?.configValue, 10) }, (_, i) => i + 1);
                setHamalidropDown(numbers);
            }
            if (Cashdiscountdays) {
                const configValue = Cashdiscountdays?.configValue;
                if (configValue.includes('-')) {

                    const [from, to] = configValue.split('-').map(Number);

                    if (!isNaN(from) && !isNaN(to) && from < to) {
                        let numbers = Array.from({ length: to - from + 1 }, (_, i) => from + i);
                        setCashDays(numbers);
                    }
                }
            }
        }
    }, [dropdownOptions]);

    const onSubmit = async (data) => {
        let body = {
            ...data,
            orderId: orderId,
        }
        try {
            const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
            if (res.Status === 1) {
                setIsOpen(false);
                Success(res.Message);
                OrderDetails();
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (hamalicharges && totalQuintal > 0) {

            setValue("hamaliCharges", parseFloat(
                (totalQuintal * hamalicharges)).toFixed(2));
        };
    }, [hamalicharges, totalQuintal, setValue]);

    return (
        <div className={StyleClass["reject_modal"]}>
            <div
                onClick={() => { setIsOpen(false) }}
                className={StyleClass["overlaye"]}
            ></div>
            <div className={StyleClass["Feildsmodal_content"]}>
                <h2 className={StyleClass["modal_header"]}>Edit Order Fields</h2>
                <div className={StyleClass["modal_body"]}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {
                            fieldId == 1 ? <div className={StyleClass["form-group"]}>
                                <label htmlFor="preBooking">Cash Discount Days (CD/CC)</label>
                                <select
                                    placeholder='Cash Discount Days (CD/CC)'
                                    {...register("cashDiscountDays", {
                                        required: "Cash Discount Days (CD/CC) is required",
                                        valueAsNumber: true
                                    })}
                                >
                                    <option value="">Cash Discount Days (CD/CC)</option>
                                    {cashDays.map((option, i) => (
                                        <option key={i} value={option}>{option}{"Days"}</option>
                                    ))}
                                </select>
                                {errors.cashDiscountDays && <span className={StyleClass["errors"]}>{errors.cashDiscountDays.message}</span>}
                            </div> :
                                <div className={StyleClass["form-group"]}>
                                    <label htmlFor="preBooking">Hamali Charges (Quintal)</label>
                                    <input
                                        type='number'
                                        step="0.01"
                                        placeholder='Hamali Charges'
                                        {...register("hamaliCharges", {
                                            required: false,
                                            valueAsNumber: true
                                        })}
                                        readOnly
                                    />
                                    <div className={StyleClass["downdrop"]}>
                                        <select className={StyleClass.dropdwons}
                                            {...register("hamaliChargesPerQuintal", {
                                                required: false,
                                                valueAsNumber: true,
                                                onChange: (e) => {
                                                    setHamaliCharges(e.target.value);
                                                },
                                            })}
                                        >
                                            <option value=""> Charges</option>
                                            {
                                                hamalidropDown.map((option, i) => (
                                                    <option key={i} value={option}>₹{option}{" Per Quintal"}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {errors.hamaliCharges && <span className={StyleClass["errors"]}>{errors.hamaliCharges.message}</span>}
                                </div>
                        }


                        <div className={StyleClass["button_wrapper"]}>
                            <button type='button' id={StyleClass["delete"]} onClick={() => { setIsOpen(false) }}>Cancel</button>
                            <button type='submit' id={StyleClass["addbt"]}>Submit</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FieldsModal;