import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import HomeClasses from "../../pages/Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { SuccessSwal } from "../../components/utils/ToastMessages";
import dropBox from "../../assets/orderbox.png";
import { IoIosCall } from "react-icons/io";
import { MdBusinessCenter, MdLocationPin } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { BiSolidCreditCardFront } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import { PurchaseService } from "./PurchaseService";
import { LuDownload } from "react-icons/lu";
import Moment from "react-moment";
import Image from '../../assets/noplot.png'
import { FaUser } from "react-icons/fa";
import moment from "moment";
import QualityDifference from "../Home/Qualitydiference/QualityDifference";
import QualityPriceChange from "./QualityPriceChange";
import OrderPayment from "../Home/OrderPayment";
import AgentAddressChange from "./AgentAddressChange";
import UpdateTransports from "./UpdateTransports";
import CommissionList from "./CommissionList";
import { ShopServices } from "../Shop/ShopServicess";
import { NameChange } from "../../components/utils/Functions";

const PurchaseDetails = () => {
  const { orderId, status } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orderdetails, setOrderdetails] = useState({ distributorCommission: 0, subBrokerCommssion: 0, transportCommission: 0, millerCommission: 0 });
  const [orderedfrom, setOrderedfrom] = useState({});
  const [orderdby, setOrderedBy] = useState({});
  const [rejectReason, setRejectReason] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false);
  const [addressChange, setAddressChange] = useState(false);
  const [updateTransport, setUpdateTransport] = useState(false);
  const [SelectedTab, setSelectedTab] = useState({ accountType: 0 });

  const userId = parseInt(localStorage.getItem("userId"), 10);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = parseInt(localStorage.getItem("AccountType"), 10);
  const permissionAccount = parseInt(localStorage.getItem("permissionAccount"), 10);
  // Flag condition for dashbord
  const { viewFlag } = location.state || {};

  useEffect(() => {
    OrderDetails();
    setSelectedTab(JSON.parse(localStorage.getItem("selectedTab")))
  }, []);


  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, []);

  // Permissions for Agent office team
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const CommisionPermission = addPermissions.filter(
    (item) => item.permissionId === 15
  );
  const Commisionapermission = CommisionPermission.length > 0 ? CommisionPermission[0].status : 1;

  const QualityPermission = addPermissions.filter(
    (item) => item.permissionId === 17
  );
  const PermissionQuality = QualityPermission.length > 0 ? QualityPermission[0].status : 1;


  const OrderDetails = async () => {
    let body = {
      orderId: orderId,
      commissionType: parseInt(status, 10)
    }
    try {

      const res = await Services.getOrderdetails("POST", JSON.stringify(body), token);
      if (res.Status == 1) {
        setLoading(false);
        setOrderdetails(res.orderDetails);
        setProducts(res.orderDetails.products);
        setOrderedfrom(res.orderedFrom);
        setOrderedBy(res.orderedBy);
        localStorage.setItem("OrderTotalQuintals", res.orderDetails?.totalWeightInQuintal);

      }
    } catch (err) {
      console.error(err);
    }
  };

  const Reorder = async (orderId) => {
    // e.stopPropagation();
    try {
      const res = await PurchaseService.ReOrder(
        "POST",
        JSON.stringify({}),
        token,
        orderId
      );
      if (res.Status === 1) {
        SuccessSwal("Success", "Products Added to Cart successfully");
        Navigate("/cart");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const cancelOrder = async (reason) => {
    let body = {
      userOrdersStatus: 6,
      orderId: orderdetails.orderId,
      rejectReason: reason,
      products: orderdetails.products,
    };
    try {
      const res = await PurchaseService.CancelOrder(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setCancelModal(false);
        setRejectReason(" ");
        OrderDetails();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const InvoiceBill = () => {
    window.open(orderdetails?.invoice, "_blank");
  };

  // Commsions  filtering

  const getCommissionTypeLabel = (type) => {
    switch (type) {
      case 10:
        return "Miller Commissions";
      case 11:
        return "Distributor Commissions";
      case 12:
        return "Sub Broker Commissions";
      case 13:
        return "Transport Commissions";
      default:
        return "Unknown Commission Type";
    }
  };

  const filterCommissionsByType = (type) => {
    const typeLabel = getCommissionTypeLabel(type);
    return {
      typeLabel,
      commissions: orderdetails?.commissions?.filter(commission => commission.commissionType === type)
    };
  };

  const filteredCommissions = {
    millerCommissions: filterCommissionsByType(10),
    distributorCommissions: filterCommissionsByType(11),
    subBrokerCommissions: filterCommissionsByType(12),
    transportCommissions: filterCommissionsByType(13)
  };

  return (
    <Layout Active={"Purchase Orders"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Add_main_container"]}>
            <div className={HomeClasses["Add_header"]}>
              <button
                className={HomeClasses["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["add_header_h2"]}>OrderID#{orderId}</h1>
            </div>
            <div className={HomeClasses["order_d_container"]}>
              <div className={HomeClasses["approval_container"]}>

                {((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && !viewFlag) &&
                  <>
                    {userId == orderdetails?.subAgent?.userId ? (
                      <div className={HomeClasses["approvals"]}>
                        <div className={HomeClasses["radious_wrapper"]}>
                          <FaUser size={20} />
                        </div>
                        <div className={HomeClasses["text_container"]}>
                          <p id={HomeClasses["order_d_text"]}>Sub Broker Commission  </p>
                          <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.subBrokerCommssion).toFixed(2)}</p>
                        </div>
                      </div>) : <>
                      {
                        (orderdetails?.millerCommission !== 0 && orderdetails?.millerCommission !== null) &&
                        <div className={HomeClasses["approvals"]}>
                          <div className={HomeClasses["radious_wrapper"]}>
                            <FaUser size={20} />
                          </div>
                          <div className={HomeClasses["text_container"]}>
                            <p id={HomeClasses["order_d_text"]}>Miller to Broker </p>
                            <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.millerCommission).toFixed(2)}</p>
                          </div>
                        </div>
                      }
                      {
                        (orderdetails?.advance !== 0 && orderdetails?.advance !== null && orderdetails?.deliveryType?.deliveryTypeId === 2) &&
                        <div className={HomeClasses["approvals"]}>
                          <div className={HomeClasses["radious_wrapper"]}>
                            <FaUser size={20} />
                          </div>
                          <div className={HomeClasses["text_container"]}>
                            <p id={HomeClasses["order_d_text"]}>Transport Advance Pay </p>
                            <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.advance).toFixed(2)}</p>
                          </div>
                        </div>
                      }

                      {
                        (orderdetails?.distributorCommission !== 0 && orderdetails?.distributorCommission !== null) &&
                        <div className={HomeClasses["approvals"]}>
                          <div className={HomeClasses["radious_wrapper"]}>
                            <FaUser size={20} />
                          </div>
                          <div className={HomeClasses["text_container"]}>
                            <p id={HomeClasses["order_d_text"]}>Buyer to Broker </p>
                            <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.distributorCommission).toFixed(2)}</p>
                          </div>
                        </div>
                      }


                      {
                        (orderdetails?.transportCommission !== 0 && orderdetails?.transportCommission !== null) && (
                          <div className={HomeClasses["approvals"]}>
                            <div className={HomeClasses["radious_wrapper"]}>
                              <FaUser size={20} />
                            </div>
                            <div className={HomeClasses["text_container"]}>
                              <p id={HomeClasses["order_d_text"]}>Transport Commission </p>
                              <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.transportCommission).toFixed(2)}</p>
                            </div>
                          </div>)}
                      {
                        orderdetails?.subBrokerCommssion !== 0 &&
                        orderdetails?.subBrokerCommssion !== null && (

                          <div className={HomeClasses["approvals"]}>
                            <div className={HomeClasses["radious_wrapper"]}>
                              <FaUser size={20} />
                            </div>
                            <div className={HomeClasses["text_container"]}>
                              <p id={HomeClasses["order_d_text"]}>Sub Broker Commission </p>
                              <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.subBrokerCommssion).toFixed(2)}</p>
                            </div>
                          </div>)}
                    </>}
                  </>
                }

                {
                  (accountType == 2 && orderdetails?.distributorCommission !== 0 && orderdetails?.distributorCommission !== null && !viewFlag) &&
                  <div className={HomeClasses["approvals"]}>
                    <div className={HomeClasses["radious_wrapper"]}>
                      <FaUser size={20} />
                    </div>
                    <div className={HomeClasses["text_container"]}>
                      <p id={HomeClasses["order_d_text"]}>Buyer to Broker </p>
                      <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.distributorCommission).toFixed(2)}</p>
                    </div>
                  </div>
                }
                {/* When  go through the dashbord */}

                {
                  (viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && (SelectedTab?.accountType === 1 || SelectedTab?.accountType === 7))) ? <>
                    <div className={HomeClasses["approvals"]}>
                      <div className={HomeClasses["radious_wrapper"]}>
                        <FaUser size={20} />
                      </div>
                      <div className={HomeClasses["text_container"]}>
                        <p id={HomeClasses["order_d_text"]}>Miller to Broker </p>
                        <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.millerCommission).toFixed(2)}</p>
                      </div>
                    </div>
                    {
                      (orderdetails?.advance !== 0 && orderdetails?.advance !== null && orderdetails?.deliveryType?.deliveryTypeId === 2) &&
                      <div className={HomeClasses["approvals"]}>
                        <div className={HomeClasses["radious_wrapper"]}>
                          <FaUser size={20} />
                        </div>
                        <div className={HomeClasses["text_container"]}>
                          <p id={HomeClasses["order_d_text"]}>Transport Advance Pay </p>
                          <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.advance).toFixed(2)}</p>
                        </div>
                      </div>
                    }
                    {(orderdetails?.transportCommission !== 0 && orderdetails?.transportCommission !== null) && (
                      <div className={HomeClasses["approvals"]}>
                        <div className={HomeClasses["radious_wrapper"]}>
                          <FaUser size={20} />
                        </div>
                        <div className={HomeClasses["text_container"]}>
                          <p id={HomeClasses["order_d_text"]}>Transport Commission </p>
                          <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails.transportCommission).toFixed(2)}</p>
                        </div>
                      </div>)}
                  </> :
                    (viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 2)) ? <>
                      {
                        (orderdetails?.distributorCommission !== 0 && orderdetails?.distributorCommission !== null) &&
                        <div className={HomeClasses["approvals"]}>
                          <div className={HomeClasses["radious_wrapper"]}>
                            <FaUser size={20} />
                          </div>
                          <div className={HomeClasses["text_container"]}>
                            <p id={HomeClasses["order_d_text"]}>Buyer to Broker </p>
                            <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.distributorCommission).toFixed(2)}</p>
                          </div>
                        </div>
                      }


                      {(orderdetails?.transportCommission !== 0 && orderdetails?.transportCommission !== null) && (
                        <div className={HomeClasses["approvals"]}>
                          <div className={HomeClasses["radious_wrapper"]}>
                            <FaUser size={20} />
                          </div>
                          <div className={HomeClasses["text_container"]}>
                            <p id={HomeClasses["order_d_text"]}>Transport Commission </p>
                            <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.transportCommission).toFixed(2)}</p>
                          </div>
                        </div>)}
                    </> :
                      (viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && (SelectedTab?.accountType === 8 || SelectedTab?.accountType === 5))) ? <>
                        {
                          (orderdetails?.subBrokerCommssion !== 0 &&
                            orderdetails?.subBrokerCommssion !== null) && (
                            <div className={HomeClasses["approvals"]}>
                              <div className={HomeClasses["radious_wrapper"]}>
                                <FaUser size={20} />
                              </div>
                              <div className={HomeClasses["text_container"]}>
                                <p id={HomeClasses["order_d_text"]}>Sub Broker Commission </p>
                                <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.subBrokerCommssion).toFixed(2)}</p>
                              </div>
                            </div>)}



                        {
                          (orderdetails?.transportCommission !== 0 && orderdetails?.transportCommission !== null) && (
                            <div className={HomeClasses["approvals"]}>
                              <div className={HomeClasses["radious_wrapper"]}>
                                <FaUser size={20} />
                              </div>
                              <div className={HomeClasses["text_container"]}>
                                <p id={HomeClasses["order_d_text"]}>Transport Commission </p>
                                <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.transportCommission).toFixed(2)}</p>
                              </div>
                            </div>)}
                      </> : ""}

              </div>
              <div className={HomeClasses["order_box_container"]}>
                <div className={HomeClasses["order_s_box"]}>
                  <div className={HomeClasses["order_image_container"]}>
                    <img
                      src={dropBox}
                      alt="image"
                      className={HomeClasses["order_image"]}
                    />
                  </div>
                  <p id={HomeClasses["orders_status"]}>
                    <h5 id={HomeClasses["order_d_header"]}>
                      {orderdetails.userOrdersStatus === 1
                        ? "Order Pending "
                        : orderdetails.userOrdersStatus === 2
                          ? "Order Approved"
                          : orderdetails.userOrdersStatus === 3
                            ? "Order Invoice printed"
                            : orderdetails.userOrdersStatus === 4
                              ? "Order Transport"
                              : orderdetails.userOrdersStatus === 5
                                ? "Order Delivered"
                                : orderdetails.userOrdersStatus === 6
                                  ? "Order Rejected"
                                  : orderdetails.userOrdersStatus === 7
                                    ? "Completed"
                                    : orderdetails.userOrdersStatus === 8
                                      ? "Quality Defference"
                                      : orderdetails.userOrdersStatus === 10
                                        ? "Miller Commisiosns"
                                        : orderdetails.userOrdersStatus === 11
                                          ? "Distibutor Commisiosns"
                                          : orderdetails.userOrdersStatus === 12
                                            ? "SubBroker Commisiosns"
                                            : orderdetails.userOrdersStatus === 13
                                              ? "Transport Commisiosns"
                                              : orderdetails.userOrdersStatus === 14
                                                ? "Advance Transport Pay"
                                                : "SubBroker "}
                    </h5>
                    <span id={HomeClasses["order_d_date"]}>
                      <Moment
                        format="DD MMM, YYYY, hh:mm A"
                        subtract={{ hours: 5, minutes: 30 }}
                      >
                        {new Date(orderdetails.createdAt)}
                      </Moment>
                    </span>
                  </p>
                </div>
              </div>
              <div className={HomeClasses["order_address"]}>
                <h3 id={HomeClasses["order_d_h2"]}>
                  Ordered From
                </h3>

                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.businessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.name}{" "}({orderedfrom.accountType === 1
                      ? "Manufacturer "
                      : orderedfrom.accountType === 2
                        ? "Distributor "
                        : orderedfrom.accountType === 3
                          ? "Retailer"
                          : orderedfrom.accountType === 4
                            ? "Marketing Specialist"
                            : orderedfrom.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.mobileNumber}
                  </p>
                </p>
              </div>

              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Ordered By</h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.name}{" "}({orderdby.accountType === 1
                      ? "Manufacturer "
                      : orderdby.accountType === 2
                        ? "Distributor "
                        : orderdby.accountType === 3
                          ? "Retailer"
                          : orderdby.accountType === 4
                            ? "Marketing Specialist"
                            : orderdby.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.mobileNumber}
                  </p>
                </p>
              </div>
              {
                (orderdetails?.subAgent && Object.keys(orderdetails?.subAgent).length > 0) &&
                <div className={HomeClasses["order_address"]}>
                  <h2 id={HomeClasses["order_d_h2"]}>Ordered By (Sub Broker)</h2>
                  <p id={HomeClasses["order_addres_text"]}>
                    <p className={HomeClasses["location_icon"]}>
                      {" "}
                      <HiMiniUser className={HomeClasses["order_d_icon"]} />
                    </p>
                    <p className={HomeClasses["icon_text_order"]}>
                      {" "}
                      {orderdetails?.subAgent?.name}{" "}({orderdetails?.subAgent?.accountType === 1
                        ? "Manufacturer "
                        : orderdetails?.subAgent?.accountType === 2
                          ? "Distributor "
                          : orderdetails?.subAgent?.accountType === 3
                            ? "Retailer"
                            : orderdetails?.subAgent?.accountType === 4
                              ? "Marketing Specialist"
                              : orderdetails?.subAgent?.accountType === 5
                                ? "Agent"
                                : "Office Staff"})
                    </p>
                  </p>
                  <p id={HomeClasses["order_addres_text"]}>
                    <p className={HomeClasses["location_icon"]}>
                      {" "}
                      <IoIosCall className={HomeClasses["order_d_icon"]} />
                    </p>
                    <p className={HomeClasses["icon_text_order"]}>
                      {" "}
                      {orderdetails?.subAgent?.mobileNumber}
                    </p>
                  </p>
                </div>
              }


              <div className={HomeClasses["order_address"]}>
                <div className={HomeClasses["order_from_addres"]}>
                  <p id={HomeClasses["order_d_h2"]}> Delivery To </p>
                  {
                    viewFlag ? "" :
                      accountType == 5 && (orderdetails.userOrdersStatus === 1 || orderdetails.userOrdersStatus === 2 || orderdetails.userOrdersStatus === 3) ? <button type="button" onClick={() => { setAddressChange(true) }}>Change</button> : ""
                  }

                </div>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverName}{" "}({orderdetails.accountType === 1
                      ? "Manufacturer "
                      : orderdetails.accountType === 2
                        ? "Distributor "
                        : orderdetails.accountType === 3
                          ? "Retailer"
                          : orderdetails.accountType === 4
                            ? "Marketing Specialist"
                            : orderdetails.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverbusinessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.deliveryAddress}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverMobileNumber}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <BiSolidCreditCardFront
                      className={HomeClasses["order_d_icon"]}
                    />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.GSTNumber}
                  </p>
                </p>
              </div>

              <div className={HomeClasses["order_products"]}>
                <div className={HomeClasses["transport_header"]}>
                  <h2 id={HomeClasses["order_d_h2"]}>Package Items</h2>
                </div>
                <div className={HomeClasses.transport_details}>
                  {orderdetails.products &&
                    orderdetails.products.map((product, index) => {
                      return (
                        <div
                          className={HomeClasses["order_prod_container"]}
                          key={index}
                        >
                          <div className={HomeClasses["order_prod_box"]}>
                            <div
                              className={HomeClasses["order_prog_img_container"]}
                            >

                              {
                                product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                  <img
                                    className={HomeClasses["order_prod_image"]}
                                    src={product.productImageList[0]}
                                    alt="image"
                                    onError={(e) => {
                                      e.currentTarget.onerror = null;
                                      e.currentTarget.src = Image;
                                    }}
                                  />
                                ) : (
                                  <img
                                    className={HomeClasses["order_prod_image"]}
                                    src={Image}
                                    alt="image"
                                  />
                                )
                              }
                            </div>
                            <div className={HomeClasses["order_prod_info"]}>
                              <h5 id={HomeClasses["order_prod_name"]}>
                                {product.productName}
                              </h5>
                              <p className={HomeClasses["order_prod_Qty"]}>
                                {product.productCategory}{" . "}{product?.productSubCategory}
                              </p>
                              <p className={HomeClasses["order_prod_Qty"]}>
                                Qty: {product.qty}{" "}{product.productMeasuringUnit}'s
                              </p>
                            </div>
                            <div className={HomeClasses["discount_tag"]}>
                              {
                                product && (
                                  <div className={HomeClasses["dicount_pricetag"]}>
                                    {product?.cashDiscount > 0 ? (
                                      <>
                                        <p className={HomeClasses["Mdicount"]}>{product.discount}%</p>
                                        <p className={HomeClasses["Adicount"]}>{product.cashDiscount}% OFF</p>
                                      </>
                                    ) : (
                                      <p className={HomeClasses["Adicount"]}>{product.discount}% OFF</p>
                                    )}
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className={HomeClasses["order_prod_price"]}>
                            {
                              product?.productPriceUnit === "Quintal" ? <>
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    Miller Price :
                                  </p>
                                  <div className={HomeClasses["order_rupee"]}>
                                    <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.bagPrice).toFixed(2)}/Bag</p>
                                  </div>
                                </div>
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    Miller Price :
                                  </p>
                                  <div className={HomeClasses["order_rupee"]}>
                                    <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.price).toFixed(2)}/Quintal</p>
                                  </div>
                                </div>
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    {NameChange()} :
                                  </p>
                                  <div className={HomeClasses["order_rupee"]}>
                                    <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.bagDiscountPrice).toFixed(2)}/Bag</p>
                                  </div>
                                </div>
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    {NameChange()} :
                                  </p>
                                  <div className={HomeClasses["order_rupee"]}>
                                    <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}/Quintal</p>
                                  </div>
                                </div>
                              </> : <> <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  Miller Price :
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.price).toFixed(2)}</p>
                                </div>
                              </div>
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    {NameChange()} :
                                  </p>
                                  <div className={HomeClasses["order_rupee"]}>
                                    <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}</p>
                                  </div>
                                </div>
                              </>
                            }
                            {
                              product?.packingBag !== null && (
                                <div className={HomeClasses["order_d_price"]}>
                                  <p id={HomeClasses["order_price_container"]}>
                                    {product?.packingBag?.bagName} Price :
                                  </p>
                                  <p className={HomeClasses["Discountpice"]}>
                                    ₹{product?.packingBag?.price}
                                  </p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={HomeClasses["order_flow_info"]}>
                {
                  ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && !viewFlag) &&
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Broker Record Number</p>
                    <p className={HomeClasses.ordervalues}>{orderdetails?.agentRecordNumber}</p>
                  </div>
                }
                <div className={HomeClasses["order_flow_info"]}>
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Order Type</p>
                    <p className={HomeClasses.ordervalues}>{orderdetails?.orderType?.orderType}</p>
                  </div>

                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Delivery Type</p>
                    <p className={HomeClasses.ordervalues}>{orderdetails?.deliveryType?.deliveryType}</p>
                  </div>

                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Loading Date</p>
                    <div className="">
                      <p className={HomeClasses.ordervalues}>{orderdetails?.loadingDate !== null ? moment(orderdetails?.loadingDate).format("DD MMM, YYYY") : ""}</p>
                    </div>
                  </div>
                  {
                    orderdetails?.transportDetails &&
                    typeof orderdetails?.transportDetails === "object" &&
                    Object.keys(orderdetails?.transportDetails).length > 0 &&
                    <div className={HomeClasses.transportData}>
                      <div className={HomeClasses["transport_head_wrapper"]}>
                        <h2 id={HomeClasses["order_d_h2"]}>Transportation Details</h2>
                        {
                          ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && orderdetails?.deliveryType?.deliveryTypeId === 2 && !viewFlag && (status == 1 || status == 2 || status == 3)) &&
                          <button type="button" className={HomeClasses["downbt"]} onClick={() => { setUpdateTransport(true) }}>Edit</button>
                        }
                      </div>
                      <div className={HomeClasses.transport_details}>
                        <div className={HomeClasses["transport"]}>
                          <p className={HomeClasses["transport_label"]}>
                            Name
                          </p>
                          <p className={HomeClasses["transport_value"]}>
                            {orderdetails?.transportDetails?.name}
                          </p>
                        </div>
                        <div className={HomeClasses["transport"]}>
                          <p className={HomeClasses["transport_label"]}>
                            Vehicle Number
                          </p>
                          <p className={HomeClasses["transport_value"]}>
                            {orderdetails?.transportDetails?.vehicleNumber}
                          </p>
                        </div>
                        <div className={HomeClasses["transport"]}>
                          <p className={HomeClasses["transport_label"]}>
                            Driver Number
                          </p>
                          <p className={HomeClasses["transport_value"]}>
                            {orderdetails?.transportDetails?.driverNumber}
                          </p>
                        </div>
                        {
                          ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && orderdetails?.deliveryType?.deliveryTypeId === 2) &&
                          <>
                            <div className={HomeClasses["transport"]}>
                              <p className={HomeClasses["transport_label"]}>
                                Booking Rate (LF)
                              </p>
                              <p className={HomeClasses["transport_value"]}>
                                ₹{Number(orderdetails?.transportDetails?.preBooking).toFixed(2)}
                              </p>
                            </div>
                            <div className={HomeClasses["transport"]}>
                              <p className={HomeClasses["transport_label"]}>
                                Difference Rate(LF)
                              </p>
                              <p className={HomeClasses["transport_value"]}>
                                ₹{orderdetails?.transportDetails?.differenceBooking}
                              </p>
                            </div>
                            <div className={HomeClasses["transport"]}>
                              <p className={HomeClasses["transport_label"]}>
                                Confirmation Rate (LF)
                              </p>
                              <p className={HomeClasses["transport_value"]}>
                                ₹{Number(orderdetails?.transportDetails?.postBooking).toFixed(2)}
                              </p>
                            </div>
                            <div className={HomeClasses["transport"]}>
                              <p className={HomeClasses["transport_label"]}>
                                Advance (LF)
                              </p>
                              <p className={HomeClasses["transport_value"]}>
                                ₹{Number(orderdetails?.transportDetails?.advance).toFixed(2)}
                              </p>
                            </div>
                            <div className={HomeClasses["transport"]}>
                              <p className={HomeClasses["transport_label"]}>
                                Balance (LF)
                              </p>
                              <p className={HomeClasses["transport_value"]}>
                                ₹{Number(orderdetails?.transportDetails?.balance).toFixed(2)}
                              </p>
                            </div>
                          </>
                        }
                      </div>
                    </div>}

                  {
                    orderdetails?.orderThrough &&
                    Object.keys(orderdetails?.orderThrough).length > 0 &&
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Ordered Through</p>
                      <p className={HomeClasses.ordervalues}>{orderdetails?.orderThrough?.orderThrough}</p>
                    </div>
                  }
                  {
                    orderdetails.orderThrough?.orderThroughId === 2 ? (
                      userId === orderdetails?.subAgent?.userId ? (
                        <div className={HomeClasses["orderHeader_wrapper"]}>
                          <p className={HomeClasses.orderheaders}>Sub Broker</p>
                          <p className={HomeClasses.ordervalues}>{orderdby?.name}</p>
                        </div>
                      ) : (
                        <div className={HomeClasses["orderHeader_wrapper"]}>
                          <p className={HomeClasses.orderheaders}>Sub Broker</p>
                          <p className={HomeClasses.ordervalues}>{orderdetails?.subAgent?.name}</p>
                        </div>
                      )
                    ) : ""
                  }

                  {
                    !viewFlag ?
                      (userId == orderdetails?.subAgent?.userId) ?
                        <div className={HomeClasses["orderHeader_wrapper"]}>
                          <p className={HomeClasses.orderheaders}>Sub Broker Commissions</p>
                          <p className={HomeClasses.ordervalues}>₹{orderdetails?.brokerCommissionPerQuintal}/Quintal</p>
                        </div> : (
                          <>
                            {
                              (accountType == 5 || (accountType == 6 && permissionAccount === 5)) &&
                              <div className={HomeClasses["orderHeader_wrapper"]}>
                                <p className={HomeClasses.orderheaders}>Miller Commissions</p>
                                <p className={HomeClasses.ordervalues}>₹{orderdetails?.millerCommissionPerQuintal}/Quintal</p>
                              </div>
                            }
                            {
                              (orderdetails?.distributionCommissionPerQuintal !== 0 && orderdetails?.distributionCommissionPerQuintal !== null) &&
                              <div className={HomeClasses["orderHeader_wrapper"]}>
                                <p className={HomeClasses.orderheaders}>Buyer Commissions</p>
                                <p className={HomeClasses.ordervalues}>₹{orderdetails?.distributionCommissionPerQuintal}/Quintal</p>
                              </div>
                            }

                            {
                              (orderdetails?.transportCommissionPerQuintal !== 0 && orderdetails?.transportCommissionPerQuintal !== null && accountType == 5) && (
                                <div className={HomeClasses["orderHeader_wrapper"]}>
                                  <p className={HomeClasses.orderheaders}>Transport Commissions</p>
                                  <p className={HomeClasses.ordervalues}>₹{orderdetails?.transportCommissionPerQuintal}/Quintal</p>
                                </div>
                              )
                            }

                            {
                              (orderdetails?.brokerCommissionPerQuintal !== 0 && orderdetails?.brokerCommissionPerQuintal !== null && accountType == 5) && (
                                <div className={HomeClasses["orderHeader_wrapper"]}>
                                  <p className={HomeClasses.orderheaders}>Sub Broker Commissions</p>
                                  <p className={HomeClasses.ordervalues}>₹{orderdetails?.brokerCommissionPerQuintal}/Quintal</p>
                                </div>
                              )
                            }
                          </>
                        ) : ""
                  }

                  {/*When go through the dashbord  */}
                  {(viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 1)) ? <>
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Miller Commissions</p>
                      <p className={HomeClasses.ordervalues}>₹{orderdetails?.millerCommissionPerQuintal}/Quintal</p>
                    </div>
                    {(orderdetails?.transportCommissionPerQuintal !== 0 && orderdetails?.transportCommissionPerQuintal !== null) && (
                      <div className={HomeClasses["orderHeader_wrapper"]}>
                        <p className={HomeClasses.orderheaders}>Transport Commissions</p>
                        <p className={HomeClasses.ordervalues}>₹{orderdetails?.transportCommissionPerQuintal}/Quintal</p>
                      </div>)}
                  </>
                    : (viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 2)) ?
                      <>
                        <div className={HomeClasses["orderHeader_wrapper"]}>
                          <p className={HomeClasses.orderheaders}>Buyer Commissions</p>
                          <p className={HomeClasses.ordervalues}>₹{orderdetails?.distributionCommissionPerQuintal}/Quintal</p>
                        </div>
                        {(orderdetails?.transportCommissionPerQuintal !== 0 && orderdetails?.transportCommissionPerQuintal !== null) && (
                          <div className={HomeClasses["orderHeader_wrapper"]}>
                            <p className={HomeClasses.orderheaders}>Transport Commissions</p>
                            <p className={HomeClasses.ordervalues}>₹{orderdetails?.transportCommissionPerQuintal}/Quintal</p>
                          </div>)}
                      </> : (viewFlag && ((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 5)) ? <>
                        {(orderdetails?.brokerCommissionPerQuintal !== 0 && orderdetails?.brokerCommissionPerQuintal !== null) && (
                          <div className={HomeClasses["orderHeader_wrapper"]}>
                            <p className={HomeClasses.orderheaders}>Sub Broker Commissions</p>
                            <p className={HomeClasses.ordervalues}>₹{orderdetails?.brokerCommissionPerQuintal}/Quintal</p>
                          </div>)}
                        {(orderdetails?.transportCommissionPerQuintal !== 0 && orderdetails?.transportCommissionPerQuintal !== null) && (
                          <div className={HomeClasses["orderHeader_wrapper"]}>
                            <p className={HomeClasses.orderheaders}>Transport Commissions</p>
                            <p className={HomeClasses.ordervalues}>₹{orderdetails?.transportCommissionPerQuintal}/Quintal</p>
                          </div>)}
                      </> : ""}

                  {(orderdetails?.cashDiscountDays !== null && orderdetails?.cashDiscountDays !== 0) &&
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Cash Discount Days (CD/CC)</p>
                      <p className={HomeClasses.ordervalues}>{orderdetails?.cashDiscountDays}{" Days"}</p>
                    </div>}

                  {(orderdetails?.hamaliCharges !== null && orderdetails?.hamaliCharges !== 0) &&
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Hamali Charges</p>
                      <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.hamaliCharges).toFixed(2)}</p>
                    </div>}
                  {(orderdetails?.hamaliChargesPerQuintal !== null && orderdetails?.hamaliChargesPerQuintal !== 0) &&
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Hamali Charges(Quintal)</p>
                      <p className={HomeClasses.ordervalues}>₹{orderdetails?.hamaliChargesPerQuintal}/Quintal</p>
                    </div>}
                  {((accountType == 5 || (accountType == 6 && permissionAccount === 5)) && orderdetails?.notes !== "NA") &&
                    <div className={HomeClasses["orderHeader_wrappe"]}>
                      <p className={HomeClasses.QDNotes}>Broker Notes:</p>
                      <p className={HomeClasses.ordernote}>{orderdetails?.notes}</p>
                    </div>}
                </div>

                {/* Order  all type of Pricess */}
                <div className={HomeClasses["prices_box"]}>
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Total Quintal</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        {Number(orderdetails?.totalWeightInQuintal).toFixed(2)}
                      </p>
                    </p>
                  </div>
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Total Tons</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        {Number(orderdetails?.totalWeightInTons).toFixed(2)}
                      </p>
                    </p>
                  </div>
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Subtotal</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        ₹{Number(orderdetails.totalPrice).toFixed(2)}
                      </p>
                    </p>
                  </div>
                  {
                    (orderdetails.transportDetails &&
                      typeof orderdetails.transportDetails === "object" &&
                      Object.keys(orderdetails.transportDetails).length > 0) &&
                    <div className={HomeClasses["order_total"]}>
                      <p id={HomeClasses["order_text_"]}>{orderdetails?.deliveryType?.deliveryTypeId === 1 ? "Advance Pay" : "Advance pay(Broker)"}</p>
                      <p className={HomeClasses["order_discount"]}>
                        <p id={HomeClasses["order_price_rupee"]}>
                          ₹{orderdetails?.transportDetails?.advance !== null ? Number(orderdetails?.transportDetails?.advance).toFixed(2) : 0}
                        </p>
                      </p>
                    </div>
                  }
                  {
                    (orderdetails?.totalPackingBags !== 0 && orderdetails?.totalPackingBags !== null) &&
                    <div className={HomeClasses["order_total"]}>
                      <p id={HomeClasses["order_text_"]}>Packing Bags</p>
                      <p className={HomeClasses["order_discount"]}>
                        <p id={HomeClasses["order_price_rupee"]}>
                          ₹{Number(orderdetails?.totalPackingBags).toFixed(2)}
                        </p>
                      </p>
                    </div>
                  }
                  <div className={HomeClasses["Order_prices"]}>
                    <div className={HomeClasses["order_total"]}>
                      <p id={HomeClasses["order_text_"]}>Order Price</p>
                      <p className={HomeClasses["order_discount"]}>

                        {
                          orderdetails?.agentOrderPrice === orderdetails?.finalAmount ?
                            <p id={HomeClasses["order_price_rupee"]}>
                              ₹{Number(orderdetails?.agentOrderPrice).toFixed(2)}
                            </p> :
                            <p id={HomeClasses["normal"]}>
                              ₹{Number(orderdetails?.agentOrderPrice).toFixed(2)}
                            </p>
                        }

                        <div className={HomeClasses["Invoice-Prices"]}>
                          <p> {
                            orderdetails?.agentOrderPrice !== orderdetails?.finalAmount && (<p id={HomeClasses["order_price_rupee"]}>Invoice Amount : ₹{Number(orderdetails?.finalAmount).toFixed(2)}</p>)
                          }</p>
                          <p>{
                            orderdetails?.invoiceNumber !== "NA" && (<>Invoice Number : {orderdetails?.invoiceNumber}</>)
                          }</p>
                        </div>
                      </p>
                    </div>

                  </div>
                </div>

                {/* Payments Pay */}
                {
                  (
                    orderdetails?.pendingPayment !== null && orderdetails?.pendingPayment !== 0) ?
                    <div className={HomeClasses["commissions-pending"]}>
                      <h3 id={HomeClasses["order_paments_header"]}>Pending Payment</h3>
                      <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails?.pendingPayment).toFixed(2)}</p>
                    </div> : ""}
                <div className={HomeClasses["order_d_payments"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Payments</h3>
                  {
                    orderdetails?.payments && orderdetails?.payments?.length > 0 ?
                      <>
                        <div className={HomeClasses["payment_headers"]}>
                          <p id={HomeClasses["header_payments"]}>Payment Date</p>
                          <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                        </div>
                        {
                          orderdetails?.payments.map((payment, index) => {
                            return (
                              <div
                                className={HomeClasses["order_payment_lists"]}
                                key={index}
                              >
                                <div className={HomeClasses["payment_data"]}>
                                  <div className={HomeClasses["payment_item"]}>
                                    <h4>
                                      {payment?.name}
                                    </h4>
                                    <p className={HomeClasses["orderpay"]}>Type : <span>{payment?.paymentType}</span></p>
                                    {
                                      (payment?.transactionNumber !== "NA" && payment?.transactionNumber !== null) &&
                                      <p className={HomeClasses["orderpay"]}>Transaction No : <span>{payment?.transactionNumber}</span></p>
                                    }
                                  </div>
                                  <p id={HomeClasses["payment_item"]}>
                                    <Moment
                                      format="DD MMM, YYYY, hh:mm A"
                                      subtract={{ hours: 5, minutes: 30 }}
                                    >
                                      {new Date(payment?.createdAt)}
                                    </Moment>
                                    ₹{Number(payment?.receivedPayement).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}</>
                      : <div className={HomeClasses.emptyaccounts}>
                        <p>No Payments Found</p>
                      </div>}
                </div>

                {/* Commisions pay */}
                {
                  (status == 10 || status == 11 || status == 12 || status == 13 &&
                    orderdetails?.pendingCommission !== null && orderdetails?.pendingCommission !== 0) ?
                    <div className={HomeClasses["commissions-pending"]}>
                      <h3 id={HomeClasses["order_paments_header"]}>Pending Commissions</h3>
                      <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails?.pendingCommission).toFixed(2)}</p>
                    </div> : ""}

                {(status == 10 || status == 11 || status == 12 || status == 13 && (accountType == 5 || (accountType == 6 && permissionAccount === 5))) ? (
                  <div className={HomeClasses["order_d_payments"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Commissions</h3>
                    {
                      orderdetails?.commissions && orderdetails?.commissions?.length > 0 ?
                        <>
                          <div className={HomeClasses["payment_headers"]}>
                            <p id={HomeClasses["header_payments"]}>Payment Date</p>
                            <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                          </div>
                          {
                            orderdetails?.commissions.map((payment, index) => {
                              return (
                                <div
                                  className={HomeClasses["order_payment_lists"]}
                                  key={index}
                                >
                                  <div className={HomeClasses["payment_data"]}>
                                    <div className={HomeClasses["payment_item"]}>
                                      <h4>
                                        {payment?.name}
                                      </h4>
                                      <p className={HomeClasses["orderpay"]}>Type : <span>{payment?.paymentType}</span></p>
                                      {
                                        (payment?.transactionNumber !== "NA" && payment?.transactionNumber !== null) &&
                                        <p className={HomeClasses["orderpay"]}>Transaction No : <span>{payment?.transactionNumber}</span></p>
                                      }
                                    </div>
                                    <p id={HomeClasses["payment_item"]}>
                                      <Moment
                                        format="DD MMM, YYYY, hh:mm A"
                                        subtract={{ hours: 5, minutes: 30 }}
                                      >
                                        {new Date(payment?.paymentDate)}
                                      </Moment>
                                      ₹{Number(payment?.receivedPayement).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          }</> : <div className={HomeClasses.emptyaccounts}>
                          <p>No Commissions available</p>
                        </div>}

                    <div className={HomeClasses["payment_add_btn_c"]}>

                      {(status > 9 && orderdetails?.pendingCommission !== 0 && !viewFlag && !(accountType === 6 && Commisionapermission == 0)) &&
                        <button
                          className={HomeClasses["payment_add_btn"]}
                          type="button"
                          onClick={() => { setPaymentModel(true) }}
                        >
                          Add Payment
                        </button>
                      }
                    </div>
                  </div>
                ) : ""}

                {/* commisions display */}
                {(status == 7 && !viewFlag && (accountType == 5 || (accountType == 6 && permissionAccount === 5))) &&
                  <div className={HomeClasses["commissions-container"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Commissions Payments</h3>
                    <div className={HomeClasses["payment_header"]}>
                      <p id={HomeClasses["header_payments"]}>Payment Details</p>
                      <p id={HomeClasses["header_payments"]}>Payment Received</p>
                    </div>

                    {Object.keys(filteredCommissions).length > 0 ? (
                      Object.keys(filteredCommissions).map((key) => {
                        const { typeLabel, commissions } = filteredCommissions[key] || {};

                        return commissions && commissions.length > 0 ? (
                          <div key={key} className={HomeClasses["commissions"]}>
                            <h4 className={HomeClasses["commision_header"]}>{typeLabel}</h4>
                            {commissions.map((commission) => (
                              <div key={commission?.comissionId} className={HomeClasses["commission-entry"]}>
                                <div className={HomeClasses["commission-details"]}>
                                  <div className={HomeClasses["commisions_data"]}>
                                    <div className={HomeClasses["payes-names"]}>
                                      <h3>{commission?.name}</h3>
                                      <div className={HomeClasses.subdata}>
                                        Type: <p>{commission?.paymentType}</p>
                                      </div>
                                      {commission?.transactionNumber && (
                                        <div className={HomeClasses.subdata}>
                                          Transaction No: <p>{commission?.transactionNumber}</p>
                                        </div>
                                      )}
                                    </div>

                                    <div className={HomeClasses["payment-date"]}>
                                      <p className={HomeClasses.subheader}>
                                        {moment(commission?.paymentDate).format("YYYY/MM/DD")}
                                      </p>
                                      <p className={HomeClasses.golde}>
                                        ₹{commission?.receivedPayement}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : "";
                      })
                    ) : (
                      <div className={HomeClasses.emptyaccounts}>
                        <p>No Commissions available</p>
                      </div>
                    )}
                  </div>
                }

                {/* Commsions  for dashbord */}
                {(viewFlag && status == 7) && (
                  <div className={HomeClasses["commissions-container"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Commissions Payments</h3>
                    <div className={HomeClasses["payment_header"]}>
                      <p id={HomeClasses["header_payments"]}>Payment Details</p>
                      <p id={HomeClasses["header_payments"]}>Payment Received</p>
                    </div>

                    {(accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 8 && (
                      <>
                        {/* Show all commissions */}
                        {filteredCommissions?.millerCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.millerCommissions?.typeLabel} commissions={filteredCommissions.millerCommissions.commissions} />
                        )}
                        {filteredCommissions?.distributorCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.distributorCommissions?.typeLabel} commissions={filteredCommissions.distributorCommissions.commissions} />
                        )}
                        {filteredCommissions?.subBrokerCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.subBrokerCommissions?.typeLabel} commissions={filteredCommissions.subBrokerCommissions.commissions} />
                        )}
                        {filteredCommissions?.transportCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.transportCommissions?.typeLabel} commissions={filteredCommissions.transportCommissions.commissions} />
                        )}
                      </>
                    )}

                    {(accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 1 && (
                      <>
                        {/* Show only Miller and Transport Commissions */}
                        {filteredCommissions?.millerCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions.millerCommissions.typeLabel} commissions={filteredCommissions.millerCommissions.commissions} />
                        )}
                        {filteredCommissions?.transportCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.transportCommissions?.typeLabel} commissions={filteredCommissions.transportCommissions.commissions} />
                        )}
                      </>
                    )}

                    {(accountType == 5 || (accountType == 6 && permissionAccount === 5)) && SelectedTab?.accountType === 2 && (
                      <>
                        {/* Show only Buyer and Transport Commissions */}
                        {filteredCommissions?.distributorCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions.distributorCommissions.typeLabel} commissions={filteredCommissions.distributorCommissions.commissions} />
                        )}
                        {filteredCommissions?.transportCommissions?.commissions?.length > 0 && (
                          <CommissionList typeLabel={filteredCommissions?.transportCommissions?.typeLabel} commissions={filteredCommissions.transportCommissions.commissions} />
                        )}
                      </>
                    )}

                    {Object.values(filteredCommissions).every(({ commissions }) => commissions && commissions.length === 0) && (
                      <div className={HomeClasses.emptyaccounts}>
                        <p>No Commissions available</p>
                      </div>
                    )}
                  </div>
                )}

                {/* Pendind Advance */}
                {
                  ((status == 15 || status == 7) && (accountType == 5 || (accountType == 6 && permissionAccount === 5)) &&
                    orderdetails?.pendingAdvance !== null && orderdetails?.pendingAdvance !== 0) ?
                    <div className={HomeClasses["commissions-pending"]}>
                      <h3 id={HomeClasses["order_paments_header"]}>Pending Advance </h3>
                      <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails?.pendingAdvance).toFixed(2)}</p>
                    </div> : ""}

                {/* Advance payments */}
                {((status == 15 || status == 7) && (accountType == 5 || (accountType == 6 && permissionAccount === 5))) ? (
                  <div className={HomeClasses["order_d_payments"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Advance Payments</h3>
                    {
                      orderdetails?.advancePayments && orderdetails?.advancePayments?.length > 0 ?
                        <>
                          <div className={HomeClasses["payment_header"]}>
                            <p id={HomeClasses["header_payments"]}>Payment Date</p>
                            <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                          </div>
                          {
                            orderdetails?.advancePayments?.map((payment, index) => {
                              return (
                                <div
                                  className={HomeClasses["order_payment_lists"]}
                                  key={index}
                                >
                                  <div className={HomeClasses["payment_data"]}>
                                    <div className={HomeClasses["payment_item"]}>
                                      <h4>
                                        {payment?.name}
                                      </h4>
                                      <p className={HomeClasses["orderpay"]}>Type : <span>{payment?.paymentType}</span></p>
                                      {
                                        (payment?.transactionNumber !== "NA" && payment?.transactionNumber !== null) &&
                                        <p className={HomeClasses["orderpay"]}>Transaction No : <span>{payment?.transactionNumber}</span></p>
                                      }

                                    </div>
                                    <p id={HomeClasses["payment_item"]}>
                                      <Moment
                                        format="DD MMM, YYYY, hh:mm A"
                                        subtract={{ hours: 5, minutes: 30 }}
                                      >
                                        {new Date(payment?.paymentDate)}
                                      </Moment>
                                      ₹{Number(payment?.receivedPayement).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}</> : (
                          <p style={{ textAlign: "center" }}>No Payments Found</p>
                        )}

                    <div className={HomeClasses["payment_add_btn_c"]}>
                      {(status == 15 && orderdetails?.pendingAdvance !== 0 && !viewFlag && orderdetails?.deliveryType?.deliveryTypeId === 2) &&
                        <button
                          className={HomeClasses["payment_add_btn"]}
                          type="button"
                          onClick={() => { setPaymentModel(true) }}
                        >
                          Add Payment
                        </button>
                      }
                    </div>
                  </div>
                ) : ""}

                {/* Calclation reason */}
                <div className={HomeClasses["order_update_container"]}>
                  {orderdetails.suplierOrdersStatus === 6 ? (
                    <>
                      <div className={HomeClasses["order_update_s"]}>
                        <h1 id={HomeClasses["order_Status"]}>Reason</h1>
                        <p id={HomeClasses["order_update_status"]}>
                          {orderdetails.rejectReason}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={HomeClasses["order_update_s"]}>
                        <h1 id={HomeClasses["order_Status"]}>Update Status</h1>
                        <p id={HomeClasses["order_update_status"]}>
                          {orderdetails.userOrdersStatus === 1
                            ? "Pending"
                            : orderdetails.userOrdersStatus === 2
                              ? "Processing"
                              : orderdetails.userOrdersStatus === 3
                                ? "Invoice printed"
                                : orderdetails.userOrdersStatus === 4
                                  ? "Transport"
                                  : orderdetails.userOrdersStatus === 5
                                    ? "Delivered"
                                    : orderdetails.userOrdersStatus === 6
                                      ? "Rejected"
                                      : orderdetails.userOrdersStatus === 7
                                        ? "Completed"
                                        : orderdetails.userOrdersStatus === 8
                                          ? "Quality Difference"
                                          : ""
                          }
                        </p>
                      </div>
                    </>
                  )}
                </div>

                {/* Quality Difference products */}
                {(orderdetails.buyerQD === 1) && (
                  <div className={HomeClasses.transportData}>
                    <div className={HomeClasses["transport_header"]}>
                      <h2 id={HomeClasses["order_d_h2"]}>Quality Difference products</h2>
                    </div>
                    <div className={HomeClasses["QD_products"]}>
                      {
                        orderdetails?.Effectedproducts.length > 0 ? (
                          orderdetails?.Effectedproducts?.map((item, index) => (
                            <div key={index} className={HomeClasses["EffectedProducts"]}>
                              <div className={HomeClasses["order_prod_info"]}>
                                <h5 id={HomeClasses["order_prod_name"]}>{item.productName}</h5>
                                <p className={HomeClasses["order_prod_Qty"]}>
                                  {item.productCategory}{" . "}{item?.productSubCategory}
                                </p>
                                <div className={HomeClasses["order_prod_price"]}>
                                  <p className={HomeClasses["order_prod_Qty"]}>Qty: {item?.qty}</p>
                                  <p className={HomeClasses["order_prod_Qty"]}>Effected Qty: {item?.effectedQuantity}</p>
                                  {
                                    item?.effectedProductPrice > 0 &&
                                    <p className={HomeClasses["order_prod_Qty"]}>Effected Price:<span>₹ {item?.effectedProductPrice}</span></p>
                                  }
                                </div>
                              </div>
                              {
                                (item.productPriceUnit === "Quintal" || item.productPriceUnit === "Bag") &&
                                <div className={HomeClasses["order_prod_price"]}>
                                  <p className={HomeClasses["order_prod_Qty"]}>Total Quintal: {item?.weightInQuintal}</p>
                                  <p className={HomeClasses["order_prod_Qty"]}>Effected Quintal: {item?.effectedWeightInQuintal}</p>
                                  <p className={HomeClasses["order_prod_Qty"]}>Per Quintal: ₹{item?.effectedPricePerQuintal}</p>
                                </div>
                              }
                            </div>
                          ))) :
                          <p style={{ textAlign: "center" }}>No Products Found</p>
                      }
                    </div>
                    <div className={HomeClasses["orderHeader_wrappe"]}>
                      <p className={HomeClasses.QDNotes}>Quality Difference Notes:</p>
                      <p className={HomeClasses.ordernote}>{orderdetails?.qualityDifferenceNotes}</p>
                    </div>
                  </div>
                )}

                {/* Final Price */}
                {(orderdetails?.qualityDifferencePrice !== 0 && !viewFlag) ? (
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Final Price After QD</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        ₹{Number(orderdetails?.qualityDifferencePrice).toFixed(2)}
                      </p>
                    </p>
                  </div>
                ) : ""}

                {/*Qulaity Difference Rise */}
                {
                  orderdetails?.buyerQD === 0 ?
                    <>
                      {(orderdetails.suplierOrdersStatus === 4 && accountType == 2 && orderdby?.accountType === 5) ?
                        <div className={HomeClasses["FinalPrice-wrapper"]}>
                          <button
                            type="button"
                            className={HomeClasses["order_remainder_btn"]}
                            onClick={() => { setIsOpen(true) }}
                          >
                            Quality Difference
                          </button> </div> : ""
                      }
                    </> : ""
                }

                {/* Reorder for buyers */}
                <div className={HomeClasses["order_remainder"]}>
                  {[5, 7, 10, 11, 13].includes(orderdetails.suplierOrdersStatus) && !viewFlag && (
                    <button
                      type="button"
                      className={HomeClasses["reorder_button"]}
                      onClick={() => Reorder(orderdetails.orderId)}
                    >
                      Reorder
                    </button>)}

                  {orderdetails?.invoice !== "NA" &&
                    !viewFlag &&
                    (
                      [3, 5, 7].includes(orderdetails?.suplierOrdersStatus) ||
                      [10, 11, 12, 13, 15].includes(status)
                    ) && (
                      <button
                        type="button"
                        className={HomeClasses["order_remainder_btn"]}
                        onClick={InvoiceBill}
                      >
                        <LuDownload /> Order Information
                      </button>
                    )
                  }

                  {((orderdetails.userOrdersStatus === 1 ||
                    orderdetails.userOrdersStatus === 2) && !viewFlag) && (
                      <button
                        type="button"
                        className={HomeClasses["order_remainder_btn"]}
                        onClick={() => setCancelModal(true)}
                      >
                        Cancel Order
                      </button>
                    )}
                </div>

                {/* Effected  price  for Agent pay */}
                {(status == 8 && accountType == 5 && !viewFlag) &&
                  orderdetails.Effectedproducts.some((item) => item.effectedProductPrice === 0) ? (
                  <div className={HomeClasses["FinalPrice-wrapper"]}>
                    <button type="button"
                      onClick={() => setPriceModal(true)}>
                      Add Effected Price
                    </button>
                  </div>
                ) : ""
                }

                {/* Affected Price final Modal  */}
                {
                  (status == 8 &&
                    accountType === 6 &&
                    !viewFlag &&
                    orderdetails.Effectedproducts.some(item => item.effectedProductPrice === 0)) &&
                  (accountType === 6 && PermissionQuality === 1) && (
                    <div className={HomeClasses["FinalPrice-wrapper"]}>
                      <button type="button" onClick={() => setPriceModal(true)}>
                        Add Effected Price
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )}

        {/* Cancel  order Modal */}
        {cancelModal && (
          <div className={HomeClasses["reject_modal"]}>
            <div
              onClick={() => setCancelModal(false)}
              className={HomeClasses["overlaye"]}
            ></div>
            <div className={HomeClasses["update_modal_content"]}>
              <h2 id={HomeClasses["resct_header"]}>Cancel Order</h2>
              <div className={HomeClasses["update_input_model_container"]}>
                <div className={HomeClasses["banner_i_container"]}>
                  <input
                    type="textarea"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    placeholder="Description"
                    autoFocus
                  />
                </div>
                <div className={HomeClasses["cancel_form_btns"]}>
                  <button
                    type="button"
                    className={HomeClasses["cancel_c_btn"]}
                    onClick={() => setCancelModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={HomeClasses["cancel_s_btn"]}
                    disabled={!rejectReason}
                    onClick={() => {
                      cancelOrder(rejectReason);
                      setCancelModal(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Update Quality check */}
        {isOpen && (
          <QualityDifference
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            orderDetails={orderdetails}
            onClose={() => setIsOpen(false)}
            getOrderdetails={OrderDetails}
          />
        )
        }

        {priceModal &&
          <QualityPriceChange
            isOpen={priceModal}
            setIsOpen={setPriceModal}
            orderDetails={orderdetails}
            onClose={() => setPriceModal(false)}
            orderdetails={OrderDetails}
          />}

        {/* Transport Update */}
        {updateTransport &&
          <UpdateTransports
            isOpen={updateTransport}
            setIsOpen={setUpdateTransport}
            onClose={() => setUpdateTransport(false)}
            orderId={orderId}
            orderDetails={OrderDetails}
            transportDetails={orderdetails.transportDetails}
          />}

        {/* payment model */}
        {paymentModel &&
          <OrderPayment
            isOpen={paymentModel}
            onClose={() => setPaymentModel(false)}
            orderId={orderId}
            orderDetails={orderdetails}
            status={status}
            orderdetails={OrderDetails}
          />
        }

        {/* Agent Address Chnage  for if any Cancilations */}
        {addressChange &&
          <AgentAddressChange
            isOpen={addressChange}
            setIsOpen={setAddressChange}
            orderdetails={OrderDetails}
            orderId={orderId}
            onClose={() => setAddressChange(false)}
          />}

      </div>
    </Layout >
  );
};

export default PurchaseDetails;
