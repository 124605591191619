export const NameChange = () => {
  const accountType = parseInt(localStorage.getItem("AccountType"), 10);
  const PermissionAccount = parseInt(
    localStorage.getItem("permissionAccount"),
    10
  );
  let ChangedName = "";
  console.log(accountType);
  if (accountType === 5 || (accountType === 6 && PermissionAccount === 6)) {
    ChangedName = "Broker Price";
  } else {
    ChangedName = "Buyer Price";
  }
  return ChangedName;
};
