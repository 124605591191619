import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import lodash from "lodash";
import HomeClasses from "../../pages/Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { Error, Success, SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import dropBox from "../../assets/orderbox.png";
import { IoIosCall } from "react-icons/io";
import { MdBusinessCenter, MdDelete, MdLocationPin } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { BiRupee, BiSolidCreditCardFront } from "react-icons/bi";
import "react-datepicker/dist/react-datepicker.css";
import { RotatingLines } from "react-loader-spinner";
import { override } from "./helper";
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import { LuDownload } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import Image from '../../assets/noplot.png'
import moment from "moment";
import AddTransportModal from "../Shop/AddTransportModal";
import OrderPayment from "./OrderPayment";
import { RxCross2 } from "react-icons/rx";
import UpdateTransports from "../PurchaseOrders/UpdateTransports";
import LoadingDateChange from "../PurchaseOrders/LoadingDateChange";
import AddFinalQDPrice from "./Qualitydiference/AddFinalQDPrice";
import CommissionList from "../PurchaseOrders/CommissionList";
import { NameChange } from "../../components/utils/Functions";
import FieldsModal from "./FieldsModal";

const Orderdetails = () => {
  const { orderId, status } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const [orderdetails, setOrderdetails] = useState({});
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderdby, setOrderedby] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [priviousPayments, setPriviousPayments] = useState([]);
  const [millerRecordNumber, setMillerRecordNumber] = useState('');
  const [millerBrokerNotes, setMillerBrokerNotes] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [updateTransport, setUpdateTransport] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  const [addTotalPrice, setAddTotalPrice] = useState(false);
  const [effectedTotalPrice, setEffectedTotalPrice] = useState(0);
  const [orderFinalPrice, setOrderFinalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [dataModal, setDataModal] = useState(false);
  const [fieldId, setFieldId] = useState(null);


  const { viewFlag } = location.state || {};
  const SelectedTab = JSON.parse(localStorage.getItem("selectedTab"));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = parseInt(localStorage.getItem("AccountType"), 10);

  // Permissions for accounts
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 6
  );
  const Permission = addProduct.length > 0 ? addProduct[0].status : 1;
  const paymentPermission = addPermissions.filter(
    (item) => item.permissionId === 14
  );
  const PayPermission =
    paymentPermission.length > 0 ? paymentPermission[0].status : 1;


  useEffect(() => {
    OrderDetails();
  }, []);
  let transportData = JSON.parse(localStorage.getItem("Transport Details"));


  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, []);

  const OrderDetails = async () => {
    setLoading(true)
    let body = {
      orderId: orderId,
      commissionType: parseInt(status, 10)
    }
    try {

      const res = await Services.getOrderdetails("POST", JSON.stringify(body), token);
      if (res.Status == 1) {
        setOrderdetails({ ...res.orderDetails });
        setAllProducts(lodash.cloneDeep(res.orderDetails.products));
        setProducts(res.orderDetails.products);
        setOrderedby(res.orderedBy);
        setPriviousPayments(res.previousPendingPayments);
        setLoading(false);
        setOrderFinalPrice(res.orderDetails?.finalAmount);

      }
    } catch (err) {
      console.error(err);
    }
  };

  // Effected ProductsTotal price 
  useEffect(() => {
    if (orderdetails?.Effectedproducts?.length > 0) {

      let effectedproductsprice = orderdetails?.Effectedproducts?.reduce((acc, curr) => {
        if (
          String(curr?.productPriceUnit) === "Quintal"
        ) {

          return curr?.effectedProductPrice + acc;
        }
        return curr?.effectedProductPrice + acc;
      }, 0);
      setEffectedTotalPrice(effectedproductsprice);
      // console.log(effectedproductsprice);
    }
  }, [orderdetails?.Effectedproducts]);

  useEffect(() => {
    const storedPrice = parseFloat(localStorage.getItem("TotalQDPrice")) || 0;

    const price = storedPrice > 0 ? storedPrice : (orderFinalPrice - effectedTotalPrice);
    setFinalPrice(price);
  }, [orderFinalPrice, effectedTotalPrice]);

  const UpdateStatus = async (event) => {
    const selectedStatus = event.target.value;

    if (orderdetails?.transportDetails?.name === "NA" ||
      (typeof orderdetails?.transportDetails === "object" &&
        Object.keys(orderdetails?.transportDetails).length === 0) && (selectedStatus == 4 || selectedStatus == 5)) {
      warningSwal("warning", "Please Fill the Transport Details")
      return;
    }

    if ((selectedStatus == 4 || selectedStatus == 5) && orderdetails?.loadingDate === null) {
      warningSwal("warning", "Please provide the loading date.");
      return;
    }

    if ((selectedStatus == 4 || selectedStatus == 5) && (orderdetails?.cashDiscountDays === 0 || orderdetails?.hamaliCharges === 0)) {
      if (orderdetails?.cashDiscountDays === 0) {
        warningSwal("warning", "Please Fill the Cash Discount Days.");
      } else {
        warningSwal("warning", "Please Fill the Hamali Charges.");
      }
      return;
    }


    const body = {
      suplierOrdersStatus: selectedStatus,
      orderId: orderId,
    };
    try {
      const res = await Services.updateOrderStatus(
        "PATCH",
        JSON.stringify(body),
        token
      );

      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        OrderDetails();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const Orderapprove = async (type, reason) => {
    const body = {
      userOrdersStatus: type,
      orderId: orderId,
      rejectReason: reason,
      products: orderdetails.products,
    };

    try {
      const res = await Services.approveAndrejectOrder(
        "PATCH",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        OrderDetails();
        setShowRejectModel(false);
        Navigate(-1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Update Order Quantity
  const UpdateQty = async () => {
    let body = {
      orderId: orderdetails.orderId,
      products: allProducts,
    };

    try {
      const res = await Services.updateQTY("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setShowModal(false);
        OrderDetails();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // add Discount
  const finalAmount = async (data) => {
    try {
      const res = await Services.AddFinalAmount(
        "POST",
        JSON.stringify(data),
        token,
        orderdetails.orderId
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setDiscountModal(false);
        setValue(" ");
        OrderDetails();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleQty = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("id");
    let products = [...allProducts];
    let selectedProductIndex = products.findIndex(
      (item) => item.productId == id
    );
    // products[selectedProductIndex].qty = event.target.value;
    // setAllProducts((prev) => [...products]);

    const inputValue = event.target.value;
    const isNumeric = /^[0-9]*$/.test(inputValue);

    if (isNumeric) {
      products[selectedProductIndex].qty = inputValue;
      setAllProducts((prev) => [...products]);
    } else {
      alert("Invalid quantity.");
    }
  };

  const InvoiceBill = () => {
    window.open(orderdetails.invoice, "_blank");
  };

  const Orderdata = (orderId) => {
    Navigate(`/orderdetails/${orderId}`);
  };

  const AddMillerRecordNumber = async () => {

    let body = {
      orderId: orderId,
      millerRecordNumber: millerRecordNumber,
      transportDetails: transportData,
      millerNotes: millerBrokerNotes,
    }

    try {
      const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        Success(res.Message);
        OrderDetails();
        localStorage.removeItem("Transport Details");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AddTransport = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }
  const PaymentModel = () => {
    setPaymentModel(true);
  };
  const closePaymentModel = () => {
    setPaymentModel(false);
  };

  const openRejectModel = () => {
    setShowRejectModel(true);
  };
  const closeRejectModel = () => {
    setShowRejectModel(false);
  };

  const DeleteOrderItem = async (itemId) => {
    let body = {
      orderId: orderId,
      oderedItemsId: itemId
    }
    try {
      const res = await Services.DeleteOrderItems("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        Success(res.Message);
        OrderDetails();
        setShowModal(false);

      } else if (res.Status === 0) {
        Error(res.Message);
      }
    } catch (err) {
      console.error(err);
    }
  }


  const AddQDPrice = async () => {
    let body = {
      orderId: orderId,
      qualityDifferencePrice: finalPrice,
      approveQualityDifference: 1

    }
    try {
      const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        Success(res.Message);
        localStorage.removeItem("TotalQDPrice");
        OrderDetails();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Commsions  filtering

  const getCommissionTypeLabel = (type) => {
    switch (type) {
      case 10:
        return "Miller Commissions";
      case 11:
        return "Distributor Commissions";
      case 12:
        return "Sub Broker Commissions";
      case 13:
        return "Transport Commissions";
      default:
        return "Unknown Commission Type";
    }
  };

  const filterCommissionsByType = (type) => {
    const typeLabel = getCommissionTypeLabel(type);
    return {
      typeLabel,
      commissions: orderdetails?.commissions?.filter(commission => commission.commissionType === type)
    };
  };

  const filteredCommissions = {
    millerCommissions: filterCommissionsByType(10),
    distributorCommissions: filterCommissionsByType(11),
    subBrokerCommissions: filterCommissionsByType(12),
    transportCommissions: filterCommissionsByType(13)
  };

  const FieldModal = (id) => {
    setFieldId(id);
    setDataModal(true);
  }

  return (

    <Layout Active={"Sales Orders"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Add_main_container"]}>
            <div className={HomeClasses["Add_header"]}>
              <button
                className={HomeClasses["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                  localStorage.removeItem("TotalQDPrice");
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["add_header_h2"]}>OrderID#{orderId}</h1>
            </div>
            <div className={HomeClasses["order_d_container"]}>
              {
                !viewFlag &&
                <div className={HomeClasses["approval_container"]}>
                  {(accountType == 1 && orderdetails?.millerCommission !== 0 && orderdetails?.millerCommission !== 0) &&
                    <div className={HomeClasses["approvals"]}>
                      <div className={HomeClasses["radious_wrapper"]}>
                        <FaUser size={20} />
                      </div>
                      <div className={HomeClasses["text_container"]}>
                        <p id={HomeClasses["order_d_text"]}>Miller to Broker </p>
                        <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.millerCommission).toFixed(2)}</p>
                      </div>
                    </div>}

                  {
                    (orderdetails?.advance !== 0 && orderdetails?.advance !== null && orderdetails?.deliveryType?.deliveryTypeId === 2) &&
                    <div className={HomeClasses["approvals"]}>
                      <div className={HomeClasses["radious_wrapper"]}>
                        <FaUser size={20} />
                      </div>
                      <div className={HomeClasses["text_container"]}>
                        <p id={HomeClasses["order_d_text"]}>Transport Advance Pay </p>
                        <p id={HomeClasses["order_d_text"]}> ₹{Number(orderdetails?.advance).toFixed(2)}</p>
                      </div>
                    </div>
                  }

                  {
                    (accountType == 2 && orderdetails?.distributorCommission !== null && orderdetails?.distributorCommission !== 0) && (
                      <div className={HomeClasses["approvals"]}>
                        <div className={HomeClasses["radious_wrapper"]}>
                          <FaUser size={20} />
                        </div>
                        <div className={HomeClasses["text_container"]}>
                          <p id={HomeClasses["order_d_text"]}>Distributor to Broker </p>
                          <p id={HomeClasses["order_d_text"]}>Commission ₹{Number(orderdetails?.distributorCommission).toFixed(2)}</p>
                        </div>
                      </div>)}
                </div>
              }

              {/*While going Through dashbord  */}

              {
                viewFlag && SelectedTab?.accountType === 8 && (
                  <div className={HomeClasses["approval_container"]}>
                    <div className={HomeClasses["approvals"]}>
                      <div className={HomeClasses["radious_wrapper"]}>
                        <FaUser size={20} />
                      </div>
                      <div className={HomeClasses["text_container"]}>
                        {accountType === 1 && (
                          <>
                            <p id={HomeClasses["order_d_text"]}>Miller to Broker</p>
                            <p id={HomeClasses["order_d_text"]}>
                              Commission ₹{Number(orderdetails.millerCommission).toFixed(2)}
                            </p>
                          </>
                        )}

                        {accountType === 2 && (
                          <>
                            <p id={HomeClasses["order_d_text"]}>Distributor to Broker</p>
                            <p id={HomeClasses["order_d_text"]}>
                              Commission ₹{Number(orderdetails.distributorCommission).toFixed(2)}
                            </p>
                          </>
                        )}
                      </div>

                    </div>
                    <div className={HomeClasses["approvals"]}>
                      <div className={HomeClasses["radious_wrapper"]}>
                        <FaUser size={20} />
                      </div>
                      <div className={HomeClasses["text_container"]}>
                        {(accountType === 1 && orderdetails?.deliveryType?.deliveryTypeId === 2) && (
                          <>
                            <p id={HomeClasses["order_d_text"]}>Transport Advance Pay</p>
                            <p id={HomeClasses["order_d_text"]}>
                              ₹{Number(orderdetails?.advance).toFixed(2)}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }

              {
                (orderdetails.suplierOrdersStatus !== 1) && (
                  <div className={HomeClasses["order_box_container"]}>
                    <div className={HomeClasses["order_s_box"]}>
                      <div className={HomeClasses["order_image_container"]}>
                        <img
                          src={dropBox}
                          alt="image"
                          className={HomeClasses["order_image"]}
                        />
                      </div>
                      <p id={HomeClasses["orders_status"]}>
                        <h5 id={HomeClasses["order_d_header"]}>
                          {orderdetails.suplierOrdersStatus === 1
                            ? " New order"
                            : orderdetails.suplierOrdersStatus === 2
                              ? "Order Approved"
                              : orderdetails.suplierOrdersStatus === 3
                                ? "Order Invoice Printed"
                                : orderdetails.suplierOrdersStatus === 4
                                  ? "Order Transport"
                                  : orderdetails.suplierOrdersStatus === 5
                                    ? "Order Delivered"
                                    : orderdetails.suplierOrdersStatus === 6
                                      ? "Order Rejected"
                                      : orderdetails.suplierOrdersStatus === 7
                                        ? "Order Completed"
                                        : "Order Quality Difference"}
                        </h5>
                        <span id={HomeClasses["order_d_date"]}>
                          <Moment
                            format="DD MMM, YYYY, hh:mm A"
                            subtract={{ hours: 5, minutes: 30 }}
                          >
                            {new Date(orderdetails.createdAt)}
                          </Moment>
                        </span>
                      </p>
                    </div>
                    <div className={HomeClasses["order_d_btns"]}>
                      {orderdetails.suplierOrdersStatus === 1 && (
                        <>
                          {accountType == 6 && Permission == 0 ? (
                            " "
                          ) : (
                            <>
                              <button
                                type="button"
                                className={HomeClasses["order_approve"]}
                                onClick={() => Orderapprove(2, null)}
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className={HomeClasses["order_reject"]}
                                onClick={() => openRejectModel()}
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>)}

              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Ordered By</h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby?.name}{" "}({orderdby?.accountType === 1
                      ? "Manufacturer "
                      : orderdby?.accountType === 2
                        ? "Distributor "
                        : orderdby?.accountType === 3
                          ? "Retailer"
                          : orderdby?.accountType === 4
                            ? "Marketing Specialist"
                            : orderdby?.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.mobileNumber}
                  </p>
                </p>
              </div>
              {
                (orderdetails?.subAgent && Object.keys(orderdetails?.subAgent).length > 0) &&
                <div className={HomeClasses["order_address"]}>
                  <h2 id={HomeClasses["order_d_h2"]}>Ordered By (Sub Broker)</h2>
                  <p id={HomeClasses["order_addres_text"]}>
                    <p className={HomeClasses["location_icon"]}>
                      {" "}
                      <HiMiniUser className={HomeClasses["order_d_icon"]} />
                    </p>
                    <p className={HomeClasses["icon_text_order"]}>
                      {" "}
                      {orderdetails?.subAgent?.name}{" "}({orderdetails?.subAgent?.accountType === 1
                        ? "Manufacturer "
                        : orderdetails?.subAgent?.accountType === 2
                          ? "Distributor "
                          : orderdetails?.subAgent?.accountType === 3
                            ? "Retailer"
                            : orderdetails?.subAgent?.accountType === 4
                              ? "Marketing Specialist"
                              : orderdetails?.subAgent?.accountType === 5
                                ? "Agent"
                                : "Office Staff"})
                    </p>
                  </p>
                  <p id={HomeClasses["order_addres_text"]}>
                    <p className={HomeClasses["location_icon"]}>
                      {" "}
                      <IoIosCall className={HomeClasses["order_d_icon"]} />
                    </p>
                    <p className={HomeClasses["icon_text_order"]}>
                      {" "}
                      {orderdetails?.subAgent?.mobileNumber}
                    </p>
                  </p>
                </div>
              }
              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>
                  Delivery To
                </h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverName}{" "}({orderdetails.accountType === 1
                      ? "Manufacturer "
                      : orderdetails.accountType === 2
                        ? "Distributor "
                        : orderdetails.accountType === 3
                          ? "Retailer"
                          : orderdetails.accountType === 4
                            ? "Marketing Specialist"
                            : orderdetails.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverbusinessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.deliveryAddress}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverMobileNumber}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <BiSolidCreditCardFront
                      className={HomeClasses["order_d_icon"]}
                    />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.GSTNumber}
                  </p>
                </p>
              </div>
              {/* Pending paymets  */}
              {(priviousPayments.length === 0) ? (
                <></>
              ) : (
                <>
                  {(orderdetails.suplierOrdersStatus === 1 && !viewFlag) && (
                    <div className={HomeClasses["p_payments"]}>
                      <h4 className={HomeClasses["p_header"]}>
                        Pending Payments
                      </h4>
                      <table className={HomeClasses["P_table"]}>
                        <thead className={HomeClasses["p_payment-header"]}>
                          <tr>
                            <th>OrderId</th>
                            <th>Pending Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {priviousPayments.map((payment, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => Orderdata(payment.orderId)}
                              >
                                <td>{payment.orderId}</td>
                                <td>₹{Number(payment.pendingPayment).toFixed(2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
              {/* products */}
              <div className={HomeClasses["order_products"]}>
                <h3 id={HomeClasses["order_prod_header"]}>
                  Package Items
                  {(orderdetails.suplierOrdersStatus === 2 && !viewFlag) && (
                    <button
                      type="button"
                      className={HomeClasses["qty_edit"]}
                      onClick={() => setShowModal(true)}
                    >
                      Edit Items
                    </button>
                  )}
                </h3>
                {orderdetails.products &&
                  orderdetails.products.map((product, index) => {
                    return (
                      <div
                        className={HomeClasses["order_prod_container"]}
                        key={index}
                      >
                        <div className={HomeClasses["order_prod_box"]}>
                          <div
                            className={HomeClasses["order_prog_img_container"]}
                          >
                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={Image}
                                  alt="image"
                                />
                              )
                            }
                          </div>
                          <div className={HomeClasses["order_prod_info"]}>
                            <h5 id={HomeClasses["order_prod_name"]}>
                              {product.productName}
                            </h5>
                            <p className={HomeClasses["order_prod_Qty"]}>
                              {product?.productCategory}{" . "}{product?.productSubCategory}
                            </p>
                            <p className={HomeClasses["order_prod_Qty"]}>
                              Qty: {product.qty}{" "}{product.productMeasuringUnit}'s
                            </p>
                          </div>
                          <div className={HomeClasses["discount_tag"]}>
                            {
                              product && (
                                <div className={HomeClasses["dicount_pricetag"]}>
                                  {product?.cashDiscount > 0 ? (
                                    <>
                                      <p className={HomeClasses["Mdicount"]}>{product.discount}%</p>
                                      <p className={HomeClasses["Adicount"]}>{product.cashDiscount}% OFF</p>
                                    </>
                                  ) : (
                                    <p className={HomeClasses["Adicount"]}>{product.discount}% OFF</p>
                                  )}
                                </div>
                              )
                            }
                          </div>
                        </div>
                        <div className={HomeClasses["order_prod_price"]}>
                          {
                            product?.productPriceUnit === "Quintal" ? <>
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  Miller Price :
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.bagPrice).toFixed(2)}/Bag</p>
                                </div>
                              </div>
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  Miller Price :
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.price).toFixed(2)}/Quintal</p>
                                </div>
                              </div>
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  {NameChange()}:
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.bagDiscountPrice).toFixed(2)}/Bag</p>
                                </div>
                              </div>
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  {NameChange()}:
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}/Quintal</p>
                                </div>
                              </div>
                            </> : <> <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                Miller Price :
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.price).toFixed(2)}</p>
                              </div>
                            </div>
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  {NameChange()} :
                                </p>
                                <div className={HomeClasses["order_rupee"]}>
                                  <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}</p>
                                </div>
                              </div>
                            </>
                          }
                          {
                            product?.packingBag !== null && (
                              <div className={HomeClasses["order_d_price"]}>
                                <p id={HomeClasses["order_price_container"]}>
                                  {product?.packingBag?.bagName} Price :
                                </p>
                                <p className={HomeClasses["Discountpice"]}>
                                  ₹{product?.packingBag?.price}/Quintal
                                </p>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    );
                  })}
              </div>
              {(orderdetails?.suplierOrdersStatus !== 1 && !viewFlag) && (
                orderdetails?.millerRecordNumber !== "NA" && orderdetails?.millerRecordNumber !== "" ? (
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Manufacturer(Miller) Record Number</p>
                    <p className={HomeClasses.ordervalues}>{(orderdetails?.millerRecordNumber)}</p>
                  </div>
                ) : <div className={HomeClasses["record_wrapper"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Manufacturer(Miller) Record Number</h3>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    AddMillerRecordNumber();
                  }}>
                    <div className={HomeClasses["form-control"]}>
                      <input
                        placeholder='Miller RecordNumber'
                        type="text"
                        value={millerRecordNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '');
                          setMillerRecordNumber(alphanumericValue);
                          if (!alphanumericValue) {
                            setError('Miller Record Number is required.');
                          } else {
                            setError('');
                          }
                        }}
                        min={1}
                      />
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                    {
                      millerRecordNumber.trim() && (
                        <button type="submit" className={HomeClasses["addRecords"]}>Add</button>)}
                  </form>
                </div>)
              }

              <div className={HomeClasses["order_flow_info"]}>
                <div className={HomeClasses["orderHeader_wrapper"]}>
                  <p className={HomeClasses.orderheaders}>Order Type</p>
                  <p className={HomeClasses.ordervalues}>{orderdetails?.orderType?.orderType}</p>
                </div>
                <div className={HomeClasses["orderHeader_wrapper"]}>
                  <p className={HomeClasses.orderheaders}>Delivery Type</p>
                  <p className={HomeClasses.ordervalues}>{orderdetails?.deliveryType?.deliveryType}</p>
                </div>
                {/* Loading Date */}
                <div className={HomeClasses["orderHeader_wrapper"]}>
                  <p className={HomeClasses.orderheaders}>Loading Date</p>
                  <div className={HomeClasses["LodingDate_wrapper"]}>
                    {
                      ((orderdetails.suplierOrdersStatus === 1 || orderdetails.suplierOrdersStatus === 2 || orderdetails.suplierOrdersStatus === 3) && !viewFlag) ? (
                        <div className={HomeClasses["editLoding"]}>
                          <p className={HomeClasses.ordervalues}>{orderdetails?.loadingDate !== null ? moment(orderdetails?.loadingDate).format("DD MMM, YYYY") : ""}</p>
                          <button className={HomeClasses["downbt"]} onClick={() => { setEditLoading(true) }}>{orderdetails?.loadingDate !== null && orderdetails?.loadingDate !== "NA" ? "Edit" : "Add"}</button>
                        </div>
                      ) : (
                        <p className={HomeClasses.ordervalues}>{moment(orderdetails?.loadingDate).format("DD MMM, YYYY")}</p>
                      )
                    }
                  </div>
                </div>
                {orderdetails.transportDetails &&
                  typeof orderdetails.transportDetails === "object" &&
                  Object.keys(orderdetails.transportDetails).length > 0 ?
                  <div className={HomeClasses.transportData}>
                    <div className={HomeClasses["transport_head_wrapper"]}>
                      <h2 id={HomeClasses["order_d_h2"]}>Transportation Details</h2>
                      {
                        ((orderdetails?.suplierOrdersStatus === 1 || orderdetails?.suplierOrdersStatus === 2 || orderdetails?.suplierOrdersStatus === 3) && orderdetails?.deliveryType?.deliveryTypeId === 1 && !viewFlag) &&
                        <button type="button" className={HomeClasses["downbt"]} onClick={() => { setUpdateTransport(true) }}>Edit</button>
                      }
                    </div>
                    <div className={HomeClasses.transport_details}>
                      <div className={HomeClasses["transport"]}>
                        <p className={HomeClasses["transport_label"]}>
                          Name
                        </p>
                        <p className={HomeClasses["transport_value"]}>
                          {orderdetails?.transportDetails?.name}
                        </p>
                      </div>
                      <div className={HomeClasses["transport"]}>
                        <p className={HomeClasses["transport_label"]}>
                          Vehicle Number
                        </p>
                        <p className={HomeClasses["transport_value"]}>
                          {orderdetails?.transportDetails?.vehicleNumber}
                        </p>
                      </div>
                      <div className={HomeClasses["transport"]}>
                        <p className={HomeClasses["transport_label"]}>
                          Driver Number
                        </p>
                        <p className={HomeClasses["transport_value"]}>
                          {orderdetails?.transportDetails?.driverNumber}
                        </p>
                      </div>
                      {
                        orderdetails?.deliveryType?.deliveryTypeId === 1 &&
                        <>
                          <div className={HomeClasses["transport"]}>
                            <p className={HomeClasses["transport_label"]}>
                              Total (LF)
                            </p>
                            <p className={HomeClasses["transport_value"]}>
                              ₹{orderdetails?.deliveryType?.deliveryTypeId === 1 ? orderdetails?.transportDetails?.preBooking : orderdetails?.transportDetails?.postBooking}
                            </p>
                          </div>
                          <div className={HomeClasses["transport"]}>
                            <p className={HomeClasses["transport_label"]}>
                              Advance (LF)
                            </p>
                            <p className={HomeClasses["transport_value"]}>
                              ₹{orderdetails?.transportDetails?.advance}
                            </p>
                          </div>
                          <div className={HomeClasses["transport"]}>
                            <p className={HomeClasses["transport_label"]}>
                              Balance (LF)
                            </p>
                            <p className={HomeClasses["transport_value"]}>
                              ₹{orderdetails?.transportDetails?.balance}
                            </p>
                          </div>
                        </>
                      }
                    </div>
                  </div> :
                  <div className={HomeClasses["Add_Transport_wrapper"]}>
                    {
                      !viewFlag && orderdetails?.suplierOrdersStatus !== 1 && <>
                        <h2 id={HomeClasses["order_d_h2"]}>Transportation Details</h2>
                        <button className={HomeClasses["addRecords"]} onClick={() => AddTransport()}>Add</button>
                      </>
                    }
                  </div>
                }
                {
                  orderdetails?.orderThrough &&
                  Object.keys(orderdetails?.orderThrough).length > 0 &&
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Ordered Through</p>
                    <p className={HomeClasses.ordervalues}>{orderdetails?.orderThrough?.orderThrough}</p>
                  </div>
                }

                {orderdetails?.orderThrough?.orderThroughId === 2 &&
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Sub Broker</p>
                    <p className={HomeClasses.ordervalues}>{orderdetails?.subAgent?.name}</p>
                  </div>}
                {/* When Through dashbord */}
                {
                  (viewFlag && (accountType == 1 && SelectedTab?.accountType === 8) && orderdetails?.millerCommissionPerQuintal !== 0 && orderdetails?.millerCommissionPerQuintal !== null) ?
                    < div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Miller Commissions</p>
                      <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.millerCommissionPerQuintal).toFixed(2)}/Quintal</p>
                    </div>
                    : (viewFlag && (accountType == 2 && SelectedTab?.accountType === 8) && orderdetails?.distributionCommissionPerQuintal !== 0 && orderdetails?.distributionCommissionPerQuintal !== null) ?
                      <div className={HomeClasses["orderHeader_wrapper"]}>
                        <p className={HomeClasses.orderheaders}>Buyer Commissions</p>
                        <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.distributionCommissionPerQuintal).toFixed(2)}/Quintal</p>
                      </div> : ""}

                {(!viewFlag && orderdetails?.millerCommissionPerQuintal !== 0 && orderdetails?.millerCommissionPerQuintal !== null) && <>
                  < div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Miller Commissions</p>
                    <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.millerCommissionPerQuintal).toFixed(2)}/Quintal</p>
                  </div>
                  {
                    (accountType == 2 && orderdetails?.millerCommissionPerQuintal !== 0 && orderdetails?.millerCommissionPerQuintal !== null) && (
                      <div className={HomeClasses["orderHeader_wrapper"]}>
                        <p className={HomeClasses.orderheaders}>Buyer Commissions</p>
                        <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.distributionCommissionPerQuintal).toFixed(2)}/Quintal</p>
                      </div>
                    )}
                </>}

                <div className={HomeClasses["orderHeader_wrapper"]}>
                  <p className={HomeClasses.orderheaders}>Cash Discount Days (CD/CC)</p>
                  {
                    (accountType === 1 || accountType === 2) ? (
                      ((orderdetails?.cashDiscountDays === null || orderdetails?.cashDiscountDays === 0) && orderdetails?.suplierOrdersStatus !== 1) ? (
                        <button type="button" className={HomeClasses["downbt"]} onClick={() => FieldModal(1)}>Add</button>
                      ) : (
                        <p className={HomeClasses.ordervalues}>
                          {orderdetails?.cashDiscountDays}{" Days"}
                        </p>
                      )
                    ) : ""
                  }
                </div>


                <div className={HomeClasses["orderHeader_wrapper"]}>
                  <p className={HomeClasses.orderheaders}>Hamali Charges</p>
                  {
                    (accountType === 1 || accountType === 2) ? (
                      ((orderdetails?.hamaliCharges === null || orderdetails?.hamaliCharges === 0) && orderdetails?.suplierOrdersStatus !== 1) ? (
                        <button type="button" className={HomeClasses["downbt"]} onClick={() => FieldModal(2)}>Add</button>
                      ) : (
                        <p className={HomeClasses.ordervalues}>₹{Number(orderdetails?.hamaliCharges).toFixed(2)}</p>
                      )
                    ) : ""
                  }
                </div>
                {(orderdetails?.hamaliChargesPerQuintal !== null && orderdetails?.hamaliChargesPerQuintal !== 0) &&
                  <div className={HomeClasses["orderHeader_wrapper"]}>
                    <p className={HomeClasses.orderheaders}>Hamali Charges(Quintal)</p>
                    <p className={HomeClasses.ordervalues}>₹{orderdetails?.hamaliChargesPerQuintal}/Quintal</p>
                  </div>}

                {(orderdetails?.notes !== "NA") &&
                  <div className={HomeClasses["orderHeader_wrappe"]}>
                    <p className={HomeClasses.QDNotes}>Broker Notes:</p>
                    <p className={HomeClasses.ordernote}>{orderdetails?.notes}</p>
                  </div>
                }

              </div>
              {
                (orderdetails.suplierOrdersStatus !== 1 && !viewFlag) && (
                  (orderdetails?.millerNotes !== "NA") ?
                    <div className={HomeClasses["orderHeader_wrapper"]}>
                      <p className={HomeClasses.orderheaders}>Miller Notes</p>
                      <p className={HomeClasses.ordervalues}>{orderdetails?.millerNotes}</p>
                    </div> : <div className={HomeClasses["record_wrappers"]}>
                      <h3 id={HomeClasses["order_paments_header"]}>Miller Notes</h3>
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        AddMillerRecordNumber();
                      }}>
                        <div className={HomeClasses["form-controls"]}>
                          <textarea
                            rows={5}
                            placeholder='Miller Broker Notes'
                            type="text"
                            value={millerBrokerNotes}
                            onChange={(e) => setMillerBrokerNotes(e.target.value)}
                          />
                        </div>
                        {
                          millerBrokerNotes.trim() && (
                            <button type="submit" className={HomeClasses["addRecords"]}>Add</button>
                          )
                        }

                      </form>
                    </div>)
              }

              <div className={HomeClasses["Order_prices"]}>
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Total Quintal</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      {Number(orderdetails?.totalWeightInQuintal).toFixed(2)}
                    </p>
                  </p>
                </div>
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Total Tons</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      {Number(orderdetails?.totalWeightInTons).toFixed(2)}
                    </p>
                  </p>
                </div>
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Subtotal</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      ₹{Number(orderdetails?.totalPrice).toFixed(2)}
                    </p>
                  </p>
                </div>
                {
                  (orderdetails?.transportDetails &&
                    typeof orderdetails?.transportDetails === "object" &&
                    Object.keys(orderdetails?.transportDetails).length > 0) &&
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>{orderdetails?.deliveryType?.deliveryTypeId === 1 ? "Advance Pay(Miller)" : "Advance pay(Broker)"}</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        ₹{orderdetails?.transportDetails?.advance !== null ? Number(orderdetails?.transportDetails?.advance).toFixed(2) : 0}
                      </p>
                    </p>
                  </div>
                }
                {
                  (orderdetails?.totalPackingBags !== 0 && orderdetails?.totalPackingBags !== null) &&
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Packing Bags</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        ₹{Number(orderdetails?.totalPackingBags).toFixed(2)}
                      </p>
                    </p>
                  </div>
                }

                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Order Price</p>
                  <p className={HomeClasses["order_discount"]}>
                    {
                      orderdetails?.agentOrderPrice === orderdetails?.finalAmount ?
                        <p id={HomeClasses["order_price_rupee"]}>
                          ₹{Number(orderdetails?.agentOrderPrice).toFixed(2)}
                        </p>
                        :
                        <p id={HomeClasses["normal"]}>
                          ₹{Number(orderdetails?.agentOrderPrice).toFixed(2)}
                        </p>
                    }
                    <div className={HomeClasses["Invoice-Prices"]}>
                      <p> {
                        orderdetails?.agentOrderPrice !== orderdetails?.finalAmount && (<p id={HomeClasses["order_price_rupee"]}>Invoice Amount : ₹{Number(orderdetails?.finalAmount).toFixed(2)}</p>)
                      }</p>
                      <p>{
                        orderdetails?.invoiceNumber !== "NA" && (<>Invoice Number : {orderdetails?.invoiceNumber}</>)
                      }</p>
                    </div>
                    <p className={HomeClasses["discountprice"]}>
                      {
                        (orderdetails?.suplierOrdersStatus === 2 && !viewFlag) && (<button
                          type="button"
                          className={HomeClasses["discount_bttton"]}
                          onClick={() => setDiscountModal(true)}
                        >Add Invoice Details
                        </button>)
                      }
                    </p>
                  </p>
                </div>
              </div>
              {
                (
                  orderdetails?.pendingPayment !== null && orderdetails?.pendingPayment !== 0) ?
                  <div className={HomeClasses["commissions-pending"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Pending Payment</h3>
                    <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails?.pendingPayment).toFixed(2)}</p>
                  </div> : ""}
              {orderdetails?.suplierOrdersStatus === 6 ? (
                <></>
              ) : (

                <div className={HomeClasses["order_d_payments"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Payments</h3>
                  {
                    orderdetails?.payments?.length > 0 ? <>
                      <div className={HomeClasses["payment_headers"]}>
                        <p id={HomeClasses["header_payments"]}>Payment Date</p>
                        <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                      </div>
                      {orderdetails.payments &&
                        orderdetails.payments.map((payment, index) => {
                          return (
                            <div
                              className={HomeClasses["order_payment_lists"]}
                              key={index}
                            >
                              <div className={HomeClasses["payment_data"]}>
                                <div className={HomeClasses["payment_item"]}>
                                  <h4>
                                    {payment?.name}
                                  </h4>
                                  <p className={HomeClasses["orderpay"]}>Type : <span>{payment?.paymentType}</span></p>
                                  {
                                    (payment?.transactionNumber !== "NA" && payment?.transactionNumber !== null) &&
                                    <p className={HomeClasses["orderpay"]}>Transaction No : <span>{payment?.transactionNumber}</span></p>
                                  }
                                </div>
                                <p id={HomeClasses["payment_item"]}>
                                  <Moment
                                    format="DD MMM, YYYY, hh:mm A"
                                    subtract={{ hours: 5, minutes: 30 }}
                                  >
                                    {new Date(payment.createdAt)}
                                  </Moment>
                                  ₹{Number(payment.receivedPayement).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          );
                        })}</> : <p style={{ textAlign: "center" }}>No Payments available</p>}

                  {accountType == 6 && PayPermission == 0 ? (
                    ""
                  ) : (
                    <div className={HomeClasses["payment_add_btn_c"]}>
                      {orderdetails.paymentStatus === 0 && !viewFlag &&
                        orderdetails.suplierOrdersStatus !== 1 &&
                        orderdetails.suplierOrdersStatus !== 2 &&
                        orderdetails.suplierOrdersStatus !== 7 && (
                          <button
                            className={HomeClasses["payment_add_btn"]}
                            type="button"
                            onClick={() => PaymentModel()}
                          >
                            Add Payment
                          </button>
                        )}
                    </div>
                  )}
                </div>
              )}
              {/* Commissions */}
              {
                (status == 10 || status == 11 &&
                  orderdetails.pendingCommission !== null && orderdetails.pendingCommission !== 0) ?
                  <div className={HomeClasses["commissions-pending"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Pending Commissions</h3>
                    <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails.pendingCommission).toFixed(2)}</p>
                  </div> : ""}

              {(status == 10 || status == 11) && (
                <div className={HomeClasses["order_d_payments"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Commissions</h3>
                  <div className={HomeClasses["payment_headers"]}>
                    <p id={HomeClasses["header_payments"]}>Payment Date</p>
                    <p id={HomeClasses["header_payments"]}>Received Payments</p>
                  </div>

                  {Array.isArray(orderdetails.commissions) && orderdetails.commissions.length > 0 ? (
                    orderdetails.commissions.map((payment, index) => {
                      return (
                        <div
                          className={HomeClasses["order_payment_lists"]}
                          key={index}
                        >
                          <div className={HomeClasses["payment_data"]}>
                            <div className={HomeClasses["payment_item"]}>
                              <h4>{payment?.name}</h4>
                              <p className={HomeClasses["orderpay"]}>
                                Type : <span>{payment?.paymentType}</span>
                              </p>
                              <p className={HomeClasses["orderpay"]}>
                                Transaction No : <span>{payment?.transactionNumber}</span>
                              </p>
                            </div>
                            <p id={HomeClasses["payment_item"]}>
                              <Moment
                                format="DD MMM, YYYY, hh:mm A"
                                subtract={{ hours: 5, minutes: 30 }}
                              >
                                {new Date(payment?.paymentDate)}
                              </Moment>
                              ₹{Number(payment?.receivedPayment || 0).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: "center" }}>No commissions available</p>
                  )}
                </div>
              )
              }

              {status == 7 && (
                <div className={HomeClasses["commissions-container"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Commissions Payments</h3>
                  <div className={HomeClasses["payment_header"]}>
                    <p id={HomeClasses["header_payments"]}>Payment Details</p>
                    <p id={HomeClasses["header_payments"]}>Payment Received</p>
                  </div>
                  {accountType == 1 && (
                    <>
                      {filteredCommissions?.millerCommissions?.commissions?.length > 0 && (
                        <CommissionList typeLabel={filteredCommissions.millerCommissions.typeLabel} commissions={filteredCommissions.millerCommissions.commissions} />
                      )}
                      {/*                       
                      {filteredCommissions?.transportCommissions?.commissions?.length > 0 && (
                        <CommissionList typeLabel={filteredCommissions.transportCommissions.typeLabel} commissions={filteredCommissions.transportCommissions.commissions} />
                      )} */}
                    </>
                  )}

                  {accountType == 2 && (
                    <>
                      {/* Show only Buyer and Transport Commissions */}
                      {filteredCommissions?.distributorCommissions?.commissions?.length > 0 && (
                        <CommissionList typeLabel={filteredCommissions.distributorCommissions.typeLabel} commissions={filteredCommissions.distributorCommissions.commissions} />
                      )}
                    </>
                  )}

                  {Object.values(filteredCommissions).every(({ commissions }) => commissions && commissions.length === 0) && (
                    <div className={HomeClasses.emptyaccounts}>
                      <p>No Commissions available</p>
                    </div>
                  )}
                </div>
              )}


              {/* Advance payments */}
              {
                ((status == 7 || status == 15) && accountType == 1 &&
                  orderdetails?.pendingCommission !== null && orderdetails.pendingCommission !== 0) ?
                  <div className={HomeClasses["commissions-pending"]}>
                    <h3 id={HomeClasses["order_paments_header"]}>Pending Advance</h3>
                    <p className={HomeClasses["payements-pending"]}>₹{Number(orderdetails?.pendingAdvance).toFixed(2)}</p>
                  </div> : ""}

              {((status == 7 || status == 15) && accountType == 1) ? (
                <div className={HomeClasses["order_d_payments"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Advance Payments</h3>
                  <div className={HomeClasses["payment_headers"]}>
                    <p id={HomeClasses["header_payments"]}>Payment Date</p>
                    <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                  </div>
                  {orderdetails?.advancePayments?.length > 0 ? (
                    orderdetails?.advancePayments?.map((payment, index) => {
                      return (
                        <div
                          className={HomeClasses["order_payment_lists"]}
                          key={index}
                        >
                          <div className={HomeClasses["payment_data"]}>
                            <div className={HomeClasses["payment_item"]}>
                              <h4>
                                {payment?.name}
                              </h4>
                              <p className={HomeClasses["orderpay"]}>Type : <span>{payment?.paymentType}</span></p>
                              {
                                (payment?.transactionNumber !== "NA" && payment?.transactionNumber !== null) &&
                                <p className={HomeClasses["orderpay"]}>Transaction No : <span>{payment?.transactionNumber}</span></p>
                              }
                            </div>
                            <p id={HomeClasses["payment_item"]}>
                              <Moment
                                format="DD MMM, YYYY, hh:mm A"
                                subtract={{ hours: 5, minutes: 30 }}
                              >
                                {new Date(payment?.paymentDate)}
                              </Moment>
                              ₹{Number(payment?.receivedPayement).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })) : (
                    <p style={{ textAlign: "center" }}>No Advance Payemnts </p>
                  )}
                </div>
              ) : ""}

              {viewFlag &&
                <div className={HomeClasses["order_update_container"]}>
                  {orderdetails.suplierOrdersStatus === 6 ? (
                    <div className={HomeClasses["order_update_s"]}>
                      <h1 id={HomeClasses["order_Status"]}>Reason</h1>
                      <p id={HomeClasses["order_update_status"]}>
                        {orderdetails.rejectReason}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className={HomeClasses["order_update_s"]}>
                        <h1 id={HomeClasses["order_Status"]}>Updated Status</h1>
                        <p id={HomeClasses["order_update_status"]}>
                          <p>
                            {orderdetails.suplierOrdersStatus === 1
                              ? "New"
                              : orderdetails.suplierOrdersStatus === 2
                                ? "Approved"
                                : orderdetails.suplierOrdersStatus === 3
                                  ? "Invoice Printed"
                                  : orderdetails.suplierOrdersStatus === 4
                                    ? "Transport"
                                    : orderdetails.suplierOrdersStatus === 5
                                      ? "Delivered"
                                      : orderdetails.suplierOrdersStatus === 6
                                        ? "Rejected"
                                        : orderdetails.suplierOrdersStatus === 8
                                          ? "Quality Difference"
                                          : "Completed"}
                          </p>
                        </p>
                      </div>
                    </>
                  )}
                </div>}
              {!viewFlag &&
                <div className={HomeClasses["order_update_container"]}>
                  {orderdetails.suplierOrdersStatus === 6 ? (
                    <div className={HomeClasses["order_update_s"]}>
                      <h1 id={HomeClasses["order_Status"]}>Reason</h1>
                      <p id={HomeClasses["order_update_status"]}>
                        {orderdetails.rejectReason}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className={HomeClasses["order_update_s"]}>
                        <h1 id={HomeClasses["order_Status"]}>Updated Status</h1>
                        <p id={HomeClasses["order_update_status"]}>
                          {(orderdetails.suplierOrdersStatus === 8 || orderdetails.suplierOrdersStatus === 7) ? (
                            <p>
                              {orderdetails.suplierOrdersStatus === 1
                                ? "New"
                                : orderdetails.suplierOrdersStatus === 2
                                  ? "Approved"
                                  : orderdetails.suplierOrdersStatus === 3
                                    ? "Invoice Printed"
                                    : orderdetails.suplierOrdersStatus === 4
                                      ? "Transport"
                                      : orderdetails.suplierOrdersStatus === 5
                                        ? "Delivered"
                                        : orderdetails.suplierOrdersStatus === 6
                                          ? "Rejected"
                                          : orderdetails.suplierOrdersStatus === 8
                                            ? "Quality Difference"
                                            : "Completed"}
                            </p>
                          ) : (
                            <select
                              onChange={UpdateStatus}
                              value={orderdetails.suplierOrdersStatus}
                              disabled={
                                orderdetails.suplierOrdersStatus === 1 ||
                                orderdetails.suplierOrdersStatus === 5
                              }
                            >
                              <option
                                value={1}
                                disabled={orderdetails.suplierOrdersStatus > 1}
                              >
                                All new Orders
                              </option>
                              <option
                                value={2}
                              // disabled={orderdetails.suplierOrdersStatus > 2}
                              >
                                Approved
                              </option>
                              <option
                                value={3}
                              // disabled={orderdetails.suplierOrdersStatus > 3}
                              >
                                Invoice Printed
                              </option>
                              <option
                                value={4}
                              // disabled={orderdetails.suplierOrdersStatus > 4}
                              >
                                Transport
                              </option>
                              <option
                                value={5}
                                disabled={orderdetails.suplierOrdersStatus > 5}
                              >
                                Delivered
                              </option>
                            </select>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>}

              {(orderdetails.suplierOrdersStatus === 1 && !viewFlag) && (
                <div className={HomeClasses["order_box_container"]}>
                  <div className={HomeClasses["order_s_box"]}>
                    <div className={HomeClasses["order_image_container"]}>
                      <img
                        src={dropBox}
                        alt="image"
                        className={HomeClasses["order_image"]}
                      />
                    </div>
                    <p id={HomeClasses["orders_status"]}>
                      <h5 id={HomeClasses["order_d_header"]}>
                        {orderdetails.suplierOrdersStatus === 1
                          ? " New order"
                          : orderdetails.suplierOrdersStatus === 2
                            ? "Order Approved"
                            : orderdetails.suplierOrdersStatus === 3
                              ? "Order Invoice Printed"
                              : orderdetails.suplierOrdersStatus === 4
                                ? "Order Transport"
                                : orderdetails.suplierOrdersStatus === 5
                                  ? "Order Delivered"
                                  : orderdetails.suplierOrdersStatus === 6
                                    ? "Order Rejected"
                                    : orderdetails.suplierOrdersStatus === 8
                                      ? "Order Quality Difference"
                                      : "Order Completed"}
                      </h5>
                      <span id={HomeClasses["order_d_date"]}>
                        <Moment
                          format="DD MMM, YYYY, hh:mm A"
                          subtract={{ hours: 5, minutes: 30 }}
                        >
                          {new Date(orderdetails.createdAt)}
                        </Moment>
                      </span>
                    </p>
                  </div>
                  <div className={HomeClasses["order_d_btns"]}>
                    {orderdetails.suplierOrdersStatus === 1 && (
                      <>
                        {accountType == 6 && Permission == 0 ? (
                          " "
                        ) : (
                          <>
                            <button
                              type="button"
                              className={HomeClasses["order_approve"]}
                              onClick={() => Orderapprove(2, null)}
                            >
                              Approve
                            </button>
                            <button
                              type="button"
                              className={HomeClasses["order_reject"]}
                              onClick={() => openRejectModel()}
                            >
                              Reject
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* While through dashBord */}
          </div>
        )
        }

        {
          status == 8 && (
            <div className={HomeClasses.transportData}>
              <div className={HomeClasses["transport_header"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Quality Difference Products</h2>
              </div>

              <div className={HomeClasses["QD_products"]}>
                {
                  orderdetails?.Effectedproducts?.length > 0 ?
                    orderdetails?.Effectedproducts.map((item, index) => (
                      <div key={index} className={HomeClasses["EffectedProducts"]}>
                        {/* Product Information */}
                        <div className={HomeClasses["order_prod_info"]}>
                          <h5 id={HomeClasses["order_prod_name"]}>{item.productName}</h5>
                          <p className={HomeClasses["order_prod_Qty"]}>
                            {item.productCategory} . {item.productSubCategory}
                          </p>

                          <div className={HomeClasses["order_prod_price"]}>
                            <p className={HomeClasses["order_prod_Qty"]}>Qty: {item.qty}</p>
                            <p className={HomeClasses["order_prod_Qty"]}>Effected Qty: {item.effectedQuantity}</p>
                            <p className={HomeClasses["order_prod_Qty"]}>
                              Effected Price: <span>₹ {item.effectedProductPrice}</span>
                            </p>
                          </div>
                        </div>

                        {/* Product Weight Information */}
                        {
                          (item.productPriceUnit === "Quintal" || item.productPriceUnit === "Bag") &&
                          <div className={HomeClasses["order_prod_price"]}>
                            <p className={HomeClasses["order_prod_Qty"]}>Total Quintal: {item.weightInQuintal}</p>
                            <p className={HomeClasses["order_prod_Qty"]}>Effected Quintal: {item.effectedWeightInQuintal}</p>
                          </div>
                        }
                      </div>
                    )) :
                    <p className={HomeClasses["no_products"]}>No products found</p>}
              </div>

              {/* Quality Difference Notes */}
              <div className={HomeClasses["orderHeader_wrappe"]}>
                <p className={HomeClasses.QDNotes}>Quality Difference Notes</p>
                <p className={HomeClasses.ordernote}>{orderdetails.qualityDifferenceNotes}</p>
              </div>
            </div>
          )
        }

        {
          (!viewFlag &&
            orderdetails?.qualityDifferencePrice === 0 && orderdetails.agentQD === 1) ?
            <div className={HomeClasses["QD_FinalMain_rwapper"]}>
              <div className={HomeClasses["Sub-wrapper"]}>
                <p className={HomeClasses.QDNotes}>Total QD Price</p>
                <div className={HomeClasses.FinalQD_price_wrap}>
                  <p id={HomeClasses["order_price_rupee"]}>₹{Number(finalPrice).toFixed(2)}</p>
                  <button type="button" className={HomeClasses["downbt"]} onClick={() => { setAddTotalPrice(true) }}>Edit Price</button>
                </div>
              </div>
              <div className={HomeClasses["FinalQd-Wrapper"]}>
                <button type="button" onClick={() => AddQDPrice()}>Approve Order</button>
              </div>
            </div> : ""
        }

        {
          (orderdetails?.qualityDifferencePrice !== 0 && orderdetails?.qualityDifferencePrice !== null) ? (
            <div className={HomeClasses["order_total"]}>
              <p id={HomeClasses["order_text_"]}>Final Price After QD</p>
              <p className={HomeClasses["order_discount"]}>
                <p id={HomeClasses["order_price_rupee"]}>
                  ₹{Number(orderdetails?.qualityDifferencePrice).toFixed(2)}
                </p>
              </p>
            </div>
          ) : ""
        }
        <div className={HomeClasses["order_remainder"]}>
          {((orderdetails.suplierOrdersStatus === 3 && orderdetails.invoice !== "NA") ||
            (orderdetails.suplierOrdersStatus === 5 && orderdetails.invoice !== "NA")) &&
            !viewFlag && (
              <button
                type="button"
                className={HomeClasses["order_remainder_btn"]}
                onClick={() => InvoiceBill()}
              >
                <LuDownload />
                Order Information
              </button>
            )}
        </div>
      </div >

      {/* payment model */}
      {
        paymentModel &&
        <OrderPayment
          isOpen={paymentModel}
          onClose={() => setPaymentModel(false)}
          orderId={orderId}
          orderDetails={orderdetails}
          orderdetails={OrderDetails}
          status={status}
        />
      }
      {/* Add Final Qd Price  Modal */}
      {
        addTotalPrice &&
        <AddFinalQDPrice
          isOpen={addTotalPrice}
          setIsOpen={setAddTotalPrice}
          onClose={() => setAddTotalPrice(false)}
          orderId={orderId}
          orderdetails={orderdetails}
          OrderDetails={OrderDetails}
          finalPrice={finalPrice}
          setFinalPrice={setFinalPrice}
        />
      }

      {/* AddTransport Modal */}
      {
        isOpen && (
          <AddTransportModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={() => closeModal()}
            edit={false}
            totalQuintalWeight={orderdetails.totalWeightInQuintal}
            orderId={orderId}
            OrderDetails={OrderDetails}
          />
        )
      }
      {/* Transport update */}
      {
        updateTransport &&
        <UpdateTransports
          isOpen={updateTransport}
          setIsOpen={setUpdateTransport}
          onClose={() => setUpdateTransport(false)}
          orderId={orderId}
          orderDetails={OrderDetails}
          transportDetails={orderdetails.transportDetails}
        />
      }

      {/*Loading date update  */}
      {
        editloading &&
        <LoadingDateChange
          isOpen={editloading}
          setIsOpen={setEditLoading}
          onClose={() => setEditLoading(false)}
          orderId={orderId}
          orderDetails={OrderDetails}
          loadingDate={orderdetails.loadingDate} />
      }

      {/* Reject Modal */}
      {
        showRejectModel && (
          <div className={HomeClasses["reject_modal"]}>
            <div className={HomeClasses["reject_modal_content"]}>
              <h2 id={HomeClasses["resct_header"]}>Reject</h2>
              <p id={HomeClasses["reject_text"]}>
                Are you sure you want reject Order {orderId}
              </p>
              <input
                className={HomeClasses["reject_input_modal"]}
                type="text"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                placeholder="Description"
                autoFocus
              />
              <div className={HomeClasses["reject_model_buttons"]}>
                <button
                  type="button"
                  className={HomeClasses["reject_model_ctn"]}
                  onClick={() => closeRejectModel()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={HomeClasses["reject_model_btn"]}
                  disabled={!rejectReason}
                  onClick={() => {
                    Orderapprove(6, rejectReason);
                    setShowRejectModel(false);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )
      }

      {/* upadte Qty */}
      {showModal && (
        <div className={HomeClasses["reject_modal"]}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(false);
              // setAllProducts(products);

              setAllProducts(lodash.cloneDeep(products));
              console.log(111);
            }}
            className={HomeClasses["overlaye"]}
          ></div>
          <div className={HomeClasses["update_modal_content"]}>
            <div className={HomeClasses["Qty_header"]}>
              <h2 className={HomeClasses["resct_he"]}>Updating Product Quantity</h2>
              <div className={HomeClasses["d_wrapper"]}>
                <button type="button" onClick={() => { setShowModal(false) }}><RxCross2 size={25} /></button>
              </div>
            </div>
            <div className={HomeClasses["qty_model_container"]}>
              {allProducts.map((product, index) => {
                return (
                  <div
                    className={HomeClasses["order_prod_container"]}
                    key={index}
                  >
                    <div className={HomeClasses["order_prod_box"]}>
                      <div className={HomeClasses["order_prog_img_container"]}>
                        {
                          product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                            <img
                              className={HomeClasses["order_prod_image"]}
                              src={product.productImageList[0]}
                              alt="image"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = Image;
                              }}
                            />
                          ) : (
                            <img
                              className={HomeClasses["order_prod_image"]}
                              src={Image}
                              alt="image"
                            />
                          )
                        }
                      </div>
                      <div className={HomeClasses["order_prod_info"]}>
                        <h5 id={HomeClasses["order_prod_name"]}>
                          {product.productName}
                        </h5>
                        <p id={HomeClasses["order_prod_Qty"]}>
                          Qty:{" "}
                          <input
                            className={HomeClasses["qty_input"]}
                            value={product.qty}
                            id={product.productId}
                            type="text"
                            min={1}
                            maxLength={5}
                            onChange={handleQty}
                            placeholder="qty"
                          />
                        </p>
                      </div>
                    </div>
                    <div className={HomeClasses["Quantity_d_wrapper"]}>
                      <div className={HomeClasses["d_wrapper"]}>
                        <div className={HomeClasses["discount_tag"]}>
                          {
                            product && (
                              <div className={HomeClasses["dicount_pricetag"]}>
                                {product?.cashDiscount > 0 ? (
                                  <>
                                    <p className={HomeClasses["Mdicount"]}>{product.discount}%</p>
                                    <p className={HomeClasses["Adicount"]}>{product.cashDiscount}% OFF</p>
                                  </>
                                ) : (
                                  <p className={HomeClasses["Adicount"]}>{product.discount}% OFF</p>
                                )}
                              </div>
                            )
                          }
                        </div>
                        <button
                          type="button" onClick={() => DeleteOrderItem(product?.oderedItemsId)}>Delete
                        </button>
                      </div>
                      <div className={HomeClasses["order_prod_price"]}>
                        {
                          product?.productPriceUnit === "Quintal" ? <>
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                Miller Price :
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.bagPrice).toFixed(2)}/Bag</p>
                              </div>
                            </div>
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                Miller Price :
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.price).toFixed(2)}/Quintal</p>
                              </div>
                            </div>
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                {NameChange()}:
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.bagDiscountPrice).toFixed(2)}/Bag</p>
                              </div>
                            </div>
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                {NameChange()}:
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}/Quintal</p>
                              </div>
                            </div>
                          </> : <> <div className={HomeClasses["order_d_price"]}>
                            <p id={HomeClasses["order_price_container"]}>
                              Miller  Price :
                            </p>
                            <div className={HomeClasses["order_rupee"]}>
                              <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.price).toFixed(2)}</p>
                            </div>
                          </div>
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                {NameChange()}:
                              </p>
                              <div className={HomeClasses["order_rupee"]}>
                                <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}</p>
                              </div>
                            </div>
                          </>
                        }
                        {
                          product?.packingBag !== null && (
                            <div className={HomeClasses["order_d_price"]}>
                              <p id={HomeClasses["order_price_container"]}>
                                {product?.packingBag?.bagName} Price :
                              </p>
                              <p className={HomeClasses["Discountpice"]}>
                                ₹{product?.packingBag?.price}/Quintal
                              </p>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className={HomeClasses["banner_form_btns"]}>
                <button
                  type="submit"
                  className={HomeClasses["banner_e_btn"]}
                  onClick={() => UpdateQty()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* feilds Modal */}
      {dataModal &&
        <FieldsModal
          isOpen={dataModal}
          setIsOpen={setDataModal}
          onClose={() => setDataModal(true)}
          OrderDetails={OrderDetails}
          orderId={orderId}
          setFieldId={setFieldId}
          fieldId={fieldId}
          totalQuintal={orderdetails?.totalWeightInQuintal}
        />}

      {/* Add Discount */}
      {
        discountModal && (
          <div className={HomeClasses["reject_modal"]}>
            <div
              onClick={() => setDiscountModal(false)}
              className={HomeClasses["overlaye"]}
            ></div>
            <div className={HomeClasses["update_modal_Invoice"]}>
              <h2 id={HomeClasses["resct_header"]}>Add Invoice Details</h2>
              <div className={HomeClasses["update_input_model_container"]}>
                <form
                  onSubmit={handleSubmit(finalAmount)}
                  className={HomeClasses["banner_form"]}
                >
                  <div className={HomeClasses["order_total"]}>
                    <p id={HomeClasses["order_text_"]}>Order Price</p>
                    <p className={HomeClasses["order_discount"]}>
                      <p id={HomeClasses["order_price_rupee"]}>
                        <BiRupee />
                        {Number(orderdetails.finalAmount).toFixed(2)}
                      </p>
                    </p>
                  </div>
                  <div className={HomeClasses["form-group"]}>
                    <input
                      type="text"
                      placeholder="Invoice Number"
                      {...register("invoiceNumber", {
                        required: false,
                      })} />
                    {errors.invoiceNumber && (
                      <p className={HomeClasses["errors"]}>
                        {errors.invoiceNumber.message}
                      </p>
                    )}
                  </div>
                  <div className={HomeClasses["form-group"]}>
                    <input
                      type="text"
                      placeholder="Add Invoice price"
                      {...register("finalAmount", {
                        required: 'finalAmount is required!',
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: "Please enter only digits with two decimal only.",
                        },
                        validate: (value) =>
                          parseInt(value) >= 0 ||
                          "Please enter a non-negative value.",
                      })}
                      min={1} />
                    {errors.finalAmount && (
                      <p className={HomeClasses["errors"]}>
                        {errors.finalAmount.message}
                      </p>
                    )}
                  </div>

                  <div className={HomeClasses["banner_form_btns"]}>
                    <button type="submit" className={HomeClasses["banner_e_btn"]}>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      }
    </Layout >
  );
};

export default Orderdetails;
