import React, { useEffect, useRef, useState } from "react";
import styles from "../../Login/Subscription/subscription.module.css";
import Tanyya from "../../../assets/login/tanyyalogin.png";
import Logo from "../../../assets/login/Logotanyya.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import moment from "moment";
import { Permissions } from "../../../components/utils/Pernissions";

const PayNow = () => {
  const [billdetails, setBilldetails] = useState({});
  const Navigate = useNavigate();
  const componentMounted = useRef(true);
  const accountType = localStorage.getItem("AccountType");
  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    getcategories();
    getSubscription();
  }, []);

  const fromDate = moment(billdetails?.fromDate).format("YYYY-MM-DD");
  const toDate = moment(billdetails?.toDate).format("YYYY-MM-DD");

  const getcategories = async () => {
    try {
      const res = await Services.BillDetails("GET", null, token);
      if (res.Status === 1) {
        setBilldetails(res?.BillDetails);
        localStorage.setItem("BillDetails", JSON.stringify(res?.BillDetails));
      }
    } catch (err) {

      console.error(err);
    }
  };

  const getSubscription = async () => {
    const accountType = localStorage.getItem("AccountType");
    Services.SubscriptionStatus("GET", null, token).then((res) => {

      if (res.Status === 1) {
        if (res.paymentStatus === 1) {
          Navigate("/paymentsuccess");
        } else {
          Navigate("/paynow");
        }
      }
      localStorage.setItem("paymentStatus", res?.paymentStatus);
      // console.log(res?.paymentStatus);
    }).catch((err) => {
      console.error(err);
    })
  };

  const calculatedBill = billdetails?.extraUsers * billdetails?.pricePerExtraUser;

  //   Add payment method

  useEffect(() => {
    const id = document.getElementById("razorpayscript");
    console.log(id);
    if (!id) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.id = "razorpayscript";
      document.body.appendChild(script);
    }

    // fetch("https://api.ipify.org/?format=json")
    //   .then((Response) => {
    //     return Response.json();
    //   })
    //   .then((response) => {
    //     if (componentMounted.current) {
    //       setUserIpAddress(response.ip);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    grant_type: process.env.REACT_APP_GRANT_TYPE,
    mode: "test",
    amount: 5000, //  = INR 1
    name: "Traders Ground",
    description: "Subscription",
    order_id: "",
    prefill: {
      contact: "9999999999",
      email: "testing@11.com",
    },
    hidden: {
      contact: true,
      email: true,
    },
    readonly: {
      contact: true,
      email: true,
    },

    // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
    handler: function (response) {
      console.log(response, "222");
      getSubscription();
      // alert(response.razorpay_payment_id);
    },

    // prefill: {
    //     name: 'Gaurav',
    //     contact: '9999999999',
    //     email: 'demo@demo.com'
    // },
    notes: {
      address: "some address",
    },

    theme: {
      color: "#6865F0",
      hide_topbar: false,
    },
  };

  const AddPayment = async () => {
    let body = {
      totalAmount: billdetails.totalAmount,
    };

    Services.AddPaymentMode(
      "POST",
      JSON.stringify(body),
      token
    ).then((res) => {
      if (res.Status === 1) {
        options.order_id = res?.razorpayOrderId;
        options.amount = Math.floor(billdetails?.totalAmount);
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    }).catch((err) => {
      console.error(err);
    })
  };
  const totalAmount = Math.floor(billdetails?.totalAmount);
  const back = () => {
    Navigate(Permissions(accountType)[0].menuLink);
  }
  return (
    <div className={styles["login"]}>
      <div className={styles["model_image"]}>
        <img
          className={styles["Tanyya_image"]}
          src={Tanyya}
          alt="Tanyya image"
        />
        <div className={styles["hero_mask"]}>
          <div className={styles["model_content"]}>
            <h1 className={styles["div-row"]}>Create Your Profile</h1>
            <p className={styles["div-col"]}>
              Traders Ground is a B2B platform owned by Tanyya Tech Solutions
              LLP. The platform is designed to simplify the entire process for
              manufacturers and distributors.
            </p>
          </div>
        </div>
      </div>
      <div className={styles["login_maindiv"]}>
        <img className={styles["logoText"]} src={Logo} alt="Logo Text" />
        {
          Object.keys(billdetails).length > 0 ?
            <div className={styles["login_box_div"]}>
              <div className={styles["login_text_container"]}>
                <h4 className={styles["conform_ammount"]}>
                  ₹{billdetails?.totalAmount ? billdetails?.totalAmount : 0}
                </h4>
                <p className={styles["confirm_todate"]}>Due Date :{toDate}</p>
              </div>
              <div className={styles["bill_container"]}>
                <div className={styles["bill_cycle"]}>
                  <p className={styles["bill_cycle_header"]}>Bill Cycle</p>
                  <p className={styles["bill_cycle_text"]}>
                    {fromDate}
                    {" - "}
                    {toDate}
                  </p>
                </div>
                <div className={styles["bill_cycle"]}>
                  <p className={styles["bill_cycle_header"]}>Bill Generated Date</p>
                  <p className={styles["bill_cycle_text"]}>{toDate}</p>
                </div>
                <div className={styles["bill_cycle"]}>
                  <p className={styles["bill_cycle_header"]}>Total Users</p>
                  <p className={styles["bill_cycle_text"]}>
                    {billdetails?.totalUsers ? billdetails?.totalUsers : 0}
                  </p>
                </div>
                <div className={styles["bill_cycle"]}>
                  <p className={styles["bill_cycle_header"]}>
                    {" . "}Base Users({billdetails?.baseUsers ? billdetails?.baseUsers : 0})
                  </p>
                  <p className={styles["bill_cycle_text"]}>
                    ₹{billdetails?.basePrice ? billdetails?.basePrice : 0}
                  </p>
                </div>
                <div className={styles["bill_cycle"]}>
                  <p className={styles["bill_cycle_header"]}>
                    {" . "}Add on Users({billdetails?.extraUsers ? billdetails?.extraUsers : 0}
                    {" x "}₹{billdetails?.pricePerExtraUser ? billdetails?.pricePerExtraUser : 0})
                  </p>
                  <p className={styles["bill_cycle_text"]}>{calculatedBill ? calculatedBill : 0}</p>
                </div>
              </div>
              <div className={styles["total_bill"]}>
                <p className={styles["total_bill_header"]}>Total</p>
                <p className={styles["total_bill_text"]}>
                  ₹{billdetails?.totalAmount ? billdetails.totalAmount : 0}
                </p>
              </div>
              <div className={styles["confirm_button"]}>
                <button
                  className={styles["sub_ctn"]}
                  type="button"
                  onClick={() => back()}
                >
                  Ask me,Later
                </button>
                <button
                  className={styles["sub_btn"]}
                  type="button"
                  onClick={() => AddPayment()}
                >
                  Pay Now
                </button>
              </div>
            </div> : <div className={styles["empty-wrapper"]}>
              <div className={styles["emptytext"]}>
                <p>Error Subscription Details</p>
              </div>
              <button
                className={styles["sub_ctn"]}
                type="button"
                onClick={() => back()}
              >
                Ask me,Later
              </button></div>}

      </div>
    </div>
  );
};

export default PayNow;
