import React, { useEffect, useState } from "react";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import Layout from "../../components/Layout/layout";
import { GoArrowLeft } from "react-icons/go";
import HomeClasses from "../../pages/Home/home.module.css";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { BsFillHandbagFill } from "react-icons/bs";
import { MdEditDocument } from "react-icons/md";
import { FaBoxes, FaInfoCircle } from "react-icons/fa";
import { BiSolidBox } from "react-icons/bi";
import { LuUpload } from "react-icons/lu";
import { BsBox2Fill } from "react-icons/bs";
import config from "./config";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import { IoIosPricetags } from 'react-icons/io'
import { GiWeight } from "react-icons/gi";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { RxCross2 } from "react-icons/rx";
import { ScaleLoader } from "react-spinners";

window.Buffer = window.Buffer || require("buffer").Buffer;

const AddProduct = () => {
  const [imageUrl, setImageUrl] = useState([]);
  const [images, setImages] = useState([]);
  const [buttonloading, setButtonloading] = useState(false);
  const Navigate = useNavigate();
  const [productCategories, setProductCategories] = useState([]);
  const [priceUnits, setPriceUnits] = useState([]);
  const types = ["PNG", "JPG", "JPEG"];
  const [selectedPriceUnit, setSelectedPriceUnit] = useState("");
  const [packingBags, setPackingBags] = useState([]);
  const [productsubCategories, setProductsubCategories] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    register,
    watch,
    reset,
  } = useForm();
  // { defaultValues: { packageMeasuringUnit: 'KG', productMeasuringUnit: 'Bag' } }
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const productcategoryId = watch("productCategory");

  useEffect(() => {
    getPriceUnits();
    getProductCategories();
    getPackingBags();
  }, []);
  // console.log('selectedId', selectedId);
  const getPriceUnits = async () => {
    try {
      const res = await Services.priceUnits("GET", null, token);
      if (res.Status === 1) {
        setPriceUnits(res.productPriceUnits);

      }
    } catch (err) {
      // alert("Something went wrong please try again later");
      console.error(err);
    }
  }

  const getProductCategories = async () => {
    try {
      const res = await Services.productCategories("GET", null, token);
      if (res.Status === 1) {
        setProductCategories(res.productCategories);
      }
    } catch (err) {
      console.error(err);
    }
  }



  useEffect(() => {
    const temp = [...productCategories]
    const CategoryId = temp.filter(item => item.categoryName === productcategoryId)[0]?.productCategoryId
    // console.log('CategoryId', CategoryId);
    setSelectedId(CategoryId);
    if (CategoryId) {
      Services.ProductSubCategories("GET", null, token, CategoryId).then((res) => {
        if (res.Status === 1) {
          setProductsubCategories(res.SubCategories);
        } else if (res.Status === 0) {
          warningSwal("Warning", res.Message);
        };
      }).catch((err) => {
        console.error(err);
      })
    }
  }, [productcategoryId]);

  const getPackingBags = async () => {
    try {
      const res = await Services.PackingBags("GET", null, token);
      if (res.Status === 1) {
        const packing = res.packingBags.map(bag => ({
          ...bag,
          price: 0,
        }))

        setPackingBags(packing);
        console.log("packing", packing);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // console.log('', packingBags);
  const formData = watch();
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 1
  );

  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;
  const onSubmit = async (data) => {

    let body = {
      ...data,
      productImageList: imageUrl,
      productCategoryId: selectedId,
      productMeasuringUnit: String(data.productPriceUnit).toLowerCase() == 'quintal' ? 'Bag' : data.productPriceUnit
    };

    if (imageUrl.length === 0) {
      warningSwal("Warning", "Image is required");
      return;
    }
    if (data.stockQty <= 0) {
      setError("stockQty", {
        type: "custom",
        message: "Stock quantity cannot be zero or negative",
      });
      return;
    }

    try {
      const res = await Services.AddProduct(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setButtonloading(false);
        reset(data);
        Navigate(-1);
      }
    } catch (err) {
      // alert("something went wrong please try again");
      console.log(err);
    }
  };

  const fileUploadHandler = (files) => {

    let uploadedImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = file.type;

      if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
        const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
        // console.log(newFileName);
        const newFile = new File([file], newFileName);
        // console.log(newFile);
        S3FileUpload.uploadFile(newFile, config)
          .then((data) => {
            // console.log(data.location);
            uploadedImages.push(data.location);
            setImages([...images, data.location]);
            setImageUrl([...imageUrl, newFileName]);
          })
          .catch((err) => console.error(err));
      }
    };
  }
  const handleDeleteImage = (url) => {
    setImages(prev => prev.filter(image => image !== url));
    setImageUrl(prev => prev.filter(name => name !== url.split('/').pop()));
  };

  const fileUploader = (
    <div className={HomeClasses["add_image_uploader"]}>
      <LuUpload className={HomeClasses["upload_icon"]} />
      <h3 id={HomeClasses["add_uploader_h3"]}>Add Image Here</h3>
    </div>
  );

  return (
    <Layout Active={"Manage products"}>
      <div className={HomeClasses["home_wrapper"]}>
        <div className={HomeClasses["Add_main_container"]}>
          <div className={HomeClasses["Add_header"]}>
            <button
              className={HomeClasses["add_back_btn"]}
              type="button"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={HomeClasses["add_header_h2"]}>Add Product</h1>
          </div>

          <div className={HomeClasses["Addproduct_container"]}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={HomeClasses["add_register"]}
            >
              <div>
                <Controller
                  name="productCategory"
                  control={control}
                  rules={{
                    required: "Product category is required",
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field}>
                        <option value="" disabled>
                          Product Category
                        </option>
                        {
                          productCategories.map((item, index) => (
                            <option
                              key={item.productCategoryId}
                              value={item.categoryName}
                            >
                              {item.categoryName}
                            </option>
                          ))
                        }

                      </select>
                      <BsBox2Fill />
                    </div>
                  )}
                />
                {errors.productCategory && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
              {productsubCategories?.length > 0 &&
                <div>
                  <Controller
                    name="productSubCategory"
                    control={control}
                    rules={{
                      required: "Product SubCategory",
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <select defaultValue="" {...field}>
                          <option value="" disabled>
                            Product SubCategory
                          </option>
                          {
                            productsubCategories.map((item, index) => (
                              <option
                                key={item.productSubCategoryId}
                                value={item.subCategoryName}
                              >
                                {item.subCategoryName}
                              </option>
                            ))
                          }

                        </select>
                        <BsBox2Fill />
                      </div>
                    )}
                  />
                  {errors.productSubCategory && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productSubCategory.message}
                    </p>
                  )}
                </div>
              }


              <div>
                <Controller
                  name="productName"
                  control={control}
                  rules={{
                    required: "Product Name is required",
                    // pattern: {
                    //   value: /^[a-zA-Z0-9\s]+$/,
                    //   message: "Invalid Product Name",
                    // },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input {...field} placeholder="Product Name*" />
                      <BsFillHandbagFill />
                    </div>
                  )}
                />
                {errors.productName && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productName.message}
                  </p>
                )}
              </div>

              <div>
                <Controller
                  name="productPriceUnit"
                  control={control}
                  rules={{
                    required: "Product PriceUnit is required",

                    onChange: (e) => {
                      // console.log(String(e.target.value).toLowerCase() == 'quintal' ? 'Bag' : e.target.value)
                      setValue("productMeasuringUnit", String(e.target.value).toLowerCase() == 'quintal' ? 'Bag' : e.target.value);
                      setSelectedPriceUnit(e.target.value);
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field}>
                        <option value="" disabled>
                          Product PriceUnit
                        </option>
                        {
                          priceUnits.map((unit, index) => (
                            <option
                              key={unit.productPriceId}
                              value={unit.productPriceUnit}
                            >
                              {unit.productPriceUnit}
                            </option>
                          ))
                        }
                      </select>
                      <BsBox2Fill />
                    </div>
                  )}
                />
                {errors.productPriceUnit && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productPriceUnit.message}
                  </p>
                )}
              </div>

              <div>
                <Controller
                  name="stockQty"
                  control={control}
                  rules={{
                    required: "Stock Qty is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Stock Qty should only contain digits",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        type="text"
                        {...field}
                        placeholder="Stock Quantity*"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                      <FaBoxes />
                      <div className={HomeClasses["a_mesuring_unit"]}>
                        <Controller
                          name="productMeasuringUnit"
                          control={control}
                          disabled

                          //   defaultValue={formData.productPriceUnit}
                          rules={{
                            required: "Product MeasuringUnit is required",
                          }}
                          render={({ field }) => (
                            <div className={HomeClasses["add_icon"]}>
                              <select defaultValue="" {...field} >
                                <option value="" disabled></option>
                                {
                                  priceUnits.map((unit, index) => (
                                    <option
                                      key={unit.productPriceId}
                                      value={unit.productPriceUnit}
                                    >
                                      {unit.productPriceUnit}
                                    </option>
                                  ))
                                }
                              </select>
                              {/* <BsBox2Fill /> */}
                            </div>
                          )}
                        />
                        {errors.productMeasuringUnit && (
                          <p className={HomeClasses["errors"]}>
                            {errors.productMeasuringUnit.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                {errors.stockQty && (
                  <p className={HomeClasses["errors"]}>
                    {errors.stockQty.message}
                  </p>
                )}
              </div>

              <div>
                <Controller
                  name="productBagWeightInKgs"
                  control={control}
                  rules={{
                    required: "Product Bag WeightInKgs required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Product BagWeight In Kgs should be greater than 0",
                    },

                  }}

                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        type="number"
                        {...field}
                        placeholder="Product BagWeight In Kgs"
                        min={1}
                        onChange={(e) => field.onChange(Number(e.target.value))} />
                      <GiWeight />
                    </div>
                  )}
                />
                {errors.productBagWeightInKgs && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productBagWeightInKgs.message}
                  </p>
                )}
              </div>
              {
                selectedPriceUnit === "Quintal" && (
                  <>
                    {packingBags.map((item, index) => (
                      <div key={item.bagId}>
                        <div className={HomeClasses["info-tag"]}>
                          <span className={HomeClasses["info-icon"]}><FaInfoCircle size={15} /></span>
                          <p>{item.bagId === 1 && "Buyer Bags Reduces your Product Cost."}</p>
                          <p>{item.bagId === 2 && "Miller Bags - Non Metallic Increases your Product Cost."}</p>
                          <p> {item.bagId === 3 && "Miller Bags - Metallic Increases your Product Cost."}</p>
                        </div>
                        <div className={HomeClasses["add_icon"]}>
                          <input
                            type="number"
                            step="0.01"
                            placeholder={`${item.bagName}`}
                            {...register(`packingBagPrice.${index}.price`, {
                              required: item.bagId !== 2 ? `${item.bagName}Price is required` : false,
                              validate: item.bagId !== 2
                                ? (value) => {
                                  if (value === null || value <= 0) {
                                    return "Price should be greater than 0";
                                  }
                                  return true;
                                }
                                : "",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message: "Price should only contain digits",
                              },
                              valueAsNumber: true
                            })}
                          />
                          <IoIosPricetags />
                        </div>
                        <input
                          type="hidden"
                          defaultValue={item.bagId}
                          {...register(`packingBagPrice.${index}.bagId`, { valueAsNumber: true })}
                        />
                        <input
                          type="hidden"
                          defaultValue={item.bagName}
                          {...register(`packingBagPrice.${index}.bagName`)}
                        />
                        {item.bagId !== 2 && errors.packingBagPrice?.[index]?.price && (
                          <p className={HomeClasses["errors"]}>
                            {errors.packingBagPrice[index].price.message}
                          </p>
                        )}
                      </div>
                    ))}
                  </>
                )
              }

              <div>
                <Controller
                  name="discount"
                  control={control}
                  rules={{
                    required: "Discount is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: " Discount should only contain digits",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input {...field}
                        onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                        value={field.value || ''}
                        placeholder="Discount In Percentage"
                      />
                      <HiMiniReceiptPercent />
                    </div>
                  )}
                />
                {errors.discount && (
                  <p className={HomeClasses["errors"]}>{errors.discount.message}</p>
                )}
              </div>
              <div>
                <Controller
                  name="HSNCode"
                  control={control}
                  rules={{
                    required: "HSNCode is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Invalid HSNCode ",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        {...field}
                        placeholder="HSNCode "
                      // maxLength={8}
                      // minLength={8} 
                      />
                      <MdEditDocument />
                    </div>
                  )}
                />
                {errors.HSNCode && (
                  <p className={HomeClasses["errors"]}>
                    {errors.HSNCode.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="isActive"
                  control={control}
                  rules={{
                    required: "Status is required"
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field}
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value={1}>Active</option>
                        <option value={2}>In Active</option>
                      </select>
                      <BiSolidBox />
                    </div>
                  )}
                />
                {errors.isActive && (
                  <p className={HomeClasses["errors"]}>
                    {errors.isActive.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="productImageList"
                  control={control}
                  // rules={{ required: "Image is required" }}
                  render={({ field }) => (
                    <div className={HomeClasses["image_preview"]}>

                      <FileUploader
                        handleChange={fileUploadHandler}
                        children={fileUploader}
                        multiple={true}
                        types={types}
                        className={HomeClasses["add_image_uploader"]}
                      />
                      <div className={HomeClasses.image_array}>
                        {images.map((item, index) => (
                          <div key={index} className={HomeClasses.image_container}>
                            <img
                              key={index}
                              src={item}
                              alt="Product Preview"
                              className={HomeClasses["image_uploader"]}
                            />
                            <button type="button" onClick={() => handleDeleteImage(item)} className={HomeClasses['imageremover']}><RxCross2 size={18} /></button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                />
                {errors.productImageList && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productImageList.message}
                  </p>
                )}
              </div>
              {accountType == 6 && PermissionStatus == 0 ? (
                " "
              ) : (
                <button type="submit" className={HomeClasses["add_submit"]} disabled={buttonloading}>
                  {" "}
                  {buttonloading ? (
                    <ScaleLoader
                      color="#ffffff"
                      height={20}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
            </form>
          </div>
        </div>
      </div >
    </Layout >
  );
};

export default AddProduct;
