import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import HomeClasses from "../../pages/Home/home.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { override } from "./helper";
import { Services } from "../../Services";
import { SuccessSwal } from "../../components/utils/ToastMessages";
import { FaBoxes, FaTag, FaTruckMoving, FaUser } from "react-icons/fa";
import { RiCustomerService2Fill, RiLockPasswordFill } from "react-icons/ri";
import { PiBagFill } from "react-icons/pi";
import { useForm, Controller } from "react-hook-form";
import { MdLocationPin } from "react-icons/md";
import { IoIosMail, IoIosCall } from "react-icons/io";
import MySubscription from "./Subscriptionsdata/MySubscription";
import Faqs from "./Faqs/Faqs";
import Privacypolicy from "./Faqs/Privacypolicy";
import Support from "./Faqs/Support";
import Logo from "../../assets/login/Logotanyya.png";
import Termsconditions from "./Faqs/Termsconditions";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { RotatingLines } from "react-loader-spinner";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import config from "./userconfig";
import Moq from "./Faqs/Moq";
import { BiSolidCreditCardFront } from "react-icons/bi";
import useStore from "../../store";
import PosswordReset from "./Faqs/PosswordReset";
import avatar from '../../assets/avatar.png';
import ManageAddress from "./Faqs/ManageAddress";
import TranportDetails from "./TransportDetails/TranportDetails";
window.Buffer = window.Buffer || require("buffer").Buffer;

const UserProfile = () => {
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(true);
  const [userdata, setUserdata] = useState({});
  const { user, setUser, userActive, setUserActive } = useStore();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [button, setButton] = useState(
    localStorage.getItem("button") || "General Information"
  );
  const [updatedBusinessHours, setUpdatedBusinessHours] = useState([]);
  const [selectedDayId, setSelectedDayId] = useState(null);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [step, setStep] = useState(1);

  let User;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");
  // ticket status

  // user Active button

  useEffect(() => {
    getcategories();
    return () => {
      localStorage.removeItem("button");
    };
  }, [reset]);

  const getcategories = async () => {
    Services.getUser("GET", null, token).then((res) => {
      setLoading(false);
      if (res.Status === 1) {
        setUserdata(res.user);
        setUser(res.user)
        setUpdatedBusinessHours(res.user.businessHours || []);
        setFromTime("");
        setToTime("");
        setPhoto(res.user.Photo);
        setUserActive(res.user.isActive);
        let resetBody = { ...res.user };
        delete resetBody.Photo;
        reset(resetBody);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const handleButtonClick = (buttonText) => {
    setButton(buttonText);
    localStorage.setItem("button", buttonText);
    console.log(buttonText);
  };



  // Edit profile
  const onSubmit = (data) => {
    console.log(data);
    Services.EditProfile("PATCH", JSON.stringify(data), token)
      .then((res) => {
        if (res.Status === 1) {
          SuccessSwal("Success", res.Message);
          setShowUpdateModal(false);
          getcategories();
          // Navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getCurrentTime = () => {
  //   const currentTime = new Date();
  //   const hours = currentTime.getHours();
  //   const minutes = currentTime.getMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   const formattedHours = hours % 12 || 12;
  //   return { hours: formattedHours, minutes, ampm };
  // };

  // useEffect(() => {
  //   const currentTime = getCurrentTime();
  //   setFromTime(
  //     `${currentTime.hours}:${currentTime.minutes} ${currentTime.ampm}`
  //   );
  //   setToTime(
  //     `${currentTime.hours}:${currentTime.minutes} ${currentTime.ampm}`
  //   );
  // }, []);

  const handleCancelClick = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateClick = (dayId) => {
    const defaultBusinessHour = updatedBusinessHours.find(
      (hour) => hour.Id === dayId
    );

    const [fromTime, toTime] = defaultBusinessHour.timings.split("-");
    setFromTime(fromTime.trim());
    setToTime(toTime.trim());
    setSelectedDayId(dayId);
    setShowUpdateModal(true);
  };

  const update = () => {
    let body = {};
    const defaultBusinessHour = updatedBusinessHours.filter(
      (hour) => hour.Id != selectedDayId
    );
    const [selectedBusinessHour] = updatedBusinessHours.filter(
      (hour) => hour.Id === selectedDayId
    );
    let selectedDay = {
      ...selectedBusinessHour,
      timings: `${fromTime}-${toTime}`,
    };
    body = { businessHours: [...defaultBusinessHour, selectedDay] };
    console.log(body);
    onSubmit(body);
    getcategories();
    setShowUpdateModal(false);
  };

  // image uploader

  const fileUploadHandler = (event) => {
    console.log(event);
    // return;
    const type = event.type;

    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
      console.log(newFileName);
      // return;
      const file = new File([event], newFileName);
      console.log(file);
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          setPhoto(data.location);
          console.log(data);
          setValue("Photo", newFileName);
        })
        .catch((err) => console.error(err));
    }
  };

  const fileUploader = (
    <div className={HomeClasses["user_img_container"]}>

      <div className={HomeClasses["user_image_uploader"]}>
        <img
          src={photo}
          className={HomeClasses["uploaded_image"]}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = avatar;
          }}
          alt="tutor"
        />
      </div>
    </div>
  );

  return (
    <Layout Active={"Account"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["product_main_container"]}>
            <div className={HomeClasses["profile_container"]}>
              <div className={HomeClasses["profile_information_container"]}>
                <div className={HomeClasses["profile_info_container"]}>
                  <p className={HomeClasses.profile}>Profile</p>
                </div>
                <div className={HomeClasses["profile_dashbord"]}>
                  <p className={HomeClasses["profile_p_header"]}>General</p>
                  <button
                    type="button"
                    className={`${HomeClasses["profile_btn"]} ${button === "General Information"
                      ? HomeClasses["active_button_type"]
                      : ""
                      }`}
                    // title="General Information"
                    onClick={() => {
                      handleButtonClick("General Information");
                      User = { ...user };
                      delete User.Photo;
                      reset(User);
                    }}
                  >
                    <p className={HomeClasses["icon_container"]}>
                      <FaUser className={HomeClasses["profile_icon"]} />
                    </p>
                    <h4 className={HomeClasses["text_header"]}>
                      General Information
                    </h4>
                  </button>
                  {(accountType == 1 || accountType == 2 || accountType == 5) && (
                    <button
                      type="button"
                      className={`${HomeClasses["profile_btn"]} ${button === "MySubscriptions"
                        ? HomeClasses["active_button_type"]
                        : ""
                        }`}
                      onClick={() => handleButtonClick("MySubscriptions")}
                    >
                      <FaTag className={HomeClasses["profile_icon"]} />
                      <h4 className={HomeClasses["text_header"]}>
                        My Subscriptions
                      </h4>
                    </button>
                  )}

                  <button
                    type="button"
                    className={`${HomeClasses["profile_btn"]} ${button === "Transport Name"
                      ? HomeClasses["active_button_type"]
                      : ""
                      }`}
                    onClick={() => handleButtonClick("Transport Name")}
                  >
                    <FaTruckMoving className={HomeClasses["profile_icon"]}
                    />
                    <h4 className={HomeClasses["text_header"]}>Transport Name</h4>
                  </button>

                  {(accountType == 1 ||
                    accountType == 2) && (
                      <button
                        type="button"
                        className={`${HomeClasses["profile_btn"]} ${button === "Minimum Order value"
                          ? HomeClasses["active_button_type"]
                          : ""
                          }`}
                        onClick={() => handleButtonClick("Minimum Order value")}
                      >
                        <FaBoxes className={HomeClasses["profile_icon"]} />
                        <h4 className={HomeClasses["text_header"]}>Minimum Order value</h4>
                      </button>
                    )}
                  <button
                    type="button"
                    className={`${HomeClasses["profile_btn"]} ${button === "Support"
                      ? HomeClasses["active_button_type"]
                      : ""
                      }`}
                    onClick={() => handleButtonClick("Support")}
                  >
                    <RiCustomerService2Fill
                      className={HomeClasses["profile_icon"]}
                    />
                    <h4 className={HomeClasses["text_header"]}>Support</h4>
                  </button>
                  <button
                    type="button"
                    className={`${HomeClasses["profile_btn"]} ${button === "Reset Password"
                      ? HomeClasses["active_button_type"]
                      : ""
                      }`}
                    onClick={() => handleButtonClick("Reset Password")}
                  >
                    <RiLockPasswordFill
                      className={HomeClasses["profile_icon"]}
                    />
                    <h4 className={HomeClasses["text_header"]}>Change Password</h4>
                  </button>
                </div>
              </div>
              <div className={HomeClasses["profile_update_container"]}>
                {button === "General Information" && (
                  <>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={HomeClasses["edit_profile"]}
                    >
                      <div
                        className={HomeClasses["edit_profile_img_container"]}
                      >
                        <Controller
                          name="Photo"
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <div className={HomeClasses["image_preview"]}>
                              <FileUploader
                                handleChange={fileUploadHandler}
                                className={HomeClasses["user_image_uploade"]}
                                children={fileUploader}
                              />
                            </div>
                          )}
                        />
                        {errors.Photo && (
                          <p className={HomeClasses["errors"]}>
                            {errors.Photo.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: " Name is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s]+$/,
                              message: "Special charectors not allowed",
                            },
                          }}
                          render={({ field }) => (
                            <div className={HomeClasses["edit_icon"]}>
                              <input {...field} placeholder="Enter fullName" />
                              <FaUser />
                            </div>
                          )}
                        />
                        {errors.name && (
                          <p className={HomeClasses["errors"]}>
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                      {(accountType == 6 || accountType == 4) ? (
                        <></>
                      ) : (
                        <div>
                          <Controller
                            name="businessName"
                            control={control}
                            rules={{
                              required: "BusinessName is required",
                              pattern: {
                                value: /^[a-zA-Z0-9\s]+$/,
                                message: "Special charectors not allowed",
                              },
                            }}
                            render={({ field }) => (
                              <div className={HomeClasses["edit_icon"]}>
                                <input {...field} placeholder="Business name" />
                                <PiBagFill />
                              </div>
                            )}
                          />
                          {errors.businessName && (
                            <p className={HomeClasses["errors"]}>
                              {errors.businessName.message}
                            </p>
                          )}
                        </div>
                      )}

                      <div>
                        <Controller
                          name="mobileNumber"
                          control={control}
                          rules={{
                            required: "Mobile number is required",
                            pattern: {
                              value: /^[0-9]{0,10}$/,
                              message:
                                "Mobile number should not exceed 10 digits",
                            },
                          }}
                          render={({ field }) => (
                            <div className={HomeClasses["edit_icon"]}>
                              <input
                                {...field}
                                placeholder="Mobile number"
                                maxLength={10}
                                disabled
                              />
                              <IoIosCall />
                            </div>
                          )}
                        />
                        {errors.mobileNumber && (
                          <p className={HomeClasses["errors"]}>
                            {errors.mobileNumber.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          render={({ field }) => (
                            <div className={HomeClasses["edit_icon"]}>
                              <input {...field} placeholder="Email" />
                              <IoIosMail />
                            </div>
                          )}
                        />
                        {errors.email && (
                          <p className={HomeClasses["errors"]}>
                            {errors.email.message}
                          </p>
                        )}
                      </div>

                      {(accountType == 4 || accountType == 6) ? (" ") : (
                        <div>
                          <Controller
                            name="GSTNumber"
                            control={control}
                            rules={{
                              required: "GST Number is required",
                              // pattern: {
                              //   value:
                              //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                              //   message: "Please enter Valid GST Number",
                              // },
                            }}
                            render={({ field }) => (
                              <div className={HomeClasses["edit_icon"]}>
                                <input
                                  {...field}
                                  placeholder="Enter GSTNumber"
                                />
                                <BiSolidCreditCardFront />
                              </div>
                            )}
                          />
                          {errors.GSTNumber && (
                            <p className={HomeClasses["errors"]}>
                              {errors.GSTNumber.message}
                            </p>
                          )}
                        </div>
                      )}
                      {(accountType == 6 || accountType == 4) ? (" ") : (
                        <div>
                          <Controller
                            name="address"
                            control={control}
                            rules={{ required: "Address is required" }}
                            render={({ field }) => (
                              <div className={HomeClasses["edit_icon"]}>
                                <input
                                  {...field}
                                  placeholder="Address"
                                  disabled
                                />
                                <MdLocationPin />
                              </div>
                            )}
                          />
                          {errors.address && (
                            <p className={HomeClasses["errors"]}>
                              {errors.address.message}
                            </p>
                          )}
                        </div>
                      )}

                      <div className={HomeClasses["edit_btn_container"]}>
                        <button
                          type="submit"
                          className={HomeClasses["edit_profile_submit"]}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </>
                )}

                {button === "MySubscriptions" && (
                  <div className={HomeClasses["mysubscription_container"]}>
                    <MySubscription />
                  </div>
                )}

                {button === "Transport Name" && (
                  <div className={HomeClasses["mysubscription_container"]}>
                    <TranportDetails />
                  </div>
                )}

                {button === "Manage Address" && (
                  <div className={HomeClasses["mysubscription_container"]}>
                    <ManageAddress />
                  </div>
                )}

                {button === "Support" && (
                  <div className={["support_container"]}>
                    <Support />
                  </div>
                )}

                {button === "FAQ" && (
                  <div className={HomeClasses["faqs_container"]}>
                    <Faqs />
                  </div>
                )}

                {
                  button === "Reset Password" && (
                    <div className={HomeClasses["moq_conatiner"]}>
                      <PosswordReset />
                    </div>
                  )
                }


                {button === "Privacy Policy" && (
                  <div className={HomeClasses["faqs_container"]}>
                    <Privacypolicy />
                  </div>
                )}

                {button === "Aboutus" && (
                  <div className={HomeClasses["about_container"]}>
                    <div className={HomeClasses["about_container"]}>
                      <div className={HomeClasses["about_image"]}>
                        <img
                          src={Logo}
                          alt="Traders Ground"
                          className={HomeClasses["about_img"]}
                        />
                      </div>
                      <div className={HomeClasses["about_content"]}>
                        <p className={HomeClasses["about_text"]}>
                          Traders Ground is a B2B platform owned by Tanyya Tech
                          Solutions LLP. The platform is developed to make the
                          entire process simple for manufacturers and
                          distributors.
                        </p>
                        <p className={HomeClasses["about_contact"]}>
                          <li>support@Tanyya.in</li>
                          <li>+91 9704406409</li>
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {button === "Terms of use" && (
                  <div className={HomeClasses["terms_container"]}>
                    <Termsconditions />
                  </div>
                )}

                {button === "Minimum Order value" && (
                  <div className={HomeClasses["moq_conatiner"]}>
                    <Moq />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showUpdateModal && (
        <div className={HomeClasses["delete-modal"]}>
          <div className={HomeClasses["delete-modal-content"]}>
            {updatedBusinessHours.map((businessHour) => (
              <div key={businessHour.Id}></div>
            ))}
            <div className={HomeClasses["time_form_container"]}>
              <p className={HomeClasses["time_p"]}>Update Time</p>
              <div className={HomeClasses["time_form_box"]}>
                <label className={HomeClasses["time_label1"]}>From Time</label>
                <TimePicker
                  label="From Time"
                  hourPlaceholder="HH"
                  minutePlaceholder="MM"
                  value={fromTime}
                  clearIcon
                  className={HomeClasses["picker"]}
                  format="hh:mm a"
                  onChange={(newValue) => setFromTime(newValue)}
                />
              </div>
              <div className={HomeClasses["time_form_box"]}>
                <label className={HomeClasses["time_label2"]}>To Time</label>

                <TimePicker
                  label="To Time"
                  hourPlaceholder="HH"
                  minutePlaceholder="MM"
                  clearIcon
                  format="hh:mm a"
                  className={HomeClasses["picker"]}
                  value={toTime}
                  ampm={true}
                  onChange={(newValue) => setToTime(newValue)}
                />
              </div>
              <div className={HomeClasses["delete_btns"]}>
                <button
                  type="button"
                  className={HomeClasses["delete_dbtn"]}
                  onClick={() => handleCancelClick()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={HomeClasses["delete_cbtn"]}
                  onClick={update}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default UserProfile;
