import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { SuccessSwal, warningSwal } from '../../components/utils/ToastMessages';
import HomeClasses from './home.module.css';
import { Services } from '../../Services';
import { BiRupee } from 'react-icons/bi';
import { ShopServices } from '../Shop/ShopServicess';
const OrderPayment = ({ isPayment, setIsPayment, onClose, orderId, orderdetails, status, OrderDetails }) => {
    const [paymentTypes, setPaymentTypes] = useState([])
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();
    const SelectedType = watch('paymentType');
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const accountType = localStorage.getItem("AccountType");
    const PermissionAccount = localStorage.getItem("permissionAccount");
    const getPaymentTypes = async () => {
        try {
            const res = await Services.PaymentTypes("GET", null, token)
            if (res.Status === 1) {
                setPaymentTypes(res.paymentMethods);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        getPaymentTypes();
    }, [])

    const onSubmit = async (data) => {
        let body = {
            ...data,
            orderId: orderId,
        }

        try {
            let res;
            if (accountType == 5 || (accountType == 6 && PermissionAccount == 5)) {
                body.commissionType = status;
                console.log("first");
                if (status == 15 || status == 7) {
                    body.type = 2;
                    res = await Services.updatePayment("PATCH", JSON.stringify(body), token);
                } else {
                    console.log("Second");
                    res = await ShopServices.AddCommision("POST", JSON.stringify(body), token);
                }
            } else {
                if (status == 15 || status == 7) {
                    console.log("third")
                    body.type = 2;
                    res = await Services.updatePayment("PATCH", JSON.stringify(body), token);
                } else {
                    res = await Services.updatePayment("PATCH", JSON.stringify(body), token);
                }
            }

            if (res.Status === 1) {
                SuccessSwal("Payment success", res.Message);
                onClose();
                orderdetails()
            } else if (res.Status === 0) {
                warningSwal("Warning", res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className={HomeClasses["reject_modal"]}>
            <div className={HomeClasses["payment_modal_order"]}>
                <span
                    className={HomeClasses["close_modal"]}
                    onClick={() => onClose()}
                >
                    &times;
                </span>

                <div className={HomeClasses["Paymentfrom-wrapper"]}>
                    <h2 id={HomeClasses["resct_header"]}>Add Payment</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={HomeClasses["form-group"]}>
                            <input type="number"
                                step="0.001"
                                placeholder='Received Payement'
                                {...register("receivedPayement", {
                                    required: "Received Payement is Required",
                                    valueAsNumber: true
                                })}
                                min={0.001}
                            />
                            <BiRupee className={HomeClasses.inputicon} size={20} />
                            {errors.receivedPayement && <span className={HomeClasses["errors"]}>{errors.receivedPayement.message}</span>}
                        </div>
                        <div className={HomeClasses["form-group"]}>
                            <input type="date"
                                placeholder='YYYY-MM-DD'
                                {...register("paymentDate", { required: " Payment Date is Required" })}
                            />

                            {errors.paymentDate && <span className={HomeClasses["errors"]}>{errors.paymentDate.message}</span>}
                        </div>
                        <div className={HomeClasses["form-group"]}>
                            <select
                                {...register("paymentType", { required: " Payment Type is Required" })}
                            >
                                <option value="" disabled selected>Select Payment Type</option>
                                {
                                    paymentTypes.map((paymentType) => (
                                        <option key={paymentType.paymentMethodId} value={paymentType.paymentMethod}>{paymentType.paymentMethod}</option>
                                    ))
                                }

                            </select>
                            <BiRupee className={HomeClasses.inputicon} size={20} />
                            {errors.paymentType && <span className={HomeClasses["errors"]}>{errors.paymentType.message}</span>}
                        </div>
                        {
                            SelectedType !== "Cash" && (
                                <div className={HomeClasses["form-group"]}>
                                    <input type="text"
                                        placeholder='Transaction Number'
                                        {...register("transactionNumber", {
                                            required: "Transaction Number is Required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9]+$/,
                                                message: "Please enter only alphanumeric characters."
                                            }
                                        })}

                                    />
                                    {errors.transactionNumber && <span className={HomeClasses["errors"]}>{errors.transactionNumber.message}</span>}
                                </div>
                            )
                        }

                        <button
                            type="Submit"
                            className={HomeClasses["Update_P_model_btn"]}
                        >
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OrderPayment;