import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/layout'
import StatusStyles from './dashboard.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GoArrowLeft } from 'react-icons/go'
import { FaUser } from 'react-icons/fa'
import { Services } from '../../Services'
import ReactPaginate from 'react-paginate'
import { IconContext } from 'react-icons'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import emptyorders from '../../assets/emptyorders.png'
import { InfinitySpin } from 'react-loader-spinner'
import { override } from '../Home/helper'

const Subcomponent = () => {
    const { Id } = useParams();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const activeTab = location.state || ""
    const Navigate = useNavigate();
    const [perPage] = useState(8);
    const [page, setPage] = useState(0);
    const [totalCommision, setTotalCommision] = useState(0);
    const [totalAdvance, setTotalAdvance] = useState(0);
    const [totalPendingPayment, setTotalPendingPayment] = useState(0);
    const [isFromDashboard, setIsFromDashboard] = useState(false);

    const accountType = localStorage.getItem("AccountType");
    const selectedTab = JSON.parse(localStorage.getItem("selectedTab"));
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const getUserOrders = async () => {
        setLoading(true);
        try {
            const res = await Services.UserOrdersList("GET", null, token, Id, selectedTab.accountType)
            if (res.Status === 1) {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);

                setOrders(res?.orderDetails);
                setTotalCommision(res?.totalCommission);
                if (res.hasOwnProperty('totalAdvance')) {
                    setTotalAdvance(res.totalAdvance);
                } else {
                    setTotalAdvance(0);
                }
                setTotalPendingPayment(res.totalPendingPayment);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {

        if (location.pathname.includes('dashboard')) {
            setIsFromDashboard(true);
        }
        getUserOrders();
    }, [Id, location.pathname]);

    useEffect(() => {
        getUserOrders();
    }, [Id])

    const orderdetails = (orderId, status) => {

        const viewFlag = isFromDashboard ? "view-only" : "full-access";
        if (accountType == 1 || accountType == 2) {
            Navigate(`/orderdetails/${orderId}/${status}`, { state: { viewFlag } });
        } else {
            Navigate(`/purchaseDetails/${orderId}/${status}`, { state: { viewFlag } });
        }
    };
    console.log(totalCommision, selectedTab, accountType)
    return (
        <Layout Active={"Dashboard"}>
            <div className={StatusStyles.home_wrapper}>
                <div className={StatusStyles["Add_main_container"]}>
                    <div className={StatusStyles["Add_header"]}>
                        <button
                            className={StatusStyles["add_back_btn"]}
                            type="button"
                            onClick={() => {
                                Navigate(-1);
                            }}
                        >
                            <GoArrowLeft />
                        </button>
                        <h1 id={StatusStyles["add_header_h2"]}>Dashboard</h1>
                    </div>
                    <div className={StatusStyles["wrapper"]}>
                        <div className={StatusStyles["container"]}>
                            <h1 id={StatusStyles["add_header_h2"]}>{activeTab}</h1>

                            {loading ? (
                                <div className={StatusStyles["loader"]}>
                                    <InfinitySpin
                                        color="#4285f4"
                                        loading={true}
                                        css={override}
                                        width={200}
                                        height={200}
                                        size={200}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className={StatusStyles["approval_wrapper"]}>
                                        {(totalCommision !== null && totalCommision !== 0) &&
                                            <div className={StatusStyles["approvals"]}>
                                                <div className={StatusStyles["radious_wrapper"]}>
                                                    <FaUser size={20} />
                                                </div>
                                                <div className={StatusStyles["text_container"]}>
                                                    {((accountType == 5 && selectedTab?.accountType === 1) || (accountType == 1 && selectedTab?.accountType === 8)) ? <>
                                                        <p id={StatusStyles["order_d_text"]}>Miller to Broker Commission </p>
                                                        <p id={StatusStyles["order_d_text"]}> ₹{Number(totalCommision).toFixed(2)}</p>
                                                    </> : ((accountType == 5 && selectedTab?.accountType === 2) || (accountType == 2 && selectedTab?.accountType === 8)) ?
                                                        <>
                                                            <p id={StatusStyles["order_d_text"]}>Buyer to Broker Commission </p>
                                                            <p id={StatusStyles["order_d_text"]}> ₹{Number(totalCommision).toFixed(2)}</p>
                                                        </> : ((accountType == 5 && selectedTab?.accountType === 8) || (accountType == 5 && selectedTab?.accountType === 5)) ?
                                                            <>
                                                                <p id={StatusStyles["order_d_text"]}>Sub Broker Commission </p>
                                                                <p id={StatusStyles["order_d_text"]}> ₹{Number(totalCommision).toFixed(2)}</p>
                                                            </> : ((accountType == 5 && selectedTab?.accountType === 9) || (accountType == 1 && selectedTab?.accountType === 9)) ?
                                                                <>
                                                                    <p id={StatusStyles["order_d_text"]}>Transport Commission </p>
                                                                    <p id={StatusStyles["order_d_text"]}> ₹{Number(totalCommision).toFixed(2)}</p>
                                                                </> : ""
                                                    }
                                                </div>
                                            </div>}
                                        {/* Total Advance */}
                                        {
                                            (totalAdvance !== null && totalAdvance !== 0) &&
                                            <div className={StatusStyles["approvals"]}>
                                                <div className={StatusStyles["radious_wrapper"]}>
                                                    <FaUser size={20} />
                                                </div>
                                                <div className={StatusStyles["text_container"]}>
                                                    <p id={StatusStyles["order_d_text"]}>Total Advance Payments </p>
                                                    <p id={StatusStyles["order_d_text"]}> ₹{Number(totalAdvance).toFixed(2)}</p>
                                                </div>
                                            </div>
                                        }
                                        {/* Pending Amount */}
                                        {(totalPendingPayment !== null && totalPendingPayment !== 0) &&
                                            <div className={StatusStyles["approvals"]}>
                                                <div className={StatusStyles["radious_wrapper"]}>
                                                    <FaUser size={20} />
                                                </div>
                                                <div className={StatusStyles["text_container"]}>
                                                    <p id={StatusStyles["order_d_text"]}>Total Pending Payments </p>
                                                    <p id={StatusStyles["order_d_text"]}> ₹{Number(totalPendingPayment).toFixed(2)}</p>
                                                </div>
                                            </div>}

                                    </div>
                                    <div className={StatusStyles["Order_table_container"]}>
                                        <table className={StatusStyles["Order_table"]}>
                                            <thead className={StatusStyles["sticky-header"]}>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Ordered By</th>
                                                    <th>Ordered From</th>
                                                    <th>Delivery Address</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div className={StatusStyles["empty_cell"]}>
                                                                <div
                                                                    className={
                                                                        StatusStyles["empty_image_container"]
                                                                    }
                                                                >
                                                                    <img
                                                                        className={StatusStyles["empty_image"]}
                                                                        src={emptyorders}
                                                                        alt="image"
                                                                    />
                                                                </div>
                                                                <p className={StatusStyles["empty_portal_1"]}>
                                                                    No orders to show, Make your first Order
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    orders
                                                        .slice(perPage * page, perPage * page + perPage)
                                                        .map((item, index) => {
                                                            return (
                                                                <tr
                                                                    key={index}
                                                                    onClick={() => orderdetails(item.orderId, item.suplierOrdersStatus)}
                                                                >
                                                                    <td>{item.orderId}</td>
                                                                    <td>
                                                                        {item.orderedBy.name} (
                                                                        {item.orderedBy.accountType === 1
                                                                            ? "Manufacturer  "
                                                                            : item.orderedBy.accountType === 2
                                                                                ? "Distributor  "
                                                                                : item.orderedBy.accountType === 3
                                                                                    ? "Retailer"
                                                                                    : item.orderedBy.accountType === 4
                                                                                        ? "Marketing Specialist "
                                                                                        : item.orderedBy.accountType === 5
                                                                                            ? "Agent"
                                                                                            : "Office Team"}
                                                                        )
                                                                    </td>
                                                                    <td>{item.userName}</td>
                                                                    <td>{item.deliveredTo.name}{" "}({item.deliveredTo.accountType === 1
                                                                        ? "Manufacturer "
                                                                        : item.deliveredTo.accountType === 2
                                                                            ? "Distributor "
                                                                            : item.deliveredTo.accountType === 3
                                                                                ? "Retailer"
                                                                                : item.deliveredTo.accountType === 4
                                                                                    ? "Marketing Specialist"
                                                                                    : item.deliveredTo.accountType === 5
                                                                                        ? "Agent"
                                                                                        : "Office Staff"})</td>
                                                                    <td>₹{item?.qualityDifferencePrice > 0 ? Number(item.qualityDifferencePrice).toFixed(2) : Number(item?.finalAmount).toFixed(2)}</td>
                                                                </tr>
                                                            );
                                                        })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={StatusStyles["order_pagination"]}>
                                        <ReactPaginate
                                            containerClassName={StatusStyles["pagination"]}
                                            activeClassName={StatusStyles["active-page"]}
                                            pageClassName={StatusStyles["page-item"]}
                                            onPageChange={(event) => setPage(event.selected)}
                                            breakLabel="..."
                                            pageCount={Math.ceil(orders.length / perPage)}
                                            previousLabel={
                                                <IconContext.Provider
                                                    value={{ color: "#B8C1CC", size: "1.8rem" }}
                                                >
                                                    <AiFillLeftCircle />
                                                </IconContext.Provider>
                                            }
                                            nextLabel={
                                                <IconContext.Provider
                                                    value={{ color: "#B8C1CC", size: "1.8rem" }}
                                                >
                                                    <AiFillRightCircle />
                                                </IconContext.Provider>
                                            }
                                        />
                                    </div>
                                </>)}
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Subcomponent