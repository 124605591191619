import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import {
  NavLink,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import HomeClasses from "../../pages/Home/home.module.css";
import Search from "../../assets/homeimgs/search.png";
import { Services } from "../../Services";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import ReactPaginate from "react-paginate";
import { override } from "./helper";
import emptyproducts from "../../assets/emptyproducts.png";
import { RotatingLines } from "react-loader-spinner";
import Image from '../../assets/noplot.png';

const ManageProducts = () => {
  const [params] = useSearchParams();
  const [manageProducts, setManageProducts] = useState([]);
  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [searchItems, setSearchItems] = useState([]);
  const [isNew, setIsNew] = useState();
  const navigate = useNavigate();

  const handleStatusButtonClick = (statusValue) => {
    setStatus(statusValue);
    getcategories(page, statusValue);
  };
  //for permissions
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 1
  );

  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;
  const AddProduct = () => {
    navigate("/ManageProducts/addProduct");
  };

  useEffect(() => {
    if (accountType == 6) {
      getPermissions();
    }
  }, []);

  useEffect(() => {
    if (accountType == 1 || accountType == 2) {
      getSubscriptionStatus();
    }
  }, []);

  const getSubscriptionStatus = async () => {
    console.log(accountType)
    try {
      const res = await Services.SubscriptionStatus("GET", null, token);
      if (res.Status === 1) {
        setIsNew(res.isNew)
        if (res.isNew == 1) {
          return;
        }
        if (res.cancelSubscription === 1) {
          if (res.paymentStatus === 0) {
            navigate("/paynow");
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 0) {
          if (res.isSubscribed === 1) {
            navigate("/Manage");
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 2) {
          if (res.isSubscribed === 1) {
            navigate("/Manage");
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 3) {
          if (res.isSubscribed === 1) {
            navigate("/Manage");
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        }
      }

      localStorage.setItem("paymentStatus", res?.paymentStatus);

    } catch (err) {
      console.error(err);
    }
  };

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getcategories(status, page);
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
    }
  }, [status, page]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchItems]);

  const getcategories = (status, page) => {
    setLoading(true);
    let data = {
      isActive: status
    }
    Services.getManageProducts("POST", JSON.stringify(data), token, page)
      .then((res) => {
        if (res.Status === 1) {
          setLoading(false);
          setManageProducts(res.products);
          setSearchItems(res.products);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isActiveButton = (buttonStatus) => {
    return status === buttonStatus ? HomeClasses.actives : "";
  };

  const searchHandler = (e) => {
    setLoading(false);
    setpageCount(1);
    const searchValue = e.target.value.toLowerCase();
    const search = searchItems.filter((item) =>
      item.productName.toLowerCase().includes(searchValue)
    );
    // console.log(search);

    if (searchValue.length === 0) {
      setManageProducts(searchItems);
    } else {
      setManageProducts(search);
    }
  };

  return (
    <>
      <Layout Active={"Manage products"}>
        <div className={HomeClasses["home_wrapper"]}>


          {
            (isNew == 1 && (accountType == 1 || accountType == 2)) ? (
              <div className={HomeClasses["subscription_container"]}>
                <p className={HomeClasses["denied"]}>Access denied due to invalid subscription,
                  Please activate the plan and access all the features</p>
              </div>) : (
              <div className={HomeClasses["Manageprod_main_container"]}>
                <div className={HomeClasses["manageprod_search_container"]}>
                  <div className={HomeClasses["manageprod_search"]}>
                    <img
                      className={HomeClasses["manage_prod_search"]}
                      src={Search}
                      alt="Search"
                    />
                    <input
                      type={"text"}
                      placeholder="Search using Product Name"
                      onChange={searchHandler}
                    />
                  </div>
                  {accountType == 6 && PermissionStatus == 0 ? (
                    ""
                  ) : (
                    <div className={HomeClasses.addprod_btn}>
                      <button
                        type="button"
                        className={HomeClasses["manageprod_addbtn"]}
                        onClick={() => AddProduct()}
                      >
                        + Add Product
                      </button>
                    </div>
                  )}
                </div>
                <div className={HomeClasses["manage_prodcts_container"]}>
                  <div className={HomeClasses["manageprod_statusbtn"]}>
                    <button
                      className={`${HomeClasses["manageprod_statusbuttons"]
                        } ${isActiveButton(1)}`}
                      onClick={() => handleStatusButtonClick(1)}
                      type="button"
                    >
                      Active
                    </button>
                    <button
                      className={`${HomeClasses["manageprod_statusbuttons"]
                        } ${isActiveButton(2)}`}
                      onClick={() => handleStatusButtonClick(2)}
                      type="button"
                    >
                      Inactive
                    </button>
                  </div>
                  {loading ? (
                    <div className={HomeClasses["loader"]}>
                      <RotatingLines
                        color="#4285f4"
                        loading={true}
                        css={override}
                        size={100}
                        width={100}
                        height={100}
                        strokeWidth={4}
                        animationDuration={0.75}
                      />
                    </div>
                  ) : (
                    <div className={HomeClasses["m_product_container"]}>
                      <div className={HomeClasses["manage_prod_box"]}>
                        {manageProducts.length === 0 ? (
                          <div className={HomeClasses["empty_products"]}>
                            <div className={HomeClasses["empty_image_container"]}>
                              <img
                                className={HomeClasses["empty_image"]}
                                src={emptyproducts}
                                alt="image"
                              />
                            </div>
                            <p className={HomeClasses["empty_portal_1"]}>
                              No Products to show, Add your first Product
                            </p>
                          </div>
                        ) : (
                          manageProducts
                            .slice(perPage * page, perPage * page + perPage)
                            .map((product, index) => {
                              return (
                                <>
                                  <NavLink
                                    className={HomeClasses["view_manage_link"]}
                                    to={`/productview/${product.productId}`}
                                    key={index}
                                  >
                                    <div
                                      className={HomeClasses["manage_product_box"]}
                                      key={index}
                                    >
                                      <div
                                        className={
                                          HomeClasses["manage_prod_img_container"]
                                        }
                                      >
                                        {
                                          product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={product.productImageList[0]}
                                              alt="image"
                                              onError={(e) => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = Image;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={Image}
                                              alt="default image"
                                            />
                                          )
                                        }

                                      </div>
                                      <div
                                        className={HomeClasses["product_info_c"]}
                                      >
                                        <p id={HomeClasses["home_product_text1"]}>
                                          {product.productName}
                                        </p>
                                        {
                                          product.stockQty == 0 && product.status == 2 ? <p className={HomeClasses.outtext}>Out of Stock</p> :
                                            <p id={HomeClasses["manage_product_text3"]}>
                                              Stock: {product.stockQty}{" "}
                                              {product.productMeasuringUnit}
                                            </p>
                                        }
                                      </div>
                                    </div>
                                  </NavLink>
                                </>
                              );
                            })
                        )}
                      </div>
                    </div>)}
                  <div className={HomeClasses["view_pagination"]}>
                    <span id={HomeClasses["view_page_count"]}>
                      Showing{" "}
                      <span className={HomeClasses["number"]}>
                        {page * perPage + 1}
                      </span>{" "}
                      -{" "}
                      <span className={HomeClasses["number"]}>
                        {Math.min((page + 1) * perPage, manageProducts.length)}
                      </span>{" "}
                      Products from{" "}
                      <span className={HomeClasses["number"]}>
                        {manageProducts.length}
                      </span>{" "}
                      Products
                    </span>
                    <ReactPaginate
                      containerClassName={HomeClasses["pagination"]}
                      activeClassName={HomeClasses["active-page"]}
                      pageClassName={HomeClasses["page-item"]}
                      onPageChange={(event) => setPage(event.selected)}
                      breakLabel="..."
                      pageCount={Math.ceil(manageProducts.length / perPage)}
                      previousLabel={
                        <IconContext.Provider
                          value={{ color: "#B8C1CC", size: "1.8rem" }}
                        >
                          <AiFillLeftCircle />
                        </IconContext.Provider>
                      }
                      nextLabel={
                        <IconContext.Provider
                          value={{ color: "#B8C1CC", size: "1.8rem" }}
                        >
                          <AiFillRightCircle />
                        </IconContext.Provider>
                      }
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      </Layout>
    </>
  );
};

export default ManageProducts;
