import React from 'react'
import Styles from './style.module.css'
const ProgressBar = ({ totalWidth, percentage }) => {
    let coloredContainerWidth = 0;

    if (percentage !== 0) {
        coloredContainerWidth = (totalWidth * percentage) / 100;
        if (isNaN(coloredContainerWidth)) {
            coloredContainerWidth = 0;
        }
    }

    return (
        <div
            className={Styles["percentage-width-container"]}
            style={{ width: `${totalWidth}px` }}
        >
            <div
                className={Styles["colored-container"]}
                style={{ width: `${coloredContainerWidth}px` }}
            ></div>
        </div>
    );
}

export default ProgressBar;