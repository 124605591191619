import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import ShopClass from "../../pages/Shop/shop.module.css";
import { ShopServices } from "./ShopServicess";
import { NavLink, useNavigate } from "react-router-dom";
import { override } from "../Home/helper";
import { RotatingLines } from "react-loader-spinner";
import emptygroups from "../../assets/emptygroups.png";
import Permissionimg from "../../assets/NoPermissions.png";
import Image from '../../assets/noplot.png'
import { Services } from "../../Services";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";

const Shop = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bussinessName, setBussinessName] = useState();
  const Navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [isNew, setIsNew] = useState();
  const [cancelSubscription, setCancelSubscription] = useState();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");

  useEffect(() => {
    if (accountType == 1 || accountType == 2 || accountType == 5) {
      fetchSubscriptionStatus()
    }
  }, [accountType, token]);

  const fetchSubscriptionStatus = async () => {
    Services.SubscriptionStatus("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setIsNew(res.isNew);
        setPaymentStatus(res?.paymentStatus);
        setIsSubscribed(res?.isSubscribed);
        setCancelSubscription(res?.cancelSubscription);
        localStorage.setItem("paymentStatus", res?.paymentStatus);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const FreeTrailActivate = () => {
    Services.SubcriptionPlan("POST", null, token)
      .then((res) => {
        if (res.Status === 1) {
          SuccessSwal("Success", res.Message);
          fetchSubscriptionStatus();
        }
      }).catch((err) => {
        console.log(err);
        warningSwal("Warning", "Something Went Wrong");
      })
  }

  useEffect(() => {
    getcategories();
  }, []);

  const permissionAccount = localStorage.getItem("permissionAccount");
  console.log(accountType);

  const getcategories = async () => {
    try {
      const res = await ShopServices.getManufacturers("GET", null, token);
      setLoading(false);
      if (res.Status === 1) {
        setManufacturers(res.shop);
        setBussinessName(res.businessName);
        localStorage.setItem("officeBName", res.businessName);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const viewAll = (manufacturerId, groupId) => {
    Navigate(`/manufacturer/${manufacturerId}/${groupId}`);
  };

  return (
    <Layout Active={"Shop"}>
      <div className={ShopClass["home_wrapper"]}>
        {loading ? (
          <div className={ShopClass["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (


          <div className={ShopClass["Order_container"]}>
            {
              (isNew == 1 && accountType == 5) && (
                <div className={ShopClass["subscription_container"]}>
                  <p id={ShopClass["content_text"]}>Activate Free Trail to Access The All  Features</p>
                  <button className={ShopClass["subs_btn"]} onClick={() => FreeTrailActivate()}>
                    Activate Now
                  </button>
                </div>
              )
            }
            {
              (accountType == 5) && (
                <>
                  {cancelSubscription === 1 ? (
                    paymentStatus === 0 ? (
                      <div className={ShopClass["subscription_container"]}>
                        <p id={ShopClass["content_text"]}>Subscription cancelled </p>
                        <p id={ShopClass["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={ShopClass["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    ) : (
                      // sbscriptionStatusdata.isSubscribed === 0 && <button onClick={() => Navigate("/subscription")}>Subscribe Now</button>
                      ""
                    )
                  ) : cancelSubscription === 0 ? (
                    paymentStatus === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={ShopClass["subscription_container"]}>
                        {/* <p id={ShopClass["content_text"]}>Subscription cancelled </p> */}
                        <p id={ShopClass["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={ShopClass["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    )
                  ) : cancelSubscription === 2 ? (
                    isSubscribed === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={ShopClass["subscription_container"]}>
                        <p id={ShopClass["content_text"]}>Subscribe A Plan To Access All The Features</p>
                        <button className={ShopClass["subs_btn"]} onClick={() => Navigate("/subscription")}>
                          Subscribe
                        </button>
                      </div>
                    )
                  )
                    : cancelSubscription === 3 ? (
                      isSubscribed === 1 ? (
                        // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                        ""
                      ) : (
                        <div className={ShopClass["subscription_container"]}>
                          <p id={ShopClass["content_text"]}>Subscribe A Plan To Access All The Features</p>
                          <button className={ShopClass["subs_btn"]} onClick={() => Navigate("/subscription")}>
                            Subscribe
                          </button>
                        </div>
                      )
                    ) : cancelSubscription === 0 ? (
                      isSubscribed === 0 ? (
                        <div className={ShopClass["subscription_container"]}>
                          <p id={ShopClass["content_text"]}>Subscribe A Plan To Access All The Features</p>
                          <button className={ShopClass["subs_btn"]} onClick={() => Navigate("/subscription")}>
                            Subscribe
                          </button>
                        </div>
                      ) : (
                        ""
                      )) : null}
                </>
              )
            }

            {(accountType == 6) && permissionAccount == 1 ? (
              <div className={ShopClass["permission_container"]}>
                <img src={Permissionimg} alt="image" />
                <h2>
                  {" "}
                  we,re sorry, But you dont have required permissions to access
                  this page.Please contact the site administrator.
                </h2>
              </div>
            ) : (
              <div className={ShopClass["Add_main_container"]}>
                <div className={ShopClass["Add_heade"]}>
                  {/* {accountType == 4 && (
                    <div className={ShopClass["office_header"]}>
                      <h2 className={ShopClass["b_name"]}>{bussinessName}</h2>
                    </div>
                  )} */}
                </div>
                <div className={ShopClass["home_products_container"]}>
                  <div className={ShopClass["home_products"]}>
                    {manufacturers.length === 0 ? (
                      <div className={ShopClass["empty_products"]}>
                        <div className={ShopClass["empty_image_container"]}>
                          <img
                            className={ShopClass["empty_image"]}
                            src={emptygroups}
                            alt="image"
                          />
                        </div>
                        <p className={ShopClass["empty_portal_1"]}>
                          No groups to show, Create your first Group and add
                          products to define Price's
                        </p>
                      </div>
                    ) : (
                      manufacturers.map((manufacturer) => {
                        return (
                          <div
                            className={ShopClass["home_groups_container"]}
                            key={manufacturer.manufacturerId}
                          >
                            <div className={ShopClass["home_groups"]}>
                              <div className={ShopClass["home_group_div"]}>
                                <h2 id={ShopClass["home_group_h2"]}>
                                  {manufacturer.businessName}
                                </h2>
                              </div>

                              {manufacturer.groupProducts.map((group) => (
                                <div key={group.groupId}>
                                  <div
                                    className={ShopClass["home_single_groups"]}
                                  >
                                    <div
                                      className={
                                        ShopClass["home_groups_name_button"]
                                      }
                                    >
                                      <h3
                                        className={ShopClass["home_group_name"]}
                                      >
                                        {group.groupName}
                                      </h3>
                                      {group.products.length > 0 && (
                                        <button
                                          className={
                                            ShopClass["home_group_button_view"]
                                          }
                                          onClick={() =>
                                            viewAll(
                                              manufacturer.manufacturerId,
                                              group.groupId
                                            )
                                          }
                                        >
                                          View All
                                        </button>
                                      )}
                                    </div>

                                    <div
                                      className={
                                        ShopClass["home_product_container"]
                                      }
                                    >
                                      {group.products.length === 0 ? (
                                        <p className={ShopClass["no_Product"]}>
                                          No products Found
                                        </p>
                                      ) : (
                                        group.products
                                          // .filter((item) => item.price > 0)
                                          .slice(0, 6)
                                          .map((product, index) => {
                                            let discountPrice =
                                              product.price *
                                              (product.discount / 100);
                                            console.log(discountPrice);
                                            let finalPrice =
                                              product.price - discountPrice;
                                            return (
                                              <NavLink
                                                className={
                                                  ShopClass["view_manage_link"]
                                                }
                                                to={`/shopProduct/${group.groupId}/${product.productId}`}
                                                key={product.productId}
                                              >
                                                <div
                                                  className={
                                                    ShopClass[
                                                    "home_product_box"
                                                    ]
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      ShopClass[
                                                      "shop_img_container"
                                                      ]
                                                    }
                                                  >
                                                    {
                                                      product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                                        <img
                                                          className={ShopClass["home_product_image"]}
                                                          src={product.productImageList[0]}
                                                          alt="image"
                                                          onError={(e) => {
                                                            e.currentTarget.onerror = null;
                                                            e.currentTarget.src = Image;
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          className={ShopClass["home_product_image"]}
                                                          src={Image}
                                                          alt="image"
                                                        />
                                                      )
                                                    }
                                                    {/* <img
                                                      className={
                                                        ShopClass[
                                                        "home_product_image"
                                                        ]
                                                      }
                                                      src={product.productImage}
                                                      alt="image"
                                                    /> */}
                                                  </div>
                                                  <div className={ShopClass["product_info_c"]}>
                                                    <p
                                                      id={ShopClass["home_product_text1"]}>
                                                      {product.productName}
                                                    </p>
                                                    {
                                                      (product.stockQty == 0 && product.status == 2) ? (<p className={ShopClass.outtext}>Out of Stock</p>) :

                                                        <>

                                                          {product.productBagWeightInKgs !== 0
                                                            && (
                                                              <p
                                                                className={ShopClass["p_bag_qty"]}>
                                                                {
                                                                  product.productBagWeightInKgs
                                                                }
                                                                KG'S
                                                                /
                                                                {
                                                                  product.productMeasuringUnit
                                                                }
                                                              </p>
                                                            )}

                                                          {product.productPriceUnit !==
                                                            "NA" && (
                                                              <>
                                                                {product.productPriceUnit ===
                                                                  "Quintal" &&
                                                                  product.productBagWeightInKgs !== 0
                                                                  && (
                                                                    <p
                                                                      className={ShopClass["p_bag_price"]}>
                                                                      {
                                                                        product.productMeasuringUnit
                                                                      }
                                                                      price: ₹
                                                                      {product.productPriceUnit ===
                                                                        "Quintal"
                                                                        && (product.productBagWeightInKgs *
                                                                          product.price) /
                                                                        100}
                                                                    </p>
                                                                  )}
                                                              </>
                                                            )}
                                                          <p
                                                            className={
                                                              ShopClass["p_price"]}>
                                                            ₹{product.price}/
                                                            {
                                                              product.productPriceUnit
                                                            }
                                                          </p>
                                                        </>
                                                    }
                                                  </div>
                                                </div>
                                              </NavLink>
                                            );
                                          })
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
export default Shop;
