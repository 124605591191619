import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import { Services } from "../../Services";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import UserClasses from "../../pages/Users/user.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { TbCurrencyRupee } from "react-icons/tb";
import { AiFillEdit } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";
import { BiRupee } from "react-icons/bi";
import { override } from "../Home/helper";
import emptygroups from "../../assets/emptygroups.png";
import emptyproducts from "../../assets/emptyproducts.png";
import { RotatingLines } from "react-loader-spinner";
import Ownusers from "./users";
import { MdDelete } from "react-icons/md";
import Search from "../../assets/homeimgs/search.png";
import Image from '../../assets/noplot.png'


const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [params] = useSearchParams();
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupProducts, setGroupProducts] = useState([]);
  const Navigate = useNavigate();
  const [activeGroupId, setActiveGroupId] = useState(null);
  const [activeButton, setActiveButton] = useState("users");
  const [productPrice, setProductPrice] = useState();
  const [showEditModel, setShowEditModel] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [productdetails, setProductdetails] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [selectedgroupId, setSelectedGroupId] = useState();
  const [groupName, setGroupName] = useState();
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const [searchItems, setSearchItems] = useState([]);
  const [isNew, setIsNew] = useState();


  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const permissionAccount = localStorage.getItem("permissionAccount");
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 7
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  const removeuser = addPermissions.filter(
    (item) => item.permissionId === 8
  );
  const Permission = removeuser.length > 0 ? removeuser[0].status : 1;
  //
  const PricePermissions = addPermissions.filter(
    (item) => item.permissionId === 4
  );
  const Pricepermission =
    PricePermissions.length > 0 ? PricePermissions[0].status : 1;

  const editPermissions = addPermissions.filter(
    (item) => item.permissionId === 13
  );
  const editPermission =
    editPermissions.length > 0 ? editPermissions[0].status : 1;

  const DeletPermissions = addPermissions.filter(
    (item) => item.permissionId === 12
  );
  const deletePermission =
    DeletPermissions.length > 0 ? DeletPermissions[0].status : 1;


  // ---------------//
  useEffect(() => {
    if (accountType == 6) {
      getPermissions();
    }
  }, []);
  useEffect(() => {
    if (accountType == 1 || accountType == 2) {
      getSubscriptionStatus();
    }
  }, []);

  const getSubscriptionStatus = async () => {
    try {
      const res = await Services.SubscriptionStatus("GET", null, token);
      if (res.Status === 1) {
        setIsNew(res.isNew);
        if (res.isNew == 1) {
          return;
        }
        if (res.cancelSubscription === 1) {
          if (res.paymentStatus === 0) {
            Navigate("/paynow");
          } else if (res.isSubscribed === 0) {
            Navigate("/subscription");
          }
        } else if (res.cancelSubscription === 0) {
          if (res.isSubscribed === 1) {
            Navigate("/Users");
          } else if (res.isSubscribed === 0) {
            Navigate("/subscription");
          }
        } else if (res.cancelSubscription === 2) {
          if (res.isSubscribed === 1) {
            Navigate("/Users");
          } else if (res.isSubscribed === 0) {
            Navigate("/subscription");
          }
        } else if (res.cancelSubscription === 3) {
          if (res.isSubscribed === 1) {
            Navigate("/Manage");
          } else if (res.isSubscribed === 0) {
            Navigate("/subscription");
          }
        }
      }
      localStorage.setItem("paymentStatus", res?.paymentStatus);
    } catch (err) {
      console.error(err);
    }
  };
  const IsNew = localStorage.getItem("isNew");

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getGropLists();
  }, []);

  const getGropLists = async () => {
    try {
      const res = await Services.getGroups("GET", null, token);
      setLoading(false);

      if (res.Status === 1) {
        setGroups(res.groups);
        if (res.groups.length > 0) {
          if (localStorage.getItem("activeGroupId")) {
            setActiveGroupId(localStorage.getItem("activeGroupId"));
          } else {
            const storedGroupId = localStorage.getItem("activeGroupId");
            console.log(storedGroupId);
            setActiveGroupId(
              storedGroupId ? Number(storedGroupId) : res.groups[0].groupId
            );
            if (!localStorage.getItem("activeGroupId")) {
              localStorage.setItem("activeGroupId", res.groups[0].groupId);
            }
          }
        }
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  // groups//

  useEffect(() => {
    if (activeGroupId) {
      getCategories(activeGroupId);
      getGroupProductsList(activeGroupId);
    }
  }, [activeGroupId]);

  const getCategories = async (activeGroupId) => {
    try {
      const res = await Services.getGroupUsers(
        "GET",
        null,
        token,
        activeGroupId
      );
      if (res.Status === 1) {
        setGroupUsers(res.groupUser);
        getGropLists();
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  const getGroupProductsList = async (activeGroupId) => {
    try {
      const res = await Services.viewAllProducts(
        "GET",
        null,
        token,
        activeGroupId
      );
      if (res.Status === 1) {
        setGroupProducts(res.groupProducts);
        setSearchItems(res.groupProducts);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  const handleGroupClick = (activeGroupId) => {
    setActiveGroupId(activeGroupId);
    localStorage.setItem("activeGroupId", activeGroupId);
    getCategories(activeGroupId);
    getGroupProductsList(activeGroupId);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    let prodID = e.currentTarget.getAttribute("custom_prodID");
    let [details] = groupProducts.filter((item) => item.productId == prodID);
    setProductPrice(details.price);
    setProductdetails(details);
    setShowEditModel(true);
  };

  const detelegroup = async (groupId) => {
    // e.stopPropagation();
    try {
      const res = await Services.deleteGroup("DELETE", null, token, groupId);
      if (res.Status === 1) {
        console.log(groupId, activeGroupId);
        if (groupId == activeGroupId) {
          localStorage.removeItem("activeGroupId");
          setGroupProducts([]);
          setGroupUsers([]);
        }
        getGropLists();
        setDeleteModal(false);
        SuccessSwal("Success", "Group deleted successfully");
      }
    } catch (err) {

      console.error(err);
    }
  };

  //
  useEffect(() => {
    getOwnusers();
  }, []);

  const getOwnusers = async () => {
    try {
      const res = await Services.getOwnUsers("GET", null, token);
      setLoading(false);
      if (res.Status === 1) {
        setUsers(res.ownUsers);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  const AddProductprice = async (e) => {
    e.preventDefault();
    const body = {
      productId: productdetails.productId,
      groupId: activeGroupId,
      price: productPrice,
    };
    try {
      setLoading(false);
      const res = await Services.AddPrice("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        getGroupProductsList(activeGroupId);
        setShowEditModel(false);
      }
    } catch (err) {

      console.error(err);
    }
  };

  const handleEditCancel = () => {
    setShowEditModel(false);
    setProductPrice(" ");
  };

  const handlePriceChange = (event) => {
    event.stopPropagation();
    const inputVal = event.target.value.replace(/^(\d*\.\d{0,2})\d*$/, '$1');
    setProductPrice(inputVal);
  };

  // Routes
  const AddGroup = () => {
    Navigate("/createGroup");
  };

  const userDetails = (groupId, userId) => {
    Navigate(`/userdetails/${groupId}/${userId}`);
  };

  const AddUser = (groupName, groupId) => {
    Navigate(`/createGroup/${groupName}/${groupId}`);
  };

  const AddProduct = (groupName, groupId) => {
    Navigate(`/Addgroupproduct/${groupName}/${groupId}`);
  };
  const getProduct = (grouId, productId) => {
    Navigate(`/groupproductdetails/${grouId}/${productId}`);
  };

  const editModalOpen = (groupId, groupName) => {
    setSelectedGroupId(groupId);
    setGroupName(groupName);
    setEditModal(true);
    console.log(groupId);
    console.log(groupName);
  };

  const deleteModalalOpen = (groupId) => {
    setSelectedGroupId(groupId);
    setDeleteModal(true);
  };

  const editGroupName = async () => {
    let body = {
      groupId: selectedgroupId,
      groupName: groupName,
    };
    console.log(groupName)
    // return
    if (!groupName || groupName.length == 0) {
      warningSwal("Warning", 'Group Name cannot be empty');
      return;
    }
    try {
      const res = await Services.EditGroupName(
        "PATCH",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setEditModal(false);
        getGropLists();
      }
    } catch (err) {
      // alert("Something went wrong please try again later");
      console.error(err);
    }
  };

  const getBackgroundColor = (index) => {
    const colors = ["#FF8780", "#AEF368", "#2EADFF", "#FBBC05"];
    return colors[index % colors.length];
  };
  const getBackgroundColor2 = (index) => {
    const colors = ["#D7FFB1", "#FFD7D4", "#C9EFFF", "#FEEBC3"];
    return colors[index % colors.length];
  };

  // Search Functionality
  const searchHandler = (e) => {
    setLoading(false);
    const searchValue = e.target.value.toLowerCase();
    const search = searchItems.filter((item) =>
      item.productName.toLowerCase().includes(searchValue)
    );

    // console.log(search);

    if (searchValue === "") {
      setGroupProducts(searchItems);
    } else {
      setGroupProducts(search);
    }
  };

  return (
    <Layout Active={"Users & Groups"}>
      <div className={UserClasses["User_wrapper"]}>
        {loading ? (
          <div className={UserClasses["loader"]}>
            <RotatingLines
              color={"#20a0ff"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <>{
            (isNew == 1 && (accountType == 1 || accountType == 2 || accountType == 5)) ?
              (<div className={UserClasses["subscription_container"]}>
                <p className={UserClasses.denied}>Access denied due to invalid subscription, Please activate the plan and access all the features</p>
              </div>) : (
                <>
                  {
                    (accountType == 4 || accountType == 5 || (accountType == 6 && permissionAccount == 5)) ? (<div className={UserClasses["group_containe"]}>
                      <div className={UserClasses["group_users_product_c"]}><Ownusers /></div>
                    </div>) : (<div className={UserClasses["group_container"]}>
                      <div className={UserClasses["group_users_products"]}><Ownusers /></div>

                      <div className={UserClasses["group_box"]}>
                        <p id={UserClasses["user_groups"]}>
                          <p className={UserClasses.UGname}>Groups</p>
                          {users.length === 0 ? "" : <p className={UserClasses.UGbtn}>
                            {accountType == 6 && PermissionStatus == 0 ? (
                              " "
                            ) : (
                              <button
                                className={UserClasses["add_product"]}
                                onClick={() => AddGroup()}
                              >
                                <FaPlus />
                              </button>
                            )} </p>}
                        </p>
                        <div className={UserClasses["user_group_container"]}>
                          {groups.length === 0 ? (
                            <div className={UserClasses["empty_products"]}>
                              <div
                                className={
                                  UserClasses["empty_image_container"]
                                }
                              >
                                <img
                                  className={UserClasses["empty_image"]}
                                  src={emptygroups}
                                  alt="image"
                                />
                              </div>
                              <p className={UserClasses["empty_portal_1"]}>
                                No groups to show, Create your first Group and
                                add products to define Price's
                              </p>
                            </div>
                          ) : (
                            groups.map((group, index) => (
                              <div className={UserClasses["user_goups_box"]}>
                                <div
                                  key={index}
                                  className={
                                    group.groupId == activeGroupId
                                      ? `${UserClasses["groups_container"]} ${UserClasses["active_group"]}`
                                      : UserClasses["groups_container"]
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleGroupClick(group.groupId);
                                    setActiveButton("users");
                                  }}
                                >
                                  <div className={UserClasses["group_box_c"]}>
                                    <div
                                      className={
                                        UserClasses["user_icon_container"]
                                      }

                                    >
                                      <FaUsers
                                        className={UserClasses["users_icon"]}
                                        style={{ backgroundColor: getBackgroundColor(index) }}
                                      />
                                    </div>
                                    <div
                                      className={UserClasses["user_group"]}
                                    >
                                      <p id={group.groupId == activeGroupId ? UserClasses.activeText : UserClasses.group_h6}>
                                        {group.groupName}
                                      </p>
                                      <p id={group.groupId == activeGroupId ? UserClasses.activeText2 : UserClasses.group_li}>
                                        {group.userCount} members
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      UserClasses["group_icon_container"]
                                    }
                                  >
                                    {accountType == 6 &&
                                      editPermission == 0 ? (
                                      ""
                                    ) : (

                                      <button
                                        type="button"
                                        className={
                                          UserClasses["group_btn_edit"]
                                        }
                                        onClick={() =>
                                          editModalOpen(
                                            group.groupId,
                                            group.groupName
                                          )
                                        }
                                      >
                                        Edit
                                      </button>
                                    )}
                                    {
                                      accountType == 6 &&
                                        deletePermission == 0 ? ("") : (
                                        <button
                                          type="button"
                                          className={
                                            UserClasses["group_btn_dlt"]
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            console.log("del");
                                            deleteModalalOpen(group.groupId);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>

                      <div className={UserClasses["group_users_products"]}>
                        <p id={UserClasses["user_groups"]}>
                          <p className={UserClasses.UGname}>Groups Users</p>
                          <p className={UserClasses.UGbtn}> {accountType == 6 && Permission == 0 ? (
                            " "
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                AddUser(
                                  groups.find(
                                    (g) => g.groupId == activeGroupId
                                  )?.groupName,
                                  activeGroupId
                                )
                              }
                              className={UserClasses["add_product"]}
                              disabled={groups.length === 0}
                              style={{
                                display:
                                  groups.length === 0 ? "none" : "flex",
                              }}
                            >
                              <FaPlus strokeWidth={5} />
                            </button>

                          )} </p>
                        </p>
                        <div
                          className={UserClasses["group_products_container"]}
                        >
                          <div className={UserClasses["group_users_boxes"]}>
                            {groupUsers && groupUsers.length === 0 ? (
                              <div
                                className={UserClasses["empty_products"]}
                              >
                                <div
                                  className={
                                    UserClasses["empty_image_container"]
                                  }
                                >
                                  <img
                                    className={UserClasses["empty_image"]}
                                    src={emptygroups}
                                    alt="image"
                                  />
                                </div>
                                <p
                                  className={UserClasses["empty_portal_1"]}
                                >
                                  No buyers to show, Add your first user by
                                  click " + " to make transactions
                                </p>
                              </div>
                            ) : (
                              groupUsers &&
                              groupUsers.map((user, index) => {
                                return (
                                  <button
                                    className={
                                      UserClasses["group_user_details_btn"]
                                    }
                                    key={index}
                                    onClick={() =>
                                      userDetails(
                                        activeGroupId,
                                        user.userId
                                      )
                                    }
                                  >
                                    <div
                                      className={
                                        UserClasses["group_user_container"]
                                      }
                                    >
                                      <div
                                        className={
                                          UserClasses[
                                          "user_image_container"
                                          ]
                                        }
                                      >
                                        {user.Photo !== "NA" ? (
                                          <img
                                            className={
                                              UserClasses[
                                              "group_user_image"
                                              ]
                                            }
                                            src={user.Photo}
                                            alt="image"
                                          />
                                        ) : (
                                          <div
                                            className={UserClasses["group_user_initial"]}
                                            style={{ backgroundColor: getBackgroundColor2(index) }}
                                          >
                                            {user.name
                                              ? user.name.charAt(0)
                                              : ""}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className={
                                          UserClasses["group_user_info"]
                                        }
                                      >
                                        <p
                                          className={
                                            UserClasses["group_user_name"]
                                          }
                                        >
                                          {user.name}
                                        </p>
                                        <p
                                          className={
                                            UserClasses["group_user_name"]
                                          }
                                        >{(user.accountType !== 4 && user.accountType !== 6) && user.businessName}</p>
                                        <p
                                          id={
                                            UserClasses["group_user_number"]
                                          }
                                        >
                                          {user.mobileNumber}
                                        </p>
                                        <p
                                          id={
                                            UserClasses["group_user_type"]
                                          }
                                        >
                                          {user.accountType === 1
                                            ? "Manufacturer "
                                            : user.accountType === 2
                                              ? "Distributor "
                                              : user.accountType === 3
                                                ? "Retailer"
                                                : user.accountType === 4
                                                  ? "Marketing Specialist"
                                                  : user.accountType === 5
                                                    ? "Agents"
                                                    : user.accountType === 6
                                                      ? "Office Team "
                                                      : "null"}
                                        </p>
                                      </div>
                                    </div>
                                  </button>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={UserClasses["group_users_products"]}>
                        <p id={UserClasses["user_groups"]}>
                          <p className={UserClasses.UGname}>Groups Products</p>
                          <p className={UserClasses.UGbtn}>  {accountType == 6 && PermissionStatus == 0 ? (
                            " "
                          ) : (
                            <button
                              onClick={() =>
                                AddProduct(
                                  groups.find(
                                    (g) => g.groupId == activeGroupId
                                  )?.groupName,
                                  activeGroupId
                                )
                              }
                              className={UserClasses["add_product"]}
                              disabled={groups.length === 0}
                              style={{
                                display:
                                  groups.length === 0 ? "none" : "flex",
                              }}
                            >
                              <FaPlus />
                            </button>

                          )}</p>
                        </p>
                        <div className={UserClasses["groupproduct-serch-usear"]}>
                          <img
                            className={UserClasses["g_product_search"]}
                            src={Search}
                            alt="Search"
                          />
                          <input
                            type={"text"}
                            placeholder="Search using Product Name"
                            onChange={searchHandler}
                          />
                        </div>
                        <div
                          className={UserClasses["group_products_boxes"]}
                        >
                          {groupProducts.length === 0 ? (
                            <div
                              className={UserClasses["empty_products"]}
                            >
                              <img
                                className={UserClasses["empty_image"]}
                                src={emptyproducts}
                                alt="image"
                              />
                              <p
                                className={UserClasses["empty_portal_1"]}
                              >
                                No products to show , Add your first
                                product by click " + " to define Price's
                                to define Price's
                              </p>
                            </div>
                          ) : (
                            groupProducts.map((product, index) => {
                              return (
                                <div
                                  className={UserClasses["product_box"]}
                                  key={index}
                                  onClick={() =>
                                    getProduct(
                                      activeGroupId,
                                      product.productId
                                    )
                                  }
                                >
                                  <div
                                    className={
                                      UserClasses[
                                      "group_images_container"
                                      ]
                                    }
                                  >

                                    {
                                      product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                        <img
                                          className={UserClasses["group_product_img"]}
                                          src={product.productImageList[0]}
                                          alt="image"
                                          onError={(e) => {
                                            e.currentTarget.onerror = null;
                                            e.currentTarget.src = Image;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className={UserClasses["group_product_img"]}
                                          src={Image}
                                          alt=" image"
                                        />
                                      )
                                    }
                                  </div>
                                  <div
                                    className={
                                      UserClasses[
                                      "product_info_container"
                                      ]
                                    }
                                  >
                                    <p id={UserClasses["g_p_name"]}>
                                      {product.productName}
                                    </p>
                                    <p id={UserClasses["g_p_Q"]}>
                                      Stock: {product.stockQty}{" "}
                                      {product.productMeasuringUnit}
                                    </p>
                                    <p
                                      id={
                                        UserClasses["group_product_price"]
                                      }
                                    >
                                      <span
                                        id={UserClasses["group_p_price"]}
                                      >
                                        <TbCurrencyRupee />
                                        {product.price}/{product.productPriceUnit}
                                      </span>
                                      {accountType == 6 &&
                                        Pricepermission == 0 ? (
                                        " "
                                      ) : (
                                        <button
                                          id={
                                            UserClasses[
                                            "group_product_edit"
                                            ]
                                          }
                                          custom_prodID={
                                            product.productId
                                          }
                                          type="button"
                                          onClick={(e) =>
                                            handleEditClick(e)
                                          }
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>)
                  }

                </>

              )
          }
          </>
        )}
      </div>
      {showEditModel && (
        <div className={UserClasses["delete-modal"]}>
          <div
            onClick={() => setShowEditModel(false)}
            className={UserClasses["overlaye"]}
          ></div>
          <div className={UserClasses["edit-modal-content"]}>
            <p id={UserClasses["edit_header"]}>Add Price</p>
            <form className={UserClasses["price_form"]}>

              {
                productdetails.productImageList.length > 0 && productdetails.productImageList[0] !== "NA" ? (
                  <img
                    className={UserClasses["edit-modal-img"]}
                    src={productdetails.productImageList[0]}
                    alt="image"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = Image;
                    }}
                  />
                ) : (
                  <img
                    className={UserClasses["edit-modal-img"]}
                    src={Image}
                    alt="image"
                  />
                )
              }
              <p id={UserClasses["edit_text1"]}>
                {productdetails?.productName}
              </p>
              <p id={UserClasses["edit_text1"]}>
                {productdetails?.productCategory}.{productdetails?.productSubCategory}
              </p>
              <div className={UserClasses["edit_input_conatiner"]}>
                <input
                  type="text"
                  value={productPrice || ""}
                  onChange={handlePriceChange}
                  className={UserClasses["edit_input_box"]}
                  placeholder="Enter Price"
                  min={1}
                />
                <p className={UserClasses["price_text"]}>
                  /Per {productdetails.productPriceUnit}
                </p>
                <BiRupee className={UserClasses["rupee_icon"]} />
              </div>

              <div className={UserClasses["edit_btns"]}>
                <button
                  type="button"
                  className={UserClasses["delete_acbtn"]}
                  onClick={() => handleEditCancel()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={UserClasses["edit_adbtn"]}
                  onClick={(e) => AddProductprice(e)}
                  disabled={!productPrice}
                >
                  AddPrice
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {editModal && (
        <div className={UserClasses["reject_modal"]}>
          <div
            onClick={() => setEditModal(false)}
            className={UserClasses["overlaye"]}
          ></div>
          <div className={UserClasses["update_modal_content"]}>
            <h2 id={UserClasses["resct_header"]}>Edit Group Name</h2>
            <div className={UserClasses["update_input_model_container"]}>
              <div className={UserClasses["banner_input"]}>

                <input
                  type="text"
                  placeholder="Group Name"
                  value={groupName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z0-9\s]*$/;
                    if (regex.test(inputValue)) {
                      setGroupName(inputValue);
                    } else {
                      warningSwal("Warning", "Special characters are not allowed");
                    }
                  }}
                  required
                />
              </div>
              <div className={UserClasses["banner_form_btns"]}>
                <button
                  type="submit"
                  className={UserClasses["group_e_btn"]}
                  onClick={() => editGroupName()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <div className={UserClasses["reject_modal"]}>
          <div
            onClick={() => setDeleteModal(false)}
            className={UserClasses["overlaye"]}
          ></div>
          <div className={UserClasses["update_modal_content"]}>
            <h2 id={UserClasses["resct_header"]}>Delete Group</h2>
            <div className={UserClasses["update_input_model_container"]}>
              <h5 className={UserClasses["text_banner"]}>
                Are you sure you want to delete this Group
              </h5>
              <div className={UserClasses["banner_form_btns"]}>
                <button
                  type="button"
                  className={UserClasses["group_e_btn"]}
                  onClick={() => setDeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={UserClasses["group_dlt_btn"]}
                  onClick={() => detelegroup(selectedgroupId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Groups;
